/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PrimaryMaterialBlock } from '../../models/primary-material-block';

export interface GetMountedMaterials$Params {
  workCenterId: number;
}

export function getMountedMaterials(
  http: HttpClient,
  rootUrl: string,
  params: GetMountedMaterials$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<PrimaryMaterialBlock>>> {
  const rb = new RequestBuilder(rootUrl, getMountedMaterials.PATH, 'get');
  if (params) {
    rb.path('workCenterId', params.workCenterId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<PrimaryMaterialBlock>>;
    })
  );
}

getMountedMaterials.PATH = '/api/v1/PrimaryMaterial/{workCenterId}/MountedMaterials';
