import { Component, OnInit } from '@angular/core';
import { ListValue } from 'chronos-shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize, tap } from 'rxjs/operators';
import { notificationTopic } from '@bd/shared/utils';
import { WarehouseLocationViewModel, WarehouseViewModel, WorkCenterViewModel as WorkCenter } from 'chronos-basedata-client';
import { WarehouseDsService } from '@bd/core/data-services/ware-house-ds.service';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface WarehouseLocationList {
  value: number | string;
  label: string;
  warehouseLocationName: string;
  externalWarehouseLocationId: string;
}

@Component({
  selector: 'app-change-warehouse',
  templateUrl: './change-warehouse.component.html',
  styleUrls: ['./change-warehouse.component.scss']
})
export class ChangeWarehouseComponent implements OnInit {
  public isLoading = true;
  public siteId: number;
  public warehouseList: ListValue[] = [];
  public warehouseLocationList: WarehouseLocationList[] = [];
  public warehouseLocationsLoaded: WarehouseLocationViewModel[] = [];

  public readonly LOADING_TOPIC_SAVEWORKCENTER = notificationTopic.saveWorkCenterDetails;
  public warehouseId: number | null;
  public warehouseLocationId: number | null;

  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef, private warehouseDsService: WarehouseDsService) {}

  public ngOnInit(): void {
    const warehouseLocation = this.config.data?.warehouseLocation;
    this.siteId = this.config.data?.siteId;

    this.warehouseId = warehouseLocation.warehouse.id;
    this.warehouseLocationId = warehouseLocation.id;

    this.getWarehouses(this.siteId);
  }

  public get isValid(): boolean {
    return this.warehouseId != null && this.warehouseLocationId != null;
  }

  public getWarehouses(siteId?: number): void {
    this.isLoading = true;
    this.warehouseList = [];
    this.warehouseLocationList = [];

    this.warehouseDsService
      .getWarehouses(siteId)
      .pipe(
        tap((warehouses) => {
          this.warehouseList = warehouses.map((warehouse) => ({
            label: `${warehouse.externalWarehouseId}`,
            value: warehouse.id
          }));

          this.getWarehouseLocations(this.warehouseId);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  private getWarehouseLocations(warehouseId: number): void {
    this.isLoading = true;
    this.warehouseLocationList = [];
    this.warehouseLocationsLoaded = [];

    this.warehouseDsService
      .getWarehouseLocationsByWarehouse(warehouseId)
      .pipe(
        tap((warehouseLocations) => {
          this.warehouseLocationsLoaded = warehouseLocations;
          this.warehouseLocationList = warehouseLocations.map((warehouseLocation) => ({
            label: `${warehouseLocation.externalWarehouseLocationId}`,
            value: warehouseLocation.id,
            warehouseLocationName: warehouseLocation.name,
            externalWarehouseLocationId: warehouseLocation.externalWarehouseLocationId
          }));
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  public onWarehouseChange(event: any) {
    this.warehouseLocationId = null;
    this.getWarehouseLocations(event.value);
  }

  public onWarehouseLocationChange(event) {}

  public submitModal(): void {
    const selectedWarehouseLocation = this.warehouseLocationsLoaded.find((x) => x.id === this.warehouseLocationId);
    this.ref.close(selectedWarehouseLocation);
  }

  public closeModal(): void {
    this.ref.close();
  }
}
