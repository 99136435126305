/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bookConsumption } from '../fn/maintenance/book-consumption';
import { BookConsumption$Params } from '../fn/maintenance/book-consumption';
import { checkProductionPeriod } from '../fn/maintenance/check-production-period';
import { CheckProductionPeriod$Params } from '../fn/maintenance/check-production-period';
import { checkProductionPeriod2 } from '../fn/maintenance/check-production-period-2';
import { CheckProductionPeriod2$Params } from '../fn/maintenance/check-production-period-2';
import { CheckProductionPeriodResponse } from '../models/check-production-period-response';
import { clockInFilterDate } from '../fn/maintenance/clock-in-filter-date';
import { ClockInFilterDate$Params } from '../fn/maintenance/clock-in-filter-date';
import { maintainMaterialBlocks } from '../fn/maintenance/maintain-material-blocks';
import { MaintainMaterialBlocks$Params } from '../fn/maintenance/maintain-material-blocks';
import { sendMessage } from '../fn/maintenance/send-message';
import { SendMessage$Params } from '../fn/maintenance/send-message';
import { sendProductionOrderFinishedInternal } from '../fn/maintenance/send-production-order-finished-internal';
import { SendProductionOrderFinishedInternal$Params } from '../fn/maintenance/send-production-order-finished-internal';
import { startProductionOrderInternal } from '../fn/maintenance/start-production-order-internal';
import { StartProductionOrderInternal$Params } from '../fn/maintenance/start-production-order-internal';

@Injectable({ providedIn: 'root' })
export class MaintenanceService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendMessage()` */
  static readonly SendMessagePath = '/api/v1/SendMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMessage$Response(params: SendMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMessage(params: SendMessage$Params, context?: HttpContext): Observable<void> {
    return this.sendMessage$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `maintainMaterialBlocks()` */
  static readonly MaintainMaterialBlocksPath = '/api/v1/{workCenterId}/MaintainMaterialBlocks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `maintainMaterialBlocks()` instead.
   *
   * This method doesn't expect any request body.
   */
  maintainMaterialBlocks$Response(params: MaintainMaterialBlocks$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return maintainMaterialBlocks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `maintainMaterialBlocks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  maintainMaterialBlocks(params: MaintainMaterialBlocks$Params, context?: HttpContext): Observable<void> {
    return this.maintainMaterialBlocks$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `bookConsumption()` */
  static readonly BookConsumptionPath = '/api/v1/{workCenterId}/BookConsumption';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookConsumption()` instead.
   *
   * This method doesn't expect any request body.
   */
  bookConsumption$Response(params: BookConsumption$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bookConsumption(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bookConsumption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bookConsumption(params: BookConsumption$Params, context?: HttpContext): Observable<void> {
    return this.bookConsumption$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `clockInFilterDate()` */
  static readonly ClockInFilterDatePath = '/api/v1/{workCenterId}/ClockInFilterDate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clockInFilterDate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clockInFilterDate$Response(params: ClockInFilterDate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clockInFilterDate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clockInFilterDate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clockInFilterDate(params: ClockInFilterDate$Params, context?: HttpContext): Observable<void> {
    return this.clockInFilterDate$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `checkProductionPeriod()` */
  static readonly CheckProductionPeriodPath = '/api/v1/{workCenterId}/RecalculateProductionPeriod';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkProductionPeriod()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkProductionPeriod$Response(params: CheckProductionPeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkProductionPeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkProductionPeriod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkProductionPeriod(params: CheckProductionPeriod$Params, context?: HttpContext): Observable<void> {
    return this.checkProductionPeriod$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `checkProductionPeriod2()` */
  static readonly CheckProductionPeriod2Path = '/api/v1/{workCenterId}/CheckProductionPeriod';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkProductionPeriod2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkProductionPeriod2$Response(
    params: CheckProductionPeriod2$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<CheckProductionPeriodResponse>> {
    return checkProductionPeriod2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkProductionPeriod2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkProductionPeriod2(params: CheckProductionPeriod2$Params, context?: HttpContext): Observable<CheckProductionPeriodResponse> {
    return this.checkProductionPeriod2$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckProductionPeriodResponse>): CheckProductionPeriodResponse => r.body)
    );
  }

  /** Path part for operation `startProductionOrderInternal()` */
  static readonly StartProductionOrderInternalPath = '/api/v1/{productionOrderId}/StartProductionOrderInternal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startProductionOrderInternal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startProductionOrderInternal$Response(
    params: StartProductionOrderInternal$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return startProductionOrderInternal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startProductionOrderInternal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startProductionOrderInternal(params: StartProductionOrderInternal$Params, context?: HttpContext): Observable<void> {
    return this.startProductionOrderInternal$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `sendProductionOrderFinishedInternal()` */
  static readonly SendProductionOrderFinishedInternalPath = '/api/v1/{productionOrderId}/SendProductionOrderFinishedInternal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendProductionOrderFinishedInternal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendProductionOrderFinishedInternal$Response(
    params: SendProductionOrderFinishedInternal$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return sendProductionOrderFinishedInternal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendProductionOrderFinishedInternal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendProductionOrderFinishedInternal(params: SendProductionOrderFinishedInternal$Params, context?: HttpContext): Observable<void> {
    return this.sendProductionOrderFinishedInternal$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }
}
