/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { WidgetFunctionOperation } from '../../models/widget-function-operation';

export interface GetWidgetFunctionAndEmptyOperations$Params {
  uwid?: number;
}

export function getWidgetFunctionAndEmptyOperations(
  http: HttpClient,
  rootUrl: string,
  params?: GetWidgetFunctionAndEmptyOperations$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<WidgetFunctionOperation>>> {
  const rb = new RequestBuilder(rootUrl, getWidgetFunctionAndEmptyOperations.PATH, 'get');
  if (params) {
    rb.query('uwid', params.uwid, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<WidgetFunctionOperation>>;
    })
  );
}

getWidgetFunctionAndEmptyOperations.PATH = '/api/AclRole/widgetFunctionAndEmptyOperations';
