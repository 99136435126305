/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserSettingType } from '../../models/user-setting-type';

export interface IsUserSettingPresent$Params {
  settingType?: UserSettingType;
  gmname?: string;
}

export function isUserSettingPresent(
  http: HttpClient,
  rootUrl: string,
  params?: IsUserSettingPresent$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<boolean>> {
  const rb = new RequestBuilder(rootUrl, isUserSettingPresent.PATH, 'get');
  if (params) {
    rb.query('settingType', params.settingType, {});
    rb.query('gmname', params.gmname, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
    })
  );
}

isUserSettingPresent.PATH = '/api/GridSettings/isUserSettingPresent';
