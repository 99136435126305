/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addOrUpdateGeneralSettings } from '../fn/general-settings/add-or-update-general-settings';
import { AddOrUpdateGeneralSettings$Params } from '../fn/general-settings/add-or-update-general-settings';
import { deleteGeneralSettings } from '../fn/general-settings/delete-general-settings';
import { DeleteGeneralSettings$Params } from '../fn/general-settings/delete-general-settings';
import { getGeneralSettings } from '../fn/general-settings/get-general-settings';
import { GetGeneralSettings$Params } from '../fn/general-settings/get-general-settings';
import { getOrganizations2 } from '../fn/general-settings/get-organizations-2';
import { GetOrganizations2$Params } from '../fn/general-settings/get-organizations-2';
import { getOrganizationsWithGeneralSettings } from '../fn/general-settings/get-organizations-with-general-settings';
import { GetOrganizationsWithGeneralSettings$Params } from '../fn/general-settings/get-organizations-with-general-settings';
import { OrganizationOr } from '../models/organization-or';

@Injectable({ providedIn: 'root' })
export class GeneralSettingsService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOrganizations2()` */
  static readonly GetOrganizations2Path = '/api/GeneralSettings/getOrganizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizations2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations2$Response(
    params?: GetOrganizations2$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationOr>>> {
    return getOrganizations2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizations2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations2(params?: GetOrganizations2$Params, context?: HttpContext): Observable<Array<OrganizationOr>> {
    return this.getOrganizations2$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationOr>>): Array<OrganizationOr> => r.body)
    );
  }

  /** Path part for operation `getOrganizationsWithGeneralSettings()` */
  static readonly GetOrganizationsWithGeneralSettingsPath = '/api/GeneralSettings/getOrganizationsWithGeneralSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationsWithGeneralSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsWithGeneralSettings$Response(
    params?: GetOrganizationsWithGeneralSettings$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationOr>>> {
    return getOrganizationsWithGeneralSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationsWithGeneralSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsWithGeneralSettings(
    params?: GetOrganizationsWithGeneralSettings$Params,
    context?: HttpContext
  ): Observable<Array<OrganizationOr>> {
    return this.getOrganizationsWithGeneralSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationOr>>): Array<OrganizationOr> => r.body)
    );
  }

  /** Path part for operation `getGeneralSettings()` */
  static readonly GetGeneralSettingsPath = '/api/GeneralSettings/getGeneralSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGeneralSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralSettings$Response(params?: GetGeneralSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationOr>> {
    return getGeneralSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGeneralSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralSettings(params?: GetGeneralSettings$Params, context?: HttpContext): Observable<OrganizationOr> {
    return this.getGeneralSettings$Response(params, context).pipe(map((r: StrictHttpResponse<OrganizationOr>): OrganizationOr => r.body));
  }

  /** Path part for operation `addOrUpdateGeneralSettings()` */
  static readonly AddOrUpdateGeneralSettingsPath = '/api/GeneralSettings/addOrUpdateGeneralSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrUpdateGeneralSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateGeneralSettings$Response(
    params: AddOrUpdateGeneralSettings$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrganizationOr>> {
    return addOrUpdateGeneralSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOrUpdateGeneralSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateGeneralSettings(params: AddOrUpdateGeneralSettings$Params, context?: HttpContext): Observable<OrganizationOr> {
    return this.addOrUpdateGeneralSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationOr>): OrganizationOr => r.body)
    );
  }

  /** Path part for operation `deleteGeneralSettings()` */
  static readonly DeleteGeneralSettingsPath = '/api/GeneralSettings/deleteGeneralSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteGeneralSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGeneralSettings$Response(params?: DeleteGeneralSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return deleteGeneralSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteGeneralSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGeneralSettings(params?: DeleteGeneralSettings$Params, context?: HttpContext): Observable<number> {
    return this.deleteGeneralSettings$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }
}
