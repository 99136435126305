/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActiveEmployeeRegistration } from '../models/active-employee-registration';
import { ActiveEmployeeRegistrationData } from '../models/active-employee-registration-data';
import { EmployeeInfo } from '../models/employee-info';
import { EmployeeRegistrationHistory } from '../models/employee-registration-history';
import { EmployeeRegistrationLoginDetails } from '../models/employee-registration-login-details';
import { EmployeeRegistrationShift } from '../models/employee-registration-shift';
import { getActiveEmployeeRegistrationData } from '../fn/employee-registration/get-active-employee-registration-data';
import { GetActiveEmployeeRegistrationData$Params } from '../fn/employee-registration/get-active-employee-registration-data';
import { getActiveLogin } from '../fn/employee-registration/get-active-login';
import { GetActiveLogin$Params } from '../fn/employee-registration/get-active-login';
import { getEmployees } from '../fn/employee-registration/get-employees';
import { GetEmployees$Params } from '../fn/employee-registration/get-employees';
import { getHistory } from '../fn/employee-registration/get-history';
import { GetHistory$Params } from '../fn/employee-registration/get-history';
import { getLoginDetails } from '../fn/employee-registration/get-login-details';
import { GetLoginDetails$Params } from '../fn/employee-registration/get-login-details';
import { getShiftsForFilter } from '../fn/employee-registration/get-shifts-for-filter';
import { GetShiftsForFilter$Params } from '../fn/employee-registration/get-shifts-for-filter';
import { getShiftsForFilterApproval } from '../fn/employee-registration/get-shifts-for-filter-approval';
import { GetShiftsForFilterApproval$Params } from '../fn/employee-registration/get-shifts-for-filter-approval';
import { getShiftsForFilterShiftReport } from '../fn/employee-registration/get-shifts-for-filter-shift-report';
import { GetShiftsForFilterShiftReport$Params } from '../fn/employee-registration/get-shifts-for-filter-shift-report';
import { login } from '../fn/employee-registration/login';
import { Login$Params } from '../fn/employee-registration/login';
import { logout } from '../fn/employee-registration/logout';
import { Logout$Params } from '../fn/employee-registration/logout';
import { updateRegistration } from '../fn/employee-registration/update-registration';
import { UpdateRegistration$Params } from '../fn/employee-registration/update-registration';

@Injectable({ providedIn: 'root' })
export class EmployeeRegistrationService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `login()` */
  static readonly LoginPath = '/api/v1/Login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Response(params: Login$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return login(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login(params: Login$Params, context?: HttpContext): Observable<void> {
    return this.login$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `logout()` */
  static readonly LogoutPath = '/api/v1/Logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logout$Response(params: Logout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return logout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logout(params: Logout$Params, context?: HttpContext): Observable<void> {
    return this.logout$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `updateRegistration()` */
  static readonly UpdateRegistrationPath = '/api/v1/UpdateRegistration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRegistration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRegistration$Response(params: UpdateRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRegistration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRegistration(params: UpdateRegistration$Params, context?: HttpContext): Observable<void> {
    return this.updateRegistration$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getActiveLogin()` */
  static readonly GetActiveLoginPath = '/api/v1/{workCenterId}/GetActiveLogin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveLogin$Response(
    params: GetActiveLogin$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActiveEmployeeRegistration>> {
    return getActiveLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveLogin(params: GetActiveLogin$Params, context?: HttpContext): Observable<ActiveEmployeeRegistration> {
    return this.getActiveLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveEmployeeRegistration>): ActiveEmployeeRegistration => r.body)
    );
  }

  /** Path part for operation `getActiveEmployeeRegistrationData()` */
  static readonly GetActiveEmployeeRegistrationDataPath = '/api/v1/{workCenterId}/GetActiveEmployeeRegistrationData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveEmployeeRegistrationData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveEmployeeRegistrationData$Response(
    params: GetActiveEmployeeRegistrationData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActiveEmployeeRegistrationData>> {
    return getActiveEmployeeRegistrationData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveEmployeeRegistrationData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveEmployeeRegistrationData(
    params: GetActiveEmployeeRegistrationData$Params,
    context?: HttpContext
  ): Observable<ActiveEmployeeRegistrationData> {
    return this.getActiveEmployeeRegistrationData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveEmployeeRegistrationData>): ActiveEmployeeRegistrationData => r.body)
    );
  }

  /** Path part for operation `getLoginDetails()` */
  static readonly GetLoginDetailsPath = '/api/v1/{workCenterId}/GetLoginDetails/{employeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoginDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoginDetails$Response(
    params: GetLoginDetails$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<EmployeeRegistrationLoginDetails>> {
    return getLoginDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLoginDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoginDetails(params: GetLoginDetails$Params, context?: HttpContext): Observable<EmployeeRegistrationLoginDetails> {
    return this.getLoginDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmployeeRegistrationLoginDetails>): EmployeeRegistrationLoginDetails => r.body)
    );
  }

  /** Path part for operation `getShiftsForFilter()` */
  static readonly GetShiftsForFilterPath = '/api/v1/{workCenterId}/GetShiftsForFilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShiftsForFilter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftsForFilter$Response(
    params: GetShiftsForFilter$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmployeeRegistrationShift>>> {
    return getShiftsForFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShiftsForFilter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftsForFilter(params: GetShiftsForFilter$Params, context?: HttpContext): Observable<Array<EmployeeRegistrationShift>> {
    return this.getShiftsForFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmployeeRegistrationShift>>): Array<EmployeeRegistrationShift> => r.body)
    );
  }

  /** Path part for operation `getShiftsForFilterApproval()` */
  static readonly GetShiftsForFilterApprovalPath = '/api/v1/{workCenterId}/GetShiftsForFilter/approvals/{approvalReportDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShiftsForFilterApproval()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftsForFilterApproval$Response(
    params: GetShiftsForFilterApproval$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmployeeRegistrationShift>>> {
    return getShiftsForFilterApproval(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShiftsForFilterApproval$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftsForFilterApproval(
    params: GetShiftsForFilterApproval$Params,
    context?: HttpContext
  ): Observable<Array<EmployeeRegistrationShift>> {
    return this.getShiftsForFilterApproval$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmployeeRegistrationShift>>): Array<EmployeeRegistrationShift> => r.body)
    );
  }

  /** Path part for operation `getShiftsForFilterShiftReport()` */
  static readonly GetShiftsForFilterShiftReportPath = '/api/v1/{workCenterId}/GetShiftsForFilter/selectedShift/{shiftId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShiftsForFilterShiftReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftsForFilterShiftReport$Response(
    params: GetShiftsForFilterShiftReport$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmployeeRegistrationShift>>> {
    return getShiftsForFilterShiftReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShiftsForFilterShiftReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftsForFilterShiftReport(
    params: GetShiftsForFilterShiftReport$Params,
    context?: HttpContext
  ): Observable<Array<EmployeeRegistrationShift>> {
    return this.getShiftsForFilterShiftReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmployeeRegistrationShift>>): Array<EmployeeRegistrationShift> => r.body)
    );
  }

  /** Path part for operation `getHistory()` */
  static readonly GetHistoryPath = '/api/v1/{workCenterId}/GetHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistory$Response(
    params: GetHistory$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<EmployeeRegistrationHistory>>> {
    return getHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistory(params: GetHistory$Params, context?: HttpContext): Observable<Array<EmployeeRegistrationHistory>> {
    return this.getHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmployeeRegistrationHistory>>): Array<EmployeeRegistrationHistory> => r.body)
    );
  }

  /** Path part for operation `getEmployees()` */
  static readonly GetEmployeesPath = '/api/v1/GetEmployees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployees()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployees$Response(params?: GetEmployees$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EmployeeInfo>>> {
    return getEmployees(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmployees$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployees(params?: GetEmployees$Params, context?: HttpContext): Observable<Array<EmployeeInfo>> {
    return this.getEmployees$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmployeeInfo>>): Array<EmployeeInfo> => r.body)
    );
  }
}
