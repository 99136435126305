/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FrontendConfig } from '../models/frontend-config';
import { FrontendFeatures } from '../models/frontend-features';
import { getConfig } from '../fn/frontend-config/get-config';
import { GetConfig$Params } from '../fn/frontend-config/get-config';
import { getFeatures } from '../fn/frontend-config/get-features';
import { GetFeatures$Params } from '../fn/frontend-config/get-features';

@Injectable({ providedIn: 'root' })
export class FrontendConfigService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getConfig()` */
  static readonly GetConfigPath = '/api/v1/FrontendConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfig$Response(params?: GetConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<FrontendConfig>> {
    return getConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfig(params?: GetConfig$Params, context?: HttpContext): Observable<FrontendConfig> {
    return this.getConfig$Response(params, context).pipe(map((r: StrictHttpResponse<FrontendConfig>): FrontendConfig => r.body));
  }

  /** Path part for operation `getFeatures()` */
  static readonly GetFeaturesPath = '/api/v1/FrontendFeatures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeatures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeatures$Response(params?: GetFeatures$Params, context?: HttpContext): Observable<StrictHttpResponse<FrontendFeatures>> {
    return getFeatures(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeatures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeatures(params?: GetFeatures$Params, context?: HttpContext): Observable<FrontendFeatures> {
    return this.getFeatures$Response(params, context).pipe(map((r: StrictHttpResponse<FrontendFeatures>): FrontendFeatures => r.body));
  }
}
