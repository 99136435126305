/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getStati } from '../fn/machine/get-stati';
import { GetStati$Params } from '../fn/machine/get-stati';
import { getStatus } from '../fn/machine/get-status';
import { GetStatus$Params } from '../fn/machine/get-status';
import { MachineData } from '../models/machine-data';
import { updateInfoData } from '../fn/machine/update-info-data';
import { UpdateInfoData$Params } from '../fn/machine/update-info-data';

@Injectable({ providedIn: 'root' })
export class MachineService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateInfoData()` */
  static readonly UpdateInfoDataPath = '/api/v1/Machine/{workCenterId}/InfoData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInfoData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInfoData$Response(params: UpdateInfoData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateInfoData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInfoData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInfoData(params: UpdateInfoData$Params, context?: HttpContext): Observable<void> {
    return this.updateInfoData$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getStatus()` */
  static readonly GetStatusPath = '/api/v1/Machine/{workCenterId}/GetStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatus$Response(params: GetStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineData>> {
    return getStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatus(params: GetStatus$Params, context?: HttpContext): Observable<MachineData> {
    return this.getStatus$Response(params, context).pipe(map((r: StrictHttpResponse<MachineData>): MachineData => r.body));
  }

  /** Path part for operation `getStati()` */
  static readonly GetStatiPath = '/api/v1/Machine/GetStati';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStati()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getStati$Response(params: GetStati$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MachineData>>> {
    return getStati(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStati$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getStati(params: GetStati$Params, context?: HttpContext): Observable<Array<MachineData>> {
    return this.getStati$Response(params, context).pipe(map((r: StrictHttpResponse<Array<MachineData>>): Array<MachineData> => r.body));
  }
}
