import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppInitService } from '@bd/app-init.service';
import { SplashScreenService } from 'chronos-shared';
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  public asideEnabled = true;
  public showSpinner = false;
  private subscriptions = new Subscription();
  public productionOrderId = null;
  constructor(private appInitService: AppInitService, private splashScreenService: SplashScreenService) {}

  public ngOnInit(): void {
    this.appInitService.loadingIndicatorSubject$.subscribe((val) => {
      this.splashScreenService.setSplashScreenVisibility(val);
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
