/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AclAccessRequest } from '../models/acl-access-request';
import { AclAccessRoleList } from '../models/acl-access-role-list';
import { AclfunctionAf } from '../models/aclfunction-af';
import { AcloperationAo } from '../models/acloperation-ao';
import { AclroleAr } from '../models/aclrole-ar';
import { addAccess } from '../fn/acl-role/add-access';
import { AddAccess$Params } from '../fn/acl-role/add-access';
import { addRoleDataAccess } from '../fn/acl-role/add-role-data-access';
import { AddRoleDataAccess$Params } from '../fn/acl-role/add-role-data-access';
import { AuthFunctionOperation } from '../models/auth-function-operation';
import { AuthWidgetOperation } from '../models/auth-widget-operation';
import { checkAuthorization } from '../fn/acl-role/check-authorization';
import { CheckAuthorization$Params } from '../fn/acl-role/check-authorization';
import { deleteAccess } from '../fn/acl-role/delete-access';
import { DeleteAccess$Params } from '../fn/acl-role/delete-access';
import { getAccessData } from '../fn/acl-role/get-access-data';
import { GetAccessData$Params } from '../fn/acl-role/get-access-data';
import { getAccessOfComponent } from '../fn/acl-role/get-access-of-component';
import { GetAccessOfComponent$Params } from '../fn/acl-role/get-access-of-component';
import { getAclAccessDataForEdit } from '../fn/acl-role/get-acl-access-data-for-edit';
import { GetAclAccessDataForEdit$Params } from '../fn/acl-role/get-acl-access-data-for-edit';
import { getAclAccessRoles } from '../fn/acl-role/get-acl-access-roles';
import { GetAclAccessRoles$Params } from '../fn/acl-role/get-acl-access-roles';
import { getAclRoleNamesByOrganization } from '../fn/acl-role/get-acl-role-names-by-organization';
import { GetAclRoleNamesByOrganization$Params } from '../fn/acl-role/get-acl-role-names-by-organization';
import { getAclWidgets } from '../fn/acl-role/get-acl-widgets';
import { GetAclWidgets$Params } from '../fn/acl-role/get-acl-widgets';
import { getAllAclFunctionNamesByWidget } from '../fn/acl-role/get-all-acl-function-names-by-widget';
import { GetAllAclFunctionNamesByWidget$Params } from '../fn/acl-role/get-all-acl-function-names-by-widget';
import { getAllAclFunctions } from '../fn/acl-role/get-all-acl-functions';
import { GetAllAclFunctions$Params } from '../fn/acl-role/get-all-acl-functions';
import { getAllAclOperations } from '../fn/acl-role/get-all-acl-operations';
import { GetAllAclOperations$Params } from '../fn/acl-role/get-all-acl-operations';
import { getAllAclPagesNames } from '../fn/acl-role/get-all-acl-pages-names';
import { GetAllAclPagesNames$Params } from '../fn/acl-role/get-all-acl-pages-names';
import { getAllAclPageWidgets } from '../fn/acl-role/get-all-acl-page-widgets';
import { GetAllAclPageWidgets$Params } from '../fn/acl-role/get-all-acl-page-widgets';
import { getAllAclWidgets } from '../fn/acl-role/get-all-acl-widgets';
import { GetAllAclWidgets$Params } from '../fn/acl-role/get-all-acl-widgets';
import { getAllAuthFunctionOperation } from '../fn/acl-role/get-all-auth-function-operation';
import { GetAllAuthFunctionOperation$Params } from '../fn/acl-role/get-all-auth-function-operation';
import { getAllAuthWidgetFunctionOperation } from '../fn/acl-role/get-all-auth-widget-function-operation';
import { GetAllAuthWidgetFunctionOperation$Params } from '../fn/acl-role/get-all-auth-widget-function-operation';
import { getAllPageWidgetsOfRole } from '../fn/acl-role/get-all-page-widgets-of-role';
import { GetAllPageWidgetsOfRole$Params } from '../fn/acl-role/get-all-page-widgets-of-role';
import { getApiWidgetOperations } from '../fn/acl-role/get-api-widget-operations';
import { GetApiWidgetOperations$Params } from '../fn/acl-role/get-api-widget-operations';
import { getRoleDataAccess } from '../fn/acl-role/get-role-data-access';
import { GetRoleDataAccess$Params } from '../fn/acl-role/get-role-data-access';
import { getRoleDataAccessByRole } from '../fn/acl-role/get-role-data-access-by-role';
import { GetRoleDataAccessByRole$Params } from '../fn/acl-role/get-role-data-access-by-role';
import { getRoleDataAccessList } from '../fn/acl-role/get-role-data-access-list';
import { GetRoleDataAccessList$Params } from '../fn/acl-role/get-role-data-access-list';
import { getWidgetFunctionAndEmptyOperations } from '../fn/acl-role/get-widget-function-and-empty-operations';
import { GetWidgetFunctionAndEmptyOperations$Params } from '../fn/acl-role/get-widget-function-and-empty-operations';
import { getWidgetFunctionAndOperationsWithValue } from '../fn/acl-role/get-widget-function-and-operations-with-value';
import { GetWidgetFunctionAndOperationsWithValue$Params } from '../fn/acl-role/get-widget-function-and-operations-with-value';
import { PageAccessDetails } from '../models/page-access-details';
import { PageWidgetDetail } from '../models/page-widget-detail';
import { RoleDataAccessCustom } from '../models/role-data-access-custom';
import { RoledataaccessRa } from '../models/roledataaccess-ra';
import { updateAccess } from '../fn/acl-role/update-access';
import { UpdateAccess$Params } from '../fn/acl-role/update-access';
import { updateAclAccessRole } from '../fn/acl-role/update-acl-access-role';
import { UpdateAclAccessRole$Params } from '../fn/acl-role/update-acl-access-role';
import { updateRoleDataAccess } from '../fn/acl-role/update-role-data-access';
import { UpdateRoleDataAccess$Params } from '../fn/acl-role/update-role-data-access';
import { UxpageUp } from '../models/uxpage-up';
import { UxwidgetUw } from '../models/uxwidget-uw';
import { WidgetFunctionOperation } from '../models/widget-function-operation';

@Injectable({ providedIn: 'root' })
export class AclRoleService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAclAccessRoles()` */
  static readonly GetAclAccessRolesPath = '/api/AclRole/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAclAccessRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAclAccessRoles$Response(
    params: GetAclAccessRoles$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AclAccessRoleList>>> {
    return getAclAccessRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAclAccessRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAclAccessRoles(params: GetAclAccessRoles$Params, context?: HttpContext): Observable<Array<AclAccessRoleList>> {
    return this.getAclAccessRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AclAccessRoleList>>): Array<AclAccessRoleList> => r.body)
    );
  }

  /** Path part for operation `getAclAccessDataForEdit()` */
  static readonly GetAclAccessDataForEditPath = '/api/AclRole/aclAccessDataForEdit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAclAccessDataForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAclAccessDataForEdit$Response(
    params?: GetAclAccessDataForEdit$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AclAccessRoleList>>> {
    return getAclAccessDataForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAclAccessDataForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAclAccessDataForEdit(params?: GetAclAccessDataForEdit$Params, context?: HttpContext): Observable<Array<AclAccessRoleList>> {
    return this.getAclAccessDataForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AclAccessRoleList>>): Array<AclAccessRoleList> => r.body)
    );
  }

  /** Path part for operation `getAccessData()` */
  static readonly GetAccessDataPath = '/api/AclRole/getAccessData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessData$Response(params?: GetAccessData$Params, context?: HttpContext): Observable<StrictHttpResponse<AclAccessRequest>> {
    return getAccessData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccessData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessData(params?: GetAccessData$Params, context?: HttpContext): Observable<AclAccessRequest> {
    return this.getAccessData$Response(params, context).pipe(map((r: StrictHttpResponse<AclAccessRequest>): AclAccessRequest => r.body));
  }

  /** Path part for operation `getAclRoleNamesByOrganization()` */
  static readonly GetAclRoleNamesByOrganizationPath = '/api/AclRole/aclRoleNames';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAclRoleNamesByOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAclRoleNamesByOrganization$Response(
    params?: GetAclRoleNamesByOrganization$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AclroleAr>>> {
    return getAclRoleNamesByOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAclRoleNamesByOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAclRoleNamesByOrganization(params?: GetAclRoleNamesByOrganization$Params, context?: HttpContext): Observable<Array<AclroleAr>> {
    return this.getAclRoleNamesByOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AclroleAr>>): Array<AclroleAr> => r.body)
    );
  }

  /** Path part for operation `getAccessOfComponent()` */
  static readonly GetAccessOfComponentPath = '/api/AclRole/accessOfComponent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessOfComponent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessOfComponent$Response(
    params?: GetAccessOfComponent$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PageAccessDetails>>> {
    return getAccessOfComponent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccessOfComponent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessOfComponent(params?: GetAccessOfComponent$Params, context?: HttpContext): Observable<Array<PageAccessDetails>> {
    return this.getAccessOfComponent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PageAccessDetails>>): Array<PageAccessDetails> => r.body)
    );
  }

  /** Path part for operation `updateAclAccessRole()` */
  static readonly UpdateAclAccessRolePath = '/api/AclRole/updateAclAccessRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAclAccessRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAclAccessRole$Response(params: UpdateAclAccessRole$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateAclAccessRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAclAccessRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAclAccessRole(params: UpdateAclAccessRole$Params, context?: HttpContext): Observable<boolean> {
    return this.updateAclAccessRole$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `addAccess()` */
  static readonly AddAccessPath = '/api/AclRole/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAccess$Response(params: AddAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return addAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAccess(params: AddAccess$Params, context?: HttpContext): Observable<boolean> {
    return this.addAccess$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `updateAccess()` */
  static readonly UpdateAccessPath = '/api/AclRole/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccess$Response(params: UpdateAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccess(params: UpdateAccess$Params, context?: HttpContext): Observable<boolean> {
    return this.updateAccess$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `deleteAccess()` */
  static readonly DeleteAccessPath = '/api/AclRole/deleteAccess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccess$Response(params?: DeleteAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccess(params?: DeleteAccess$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteAccess$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `checkAuthorization()` */
  static readonly CheckAuthorizationPath = '/api/AclRole/checkAuthorization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAuthorization()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAuthorization$Response(params?: CheckAuthorization$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return checkAuthorization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAuthorization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAuthorization(params?: CheckAuthorization$Params, context?: HttpContext): Observable<boolean> {
    return this.checkAuthorization$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `getAllAclPagesNames()` */
  static readonly GetAllAclPagesNamesPath = '/api/AclRole/aclPageNames';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAclPagesNames()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAclPagesNames$Response(
    params?: GetAllAclPagesNames$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UxpageUp>>> {
    return getAllAclPagesNames(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAclPagesNames$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAclPagesNames(params?: GetAllAclPagesNames$Params, context?: HttpContext): Observable<Array<UxpageUp>> {
    return this.getAllAclPagesNames$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UxpageUp>>): Array<UxpageUp> => r.body)
    );
  }

  /** Path part for operation `getAllAclFunctionNamesByWidget()` */
  static readonly GetAllAclFunctionNamesByWidgetPath = '/api/AclRole/aclFunctionNamesByWidget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAclFunctionNamesByWidget()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAclFunctionNamesByWidget$Response(
    params?: GetAllAclFunctionNamesByWidget$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AclfunctionAf>>> {
    return getAllAclFunctionNamesByWidget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAclFunctionNamesByWidget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAclFunctionNamesByWidget(params?: GetAllAclFunctionNamesByWidget$Params, context?: HttpContext): Observable<Array<AclfunctionAf>> {
    return this.getAllAclFunctionNamesByWidget$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AclfunctionAf>>): Array<AclfunctionAf> => r.body)
    );
  }

  /** Path part for operation `getAllAclOperations()` */
  static readonly GetAllAclOperationsPath = '/api/AclRole/aclOperations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAclOperations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAclOperations$Response(
    params?: GetAllAclOperations$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AcloperationAo>>> {
    return getAllAclOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAclOperations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAclOperations(params?: GetAllAclOperations$Params, context?: HttpContext): Observable<Array<AcloperationAo>> {
    return this.getAllAclOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AcloperationAo>>): Array<AcloperationAo> => r.body)
    );
  }

  /** Path part for operation `getAllAclWidgets()` */
  static readonly GetAllAclWidgetsPath = '/api/AclRole/aclWidgets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAclWidgets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllAclWidgets$Response(params: GetAllAclWidgets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UxwidgetUw>>> {
    return getAllAclWidgets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAclWidgets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllAclWidgets(params: GetAllAclWidgets$Params, context?: HttpContext): Observable<Array<UxwidgetUw>> {
    return this.getAllAclWidgets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UxwidgetUw>>): Array<UxwidgetUw> => r.body)
    );
  }

  /** Path part for operation `getAllAclPageWidgets()` */
  static readonly GetAllAclPageWidgetsPath = '/api/AclRole/aclPageWidgets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAclPageWidgets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllAclPageWidgets$Response(
    params: GetAllAclPageWidgets$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PageWidgetDetail>>> {
    return getAllAclPageWidgets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAclPageWidgets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllAclPageWidgets(params: GetAllAclPageWidgets$Params, context?: HttpContext): Observable<Array<PageWidgetDetail>> {
    return this.getAllAclPageWidgets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PageWidgetDetail>>): Array<PageWidgetDetail> => r.body)
    );
  }

  /** Path part for operation `getAllPageWidgetsOfRole()` */
  static readonly GetAllPageWidgetsOfRolePath = '/api/AclRole/aclPageWidgetsWithRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPageWidgetsOfRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllPageWidgetsOfRole$Response(
    params: GetAllPageWidgetsOfRole$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PageWidgetDetail>>> {
    return getAllPageWidgetsOfRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPageWidgetsOfRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllPageWidgetsOfRole(params: GetAllPageWidgetsOfRole$Params, context?: HttpContext): Observable<Array<PageWidgetDetail>> {
    return this.getAllPageWidgetsOfRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PageWidgetDetail>>): Array<PageWidgetDetail> => r.body)
    );
  }

  /** Path part for operation `getApiWidgetOperations()` */
  static readonly GetApiWidgetOperationsPath = '/api/AclRole/getApiWidgetOperations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiWidgetOperations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiWidgetOperations$Response(
    params?: GetApiWidgetOperations$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PageWidgetDetail>> {
    return getApiWidgetOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiWidgetOperations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiWidgetOperations(params?: GetApiWidgetOperations$Params, context?: HttpContext): Observable<PageWidgetDetail> {
    return this.getApiWidgetOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageWidgetDetail>): PageWidgetDetail => r.body)
    );
  }

  /** Path part for operation `getAclWidgets()` */
  static readonly GetAclWidgetsPath = '/api/AclRole/allAclWidgets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAclWidgets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAclWidgets$Response(params?: GetAclWidgets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UxwidgetUw>>> {
    return getAclWidgets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAclWidgets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAclWidgets(params?: GetAclWidgets$Params, context?: HttpContext): Observable<Array<UxwidgetUw>> {
    return this.getAclWidgets$Response(params, context).pipe(map((r: StrictHttpResponse<Array<UxwidgetUw>>): Array<UxwidgetUw> => r.body));
  }

  /** Path part for operation `getAllAclFunctions()` */
  static readonly GetAllAclFunctionsPath = '/api/AclRole/aclFunctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAclFunctions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAclFunctions$Response(
    params?: GetAllAclFunctions$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AclfunctionAf>>> {
    return getAllAclFunctions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAclFunctions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAclFunctions(params?: GetAllAclFunctions$Params, context?: HttpContext): Observable<Array<AclfunctionAf>> {
    return this.getAllAclFunctions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AclfunctionAf>>): Array<AclfunctionAf> => r.body)
    );
  }

  /** Path part for operation `getWidgetFunctionAndEmptyOperations()` */
  static readonly GetWidgetFunctionAndEmptyOperationsPath = '/api/AclRole/widgetFunctionAndEmptyOperations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgetFunctionAndEmptyOperations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetFunctionAndEmptyOperations$Response(
    params?: GetWidgetFunctionAndEmptyOperations$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WidgetFunctionOperation>>> {
    return getWidgetFunctionAndEmptyOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWidgetFunctionAndEmptyOperations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetFunctionAndEmptyOperations(
    params?: GetWidgetFunctionAndEmptyOperations$Params,
    context?: HttpContext
  ): Observable<Array<WidgetFunctionOperation>> {
    return this.getWidgetFunctionAndEmptyOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WidgetFunctionOperation>>): Array<WidgetFunctionOperation> => r.body)
    );
  }

  /** Path part for operation `getWidgetFunctionAndOperationsWithValue()` */
  static readonly GetWidgetFunctionAndOperationsWithValuePath = '/api/AclRole/widgetFunctionAndOperationsWithValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgetFunctionAndOperationsWithValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetFunctionAndOperationsWithValue$Response(
    params?: GetWidgetFunctionAndOperationsWithValue$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WidgetFunctionOperation>>> {
    return getWidgetFunctionAndOperationsWithValue(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWidgetFunctionAndOperationsWithValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetFunctionAndOperationsWithValue(
    params?: GetWidgetFunctionAndOperationsWithValue$Params,
    context?: HttpContext
  ): Observable<Array<WidgetFunctionOperation>> {
    return this.getWidgetFunctionAndOperationsWithValue$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WidgetFunctionOperation>>): Array<WidgetFunctionOperation> => r.body)
    );
  }

  /** Path part for operation `getRoleDataAccess()` */
  static readonly GetRoleDataAccessPath = '/api/AclRole/roleData/{arid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoleDataAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleDataAccess$Response(
    params: GetRoleDataAccess$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<RoledataaccessRa>>> {
    return getRoleDataAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoleDataAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleDataAccess(params: GetRoleDataAccess$Params, context?: HttpContext): Observable<Array<RoledataaccessRa>> {
    return this.getRoleDataAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoledataaccessRa>>): Array<RoledataaccessRa> => r.body)
    );
  }

  /** Path part for operation `getRoleDataAccessList()` */
  static readonly GetRoleDataAccessListPath = '/api/AclRole/roleData/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoleDataAccessList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleDataAccessList$Response(
    params?: GetRoleDataAccessList$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<RoleDataAccessCustom>>> {
    return getRoleDataAccessList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoleDataAccessList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleDataAccessList(params?: GetRoleDataAccessList$Params, context?: HttpContext): Observable<Array<RoleDataAccessCustom>> {
    return this.getRoleDataAccessList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoleDataAccessCustom>>): Array<RoleDataAccessCustom> => r.body)
    );
  }

  /** Path part for operation `addRoleDataAccess()` */
  static readonly AddRoleDataAccessPath = '/api/AclRole/roleData/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRoleDataAccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRoleDataAccess$Response(params: AddRoleDataAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return addRoleDataAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRoleDataAccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRoleDataAccess(params: AddRoleDataAccess$Params, context?: HttpContext): Observable<boolean> {
    return this.addRoleDataAccess$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `getAllAuthFunctionOperation()` */
  static readonly GetAllAuthFunctionOperationPath = '/api/AclRole/role/list/authOperation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAuthFunctionOperation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAuthFunctionOperation$Response(
    params?: GetAllAuthFunctionOperation$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AuthFunctionOperation>>> {
    return getAllAuthFunctionOperation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAuthFunctionOperation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAuthFunctionOperation(
    params?: GetAllAuthFunctionOperation$Params,
    context?: HttpContext
  ): Observable<Array<AuthFunctionOperation>> {
    return this.getAllAuthFunctionOperation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AuthFunctionOperation>>): Array<AuthFunctionOperation> => r.body)
    );
  }

  /** Path part for operation `getAllAuthWidgetFunctionOperation()` */
  static readonly GetAllAuthWidgetFunctionOperationPath = '/api/AclRole/role/list/authWidgetOperation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAuthWidgetFunctionOperation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAuthWidgetFunctionOperation$Response(
    params?: GetAllAuthWidgetFunctionOperation$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AuthWidgetOperation>>> {
    return getAllAuthWidgetFunctionOperation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAuthWidgetFunctionOperation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAuthWidgetFunctionOperation(
    params?: GetAllAuthWidgetFunctionOperation$Params,
    context?: HttpContext
  ): Observable<Array<AuthWidgetOperation>> {
    return this.getAllAuthWidgetFunctionOperation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AuthWidgetOperation>>): Array<AuthWidgetOperation> => r.body)
    );
  }

  /** Path part for operation `updateRoleDataAccess()` */
  static readonly UpdateRoleDataAccessPath = '/api/AclRole/roleData/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRoleDataAccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRoleDataAccess$Response(params: UpdateRoleDataAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateRoleDataAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRoleDataAccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRoleDataAccess(params: UpdateRoleDataAccess$Params, context?: HttpContext): Observable<boolean> {
    return this.updateRoleDataAccess$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `getRoleDataAccessByRole()` */
  static readonly GetRoleDataAccessByRolePath = '/api/AclRole/roleDataByArid/{arid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoleDataAccessByRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleDataAccessByRole$Response(
    params: GetRoleDataAccessByRole$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<RoleDataAccessCustom>> {
    return getRoleDataAccessByRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoleDataAccessByRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleDataAccessByRole(params: GetRoleDataAccessByRole$Params, context?: HttpContext): Observable<RoleDataAccessCustom> {
    return this.getRoleDataAccessByRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDataAccessCustom>): RoleDataAccessCustom => r.body)
    );
  }
}
