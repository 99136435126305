import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  OperationTypeViewModel,
  ShiftCalendarViewModel,
  WorkCenterViewModel,
  WorkCenterService,
  WorkCenterGroupViewModel,
  WarehouseLocationViewModel
} from 'chronos-basedata-client';
import { Quantity } from 'chronos-core-client';

export interface WorkCenterEditData {
  counterUnitId: string;
  declaredPerformance?: number | null;
  inputWarehouseLocation: WarehouseLocationViewModel;
  isActive: boolean;
  operationTypeId: number;
  outputWarehouseLocation: WarehouseLocationViewModel;
  processPerQuantity: number;
  requiresApproval: boolean;
  rowVersion: number;
  shiftCalendarId: number;
  workCenterId: number;
}

@Injectable({
  providedIn: 'root'
})
export class WorkCenterDsService {
  constructor(private workCenterService: WorkCenterService) {}

  public getWorkCenters(searchQuery?: string, filterForActive?: boolean, filterForSite?: boolean): Observable<WorkCenterViewModel[]> {
    return this.workCenterService.getWorkCenters({
      workCenterId: null,
      searchQuery,
      workCenterExternalId: null,
      filterForActive,
      filterForSite
    });
  }

  public getWorkCenterById(workCenterId: number): Observable<WorkCenterViewModel> {
    return this.workCenterService.getWorkCenterById({
      workCenterId
    });
  }

  public getWorkCenterByNameOrExternalId(searchQuery: string): Observable<WorkCenterViewModel> {
    return this.workCenterService.getWorkCenterByNameOrExternalId({
      searchQuery
    });
  }

  public getWorkCenterGroups(): Observable<WorkCenterGroupViewModel[]> {
    return this.workCenterService.getWorkCenterGroups();
  }

  public getShiftCalendars(): Observable<ShiftCalendarViewModel[]> {
    return this.workCenterService.getShiftCalendars();
  }

  public getOperationTypes(): Observable<OperationTypeViewModel[]> {
    return this.workCenterService.getOperationTypes();
  }

  public updateWorkCenters(entry: WorkCenterEditData[]): Observable<void> {
    const data = entry.map((item) => ({
      counterUnitId: item.counterUnitId,
      declaredPerformance: { value: item.declaredPerformance, unitId: item.counterUnitId } as Quantity,
      inputWarehouseLocationId: item.inputWarehouseLocation.id,
      isActive: item.isActive,
      operationTypeId: item.operationTypeId,
      outputWarehouseLocationId: item.outputWarehouseLocation.id,
      processPerQuantity: { value: item.processPerQuantity, unitId: item.counterUnitId } as Quantity,
      requiresApproval: item.requiresApproval,
      rowVersion: item.rowVersion,
      shiftCalendarId: item.shiftCalendarId,
      workCenterId: item.workCenterId
    }));

    const params = {
      body: data
    };

    return this.workCenterService.updateWorkCenters(params);
  }
}
