/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { editRemark } from '../fn/remark/edit-remark';
import { EditRemark$Params } from '../fn/remark/edit-remark';
import { getRemark } from '../fn/remark/get-remark';
import { GetRemark$Params } from '../fn/remark/get-remark';
import { Remark } from '../models/remark';

@Injectable({ providedIn: 'root' })
export class RemarkService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRemark()` */
  static readonly GetRemarkPath = '/api/v1/Remark/{remarkId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRemark()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemark$Response(params: GetRemark$Params, context?: HttpContext): Observable<StrictHttpResponse<Remark>> {
    return getRemark(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRemark$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemark(params: GetRemark$Params, context?: HttpContext): Observable<Remark> {
    return this.getRemark$Response(params, context).pipe(map((r: StrictHttpResponse<Remark>): Remark => r.body));
  }

  /** Path part for operation `editRemark()` */
  static readonly EditRemarkPath = '/api/v1/Remark/{remarkId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editRemark()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editRemark$Response(params: EditRemark$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editRemark(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editRemark$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editRemark(params: EditRemark$Params, context?: HttpContext): Observable<void> {
    return this.editRemark$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }
}
