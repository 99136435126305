/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EmployeeRegistrationHistory } from '../../models/employee-registration-history';

export interface GetHistory$Params {
  workCenterId: number;
  shiftIds?: Array<number>;
}

export function getHistory(
  http: HttpClient,
  rootUrl: string,
  params: GetHistory$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<EmployeeRegistrationHistory>>> {
  const rb = new RequestBuilder(rootUrl, getHistory.PATH, 'get');
  if (params) {
    rb.path('workCenterId', params.workCenterId, {});
    rb.query('shiftIds', params.shiftIds, { style: 'form', explode: true });
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<EmployeeRegistrationHistory>>;
    })
  );
}

getHistory.PATH = '/api/v1/{workCenterId}/GetHistory';
