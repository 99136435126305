/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { checkQaHistory } from '../fn/quality-history/check-qa-history';
import { CheckQaHistory$Params } from '../fn/quality-history/check-qa-history';
import { createNonConformance } from '../fn/quality-history/create-non-conformance';
import { CreateNonConformance } from '../models/create-non-conformance';
import { CreateNonConformance$Params } from '../fn/quality-history/create-non-conformance';
import { FinishedGoodArticleItem } from '../models/finished-good-article-item';
import { getFinishedGoodArticles } from '../fn/quality-history/get-finished-good-articles';
import { GetFinishedGoodArticles$Params } from '../fn/quality-history/get-finished-good-articles';
import { getNonConformance } from '../fn/quality-history/get-non-conformance';
import { GetNonConformance$Params } from '../fn/quality-history/get-non-conformance';
import { getNonConformance2 } from '../fn/quality-history/get-non-conformance-2';
import { GetNonConformance2$Params } from '../fn/quality-history/get-non-conformance-2';
import { getNonConformanceOverview } from '../fn/quality-history/get-non-conformance-overview';
import { GetNonConformanceOverview$Params } from '../fn/quality-history/get-non-conformance-overview';
import { NonConformanceItem } from '../models/non-conformance-item';
import { NonConformanceModel } from '../models/non-conformance-model';
import { setQaToolCheckoutConfirm } from '../fn/quality-history/set-qa-tool-checkout-confirm';
import { SetQaToolCheckoutConfirm$Params } from '../fn/quality-history/set-qa-tool-checkout-confirm';

@Injectable({ providedIn: 'root' })
export class QualityHistoryService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `checkQaHistory()` */
  static readonly CheckQaHistoryPath = '/api/v1/QualityHistory/{productionOrderId}/Check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkQaHistory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkQaHistory$Response(params: CheckQaHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkQaHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkQaHistory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkQaHistory(params: CheckQaHistory$Params, context?: HttpContext): Observable<void> {
    return this.checkQaHistory$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `createNonConformance()` */
  static readonly CreateNonConformancePath = '/api/v1/QualityHistory/{productionOrderId}/CreateNonConformance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNonConformance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNonConformance$Response(params: CreateNonConformance$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createNonConformance(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNonConformance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNonConformance(params: CreateNonConformance$Params, context?: HttpContext): Observable<void> {
    return this.createNonConformance$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setQaToolCheckoutConfirm()` */
  static readonly SetQaToolCheckoutConfirmPath = '/api/v1/QualityHistory/{productionOrderId}/SetQaToolCheckoutConfirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setQaToolCheckoutConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setQaToolCheckoutConfirm$Response(params: SetQaToolCheckoutConfirm$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setQaToolCheckoutConfirm(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setQaToolCheckoutConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setQaToolCheckoutConfirm(params: SetQaToolCheckoutConfirm$Params, context?: HttpContext): Observable<void> {
    return this.setQaToolCheckoutConfirm$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getFinishedGoodArticles()` */
  static readonly GetFinishedGoodArticlesPath = '/api/v1/QualityHistory/{workCenterId}/GetFinishedGoodArticles/{loadOnlyForActiveOrder}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinishedGoodArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinishedGoodArticles$Response(
    params: GetFinishedGoodArticles$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FinishedGoodArticleItem>>> {
    return getFinishedGoodArticles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinishedGoodArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinishedGoodArticles(params: GetFinishedGoodArticles$Params, context?: HttpContext): Observable<Array<FinishedGoodArticleItem>> {
    return this.getFinishedGoodArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FinishedGoodArticleItem>>): Array<FinishedGoodArticleItem> => r.body)
    );
  }

  /** Path part for operation `getNonConformanceOverview()` */
  static readonly GetNonConformanceOverviewPath = '/api/v1/QualityHistory/{workCenterId}/GetNonConformanceOverview/{articleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNonConformanceOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNonConformanceOverview$Response(
    params: GetNonConformanceOverview$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<NonConformanceItem>>> {
    return getNonConformanceOverview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNonConformanceOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNonConformanceOverview(params: GetNonConformanceOverview$Params, context?: HttpContext): Observable<Array<NonConformanceItem>> {
    return this.getNonConformanceOverview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NonConformanceItem>>): Array<NonConformanceItem> => r.body)
    );
  }

  /** Path part for operation `getNonConformance()` */
  static readonly GetNonConformancePath = '/api/v1/QualityHistory/{nonConformanceId}/GetNonConformance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNonConformance()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNonConformance$Response(params: GetNonConformance$Params, context?: HttpContext): Observable<StrictHttpResponse<NonConformanceModel>> {
    return getNonConformance(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNonConformance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNonConformance(params: GetNonConformance$Params, context?: HttpContext): Observable<NonConformanceModel> {
    return this.getNonConformance$Response(params, context).pipe(
      map((r: StrictHttpResponse<NonConformanceModel>): NonConformanceModel => r.body)
    );
  }

  /** Path part for operation `getNonConformance2()` */
  static readonly GetNonConformance2Path = '/api/v1/QualityHistory/GetCreateNonConformance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNonConformance2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNonConformance2$Response(
    params?: GetNonConformance2$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreateNonConformance>> {
    return getNonConformance2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNonConformance2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNonConformance2(params?: GetNonConformance2$Params, context?: HttpContext): Observable<CreateNonConformance> {
    return this.getNonConformance2$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateNonConformance>): CreateNonConformance => r.body)
    );
  }
}
