/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ScanContainerEntry } from '../../models/scan-container-entry';
import { ScanContainerResponse } from '../../models/scan-container-response';

export interface ScanContainer$Params {
  workCenterId: number;
  body: ScanContainerEntry;
}

export function scanContainer(
  http: HttpClient,
  rootUrl: string,
  params: ScanContainer$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<ScanContainerResponse>> {
  const rb = new RequestBuilder(rootUrl, scanContainer.PATH, 'post');
  if (params) {
    rb.path('workCenterId', params.workCenterId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ScanContainerResponse>;
    })
  );
}

scanContainer.PATH = '/api/v1/WorkCenter/{workCenterId}/Scan';
