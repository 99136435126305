/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TimezoneTz } from '../../models/timezone-tz';

export interface GetTimeZones$Params {
  orid: number;
}

export function getTimeZones(
  http: HttpClient,
  rootUrl: string,
  params: GetTimeZones$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<TimezoneTz>>> {
  const rb = new RequestBuilder(rootUrl, getTimeZones.PATH, 'get');
  if (params) {
    rb.path('orid', params.orid, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<TimezoneTz>>;
    })
  );
}

getTimeZones.PATH = '/gettimezones/{orid}';
