/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addDeviceFeature } from '../fn/features/add-device-feature';
import { AddDeviceFeature$Params } from '../fn/features/add-device-feature';
import { deleteDeviceFeature } from '../fn/features/delete-device-feature';
import { DeleteDeviceFeature$Params } from '../fn/features/delete-device-feature';
import { FeatureFe } from '../models/feature-fe';
import { FeatureList } from '../models/feature-list';
import { listOrganizationFeatures } from '../fn/features/list-organization-features';
import { ListOrganizationFeatures$Params } from '../fn/features/list-organization-features';
import { updateDeviceFeature } from '../fn/features/update-device-feature';
import { UpdateDeviceFeature$Params } from '../fn/features/update-device-feature';

@Injectable({ providedIn: 'root' })
export class FeaturesService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listOrganizationFeatures()` */
  static readonly ListOrganizationFeaturesPath = '/api/Features/org/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrganizationFeatures()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrganizationFeatures$Response(
    params: ListOrganizationFeatures$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FeatureList>>> {
    return listOrganizationFeatures(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrganizationFeatures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrganizationFeatures(params: ListOrganizationFeatures$Params, context?: HttpContext): Observable<Array<FeatureList>> {
    return this.listOrganizationFeatures$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeatureList>>): Array<FeatureList> => r.body)
    );
  }

  /** Path part for operation `updateDeviceFeature()` */
  static readonly UpdateDeviceFeaturePath = '/api/Features';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDeviceFeature()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDeviceFeature$Response(params: UpdateDeviceFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<FeatureFe>> {
    return updateDeviceFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDeviceFeature$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDeviceFeature(params: UpdateDeviceFeature$Params, context?: HttpContext): Observable<FeatureFe> {
    return this.updateDeviceFeature$Response(params, context).pipe(map((r: StrictHttpResponse<FeatureFe>): FeatureFe => r.body));
  }

  /** Path part for operation `addDeviceFeature()` */
  static readonly AddDeviceFeaturePath = '/api/Features';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDeviceFeature()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addDeviceFeature$Response(params: AddDeviceFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<FeatureFe>> {
    return addDeviceFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDeviceFeature$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addDeviceFeature(params: AddDeviceFeature$Params, context?: HttpContext): Observable<FeatureFe> {
    return this.addDeviceFeature$Response(params, context).pipe(map((r: StrictHttpResponse<FeatureFe>): FeatureFe => r.body));
  }

  /** Path part for operation `deleteDeviceFeature()` */
  static readonly DeleteDeviceFeaturePath = '/api/Features';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDeviceFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDeviceFeature$Response(params?: DeleteDeviceFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return deleteDeviceFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDeviceFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDeviceFeature(params?: DeleteDeviceFeature$Params, context?: HttpContext): Observable<number> {
    return this.deleteDeviceFeature$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }
}
