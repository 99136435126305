/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addAttributeGroups } from '../fn/attribute-groups/add-attribute-groups';
import { AddAttributeGroups$Params } from '../fn/attribute-groups/add-attribute-groups';
import { AttributeGroupsAg } from '../models/attribute-groups-ag';
import { AttributeGroupsList } from '../models/attribute-groups-list';
import { deleteAttributeGroups } from '../fn/attribute-groups/delete-attribute-groups';
import { DeleteAttributeGroups$Params } from '../fn/attribute-groups/delete-attribute-groups';
import { getAttributeGroups } from '../fn/attribute-groups/get-attribute-groups';
import { GetAttributeGroups$Params } from '../fn/attribute-groups/get-attribute-groups';
import { getAttributeGroupsById } from '../fn/attribute-groups/get-attribute-groups-by-id';
import { GetAttributeGroupsById$Params } from '../fn/attribute-groups/get-attribute-groups-by-id';
import { getAttributeGroupsWithOrgName } from '../fn/attribute-groups/get-attribute-groups-with-org-name';
import { GetAttributeGroupsWithOrgName$Params } from '../fn/attribute-groups/get-attribute-groups-with-org-name';
import { updateAttributeGroups } from '../fn/attribute-groups/update-attribute-groups';
import { UpdateAttributeGroups$Params } from '../fn/attribute-groups/update-attribute-groups';

@Injectable({ providedIn: 'root' })
export class AttributeGroupsService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAttributeGroups()` */
  static readonly GetAttributeGroupsPath = '/api/AttributeGroups/getAttributeGroups/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttributeGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributeGroups$Response(
    params: GetAttributeGroups$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AttributeGroupsAg>>> {
    return getAttributeGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttributeGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributeGroups(params: GetAttributeGroups$Params, context?: HttpContext): Observable<Array<AttributeGroupsAg>> {
    return this.getAttributeGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttributeGroupsAg>>): Array<AttributeGroupsAg> => r.body)
    );
  }

  /** Path part for operation `getAttributeGroupsWithOrgName()` */
  static readonly GetAttributeGroupsWithOrgNamePath = '/api/AttributeGroups/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttributeGroupsWithOrgName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributeGroupsWithOrgName$Response(
    params: GetAttributeGroupsWithOrgName$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AttributeGroupsList>>> {
    return getAttributeGroupsWithOrgName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttributeGroupsWithOrgName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributeGroupsWithOrgName(
    params: GetAttributeGroupsWithOrgName$Params,
    context?: HttpContext
  ): Observable<Array<AttributeGroupsList>> {
    return this.getAttributeGroupsWithOrgName$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttributeGroupsList>>): Array<AttributeGroupsList> => r.body)
    );
  }

  /** Path part for operation `getAttributeGroupsById()` */
  static readonly GetAttributeGroupsByIdPath = '/api/AttributeGroups/attributeGroupsById/{orid}/{agid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttributeGroupsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributeGroupsById$Response(
    params: GetAttributeGroupsById$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<AttributeGroupsAg>> {
    return getAttributeGroupsById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttributeGroupsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributeGroupsById(params: GetAttributeGroupsById$Params, context?: HttpContext): Observable<AttributeGroupsAg> {
    return this.getAttributeGroupsById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttributeGroupsAg>): AttributeGroupsAg => r.body)
    );
  }

  /** Path part for operation `updateAttributeGroups()` */
  static readonly UpdateAttributeGroupsPath = '/api/AttributeGroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAttributeGroups()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAttributeGroups$Response(params: UpdateAttributeGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return updateAttributeGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAttributeGroups$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAttributeGroups(params: UpdateAttributeGroups$Params, context?: HttpContext): Observable<number> {
    return this.updateAttributeGroups$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `addAttributeGroups()` */
  static readonly AddAttributeGroupsPath = '/api/AttributeGroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAttributeGroups()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAttributeGroups$Response(params: AddAttributeGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return addAttributeGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAttributeGroups$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAttributeGroups(params: AddAttributeGroups$Params, context?: HttpContext): Observable<number> {
    return this.addAttributeGroups$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `deleteAttributeGroups()` */
  static readonly DeleteAttributeGroupsPath = '/api/AttributeGroups/{orid}/{agid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAttributeGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttributeGroups$Response(params: DeleteAttributeGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return deleteAttributeGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAttributeGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttributeGroups(params: DeleteAttributeGroups$Params, context?: HttpContext): Observable<number> {
    return this.deleteAttributeGroups$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }
}
