<ul class="main-menu">
  <lib-menu-item
    *ngFor="let menuItem of menuItems | async"
    (click)="menuItem.onClick()"
    [tooltip]="menuItem.tooltip | translate"
    [icon]="menuItem.icon"
    [isActive]="isMenuItemActive(menuItem)"
  >
    <lib-attention-badge class="attention-badge" *ngIf="menuItem.showBadge" [counter]="menuItem.counter | async"></lib-attention-badge>
  </lib-menu-item>
</ul>
