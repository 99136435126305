/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PileTurnerScanEntry } from '../../models/pile-turner-scan-entry';
import { PileTurnerScanResult } from '../../models/pile-turner-scan-result';

export interface ScanPrimaryContainer$Params {
  pileTurnerWorkCenterId: number;
  body: PileTurnerScanEntry;
}

export function scanPrimaryContainer(
  http: HttpClient,
  rootUrl: string,
  params: ScanPrimaryContainer$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<PileTurnerScanResult>> {
  const rb = new RequestBuilder(rootUrl, scanPrimaryContainer.PATH, 'post');
  if (params) {
    rb.path('pileTurnerWorkCenterId', params.pileTurnerWorkCenterId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PileTurnerScanResult>;
    })
  );
}

scanPrimaryContainer.PATH = '/api/v1/PileTurner/{pileTurnerWorkCenterId}/Scan';
