import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { nav } from '@bd/shared/utils';
import { MenuItem } from 'chronos-shared';
import { of } from 'rxjs';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html'
})
export class MainMenuComponent {
  private menuItems: MenuItem[] = [
    {
      link: nav.routes.workcenter,
      tooltip: 'MAIN_MENU.EDIT_WORK_CENTER',
      icon: 'icon-machine',
      onClick: () => this.router.navigate([nav.routes.workcenter])
    },
    {
      link: nav.routes.downtimeReason,
      tooltip: 'MAIN_MENU.MANAGE_DOWNTME_REASON',
      icon: 'icon-open-tasks',
      onClick: () => this.router.navigate([nav.routes.downtimeReason])
    }
  ];

  public isLoading = false;
  public menuList$ = of(this.menuItems);

  constructor(private router: Router) {}
}
