/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AclroleAr } from '../models/aclrole-ar';
import { AclRoleList } from '../models/acl-role-list';
import { addRole } from '../fn/role/add-role';
import { AddRole$Params } from '../fn/role/add-role';
import { getRoleNamesByOrg } from '../fn/role/get-role-names-by-org';
import { GetRoleNamesByOrg$Params } from '../fn/role/get-role-names-by-org';
import { removeRole } from '../fn/role/remove-role';
import { RemoveRole$Params } from '../fn/role/remove-role';
import { updateRole } from '../fn/role/update-role';
import { UpdateRole$Params } from '../fn/role/update-role';

@Injectable({ providedIn: 'root' })
export class RoleService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRoleNamesByOrg()` */
  static readonly GetRoleNamesByOrgPath = '/api/Role/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoleNamesByOrg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleNamesByOrg$Response(params: GetRoleNamesByOrg$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AclRoleList>>> {
    return getRoleNamesByOrg(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoleNamesByOrg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleNamesByOrg(params: GetRoleNamesByOrg$Params, context?: HttpContext): Observable<Array<AclRoleList>> {
    return this.getRoleNamesByOrg$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AclRoleList>>): Array<AclRoleList> => r.body)
    );
  }

  /** Path part for operation `updateRole()` */
  static readonly UpdateRolePath = '/api/Role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole$Response(params: UpdateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<AclroleAr>> {
    return updateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole(params: UpdateRole$Params, context?: HttpContext): Observable<AclroleAr> {
    return this.updateRole$Response(params, context).pipe(map((r: StrictHttpResponse<AclroleAr>): AclroleAr => r.body));
  }

  /** Path part for operation `addRole()` */
  static readonly AddRolePath = '/api/Role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRole$Response(params: AddRole$Params, context?: HttpContext): Observable<StrictHttpResponse<AclroleAr>> {
    return addRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRole(params: AddRole$Params, context?: HttpContext): Observable<AclroleAr> {
    return this.addRole$Response(params, context).pipe(map((r: StrictHttpResponse<AclroleAr>): AclroleAr => r.body));
  }

  /** Path part for operation `removeRole()` */
  static readonly RemoveRolePath = '/removerole/{arid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRole$Response(params: RemoveRole$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return removeRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRole(params: RemoveRole$Params, context?: HttpContext): Observable<number> {
    return this.removeRole$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }
}
