/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ValidUserParam } from '../../models/valid-user-param';

export interface IsValidUserName$Params {
  orid: number;
  body: ValidUserParam;
}

export function isValidUserName(
  http: HttpClient,
  rootUrl: string,
  params: IsValidUserName$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<boolean>> {
  const rb = new RequestBuilder(rootUrl, isValidUserName.PATH, 'post');
  if (params) {
    rb.path('orid', params.orid, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
    })
  );
}

isValidUserName.PATH = '/validusername/{orid}';
