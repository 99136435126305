/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { BaseDataApiConfiguration } from '../base-data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getOperationTypes } from '../fn/work-center/get-operation-types';
import { GetOperationTypes$Params } from '../fn/work-center/get-operation-types';
import { getShiftCalendars } from '../fn/work-center/get-shift-calendars';
import { GetShiftCalendars$Params } from '../fn/work-center/get-shift-calendars';
import { getWorkCenterByExternalId } from '../fn/work-center/get-work-center-by-external-id';
import { GetWorkCenterByExternalId$Params } from '../fn/work-center/get-work-center-by-external-id';
import { getWorkCenterById } from '../fn/work-center/get-work-center-by-id';
import { GetWorkCenterById$Params } from '../fn/work-center/get-work-center-by-id';
import { getWorkCenterByNameOrExternalId } from '../fn/work-center/get-work-center-by-name-or-external-id';
import { GetWorkCenterByNameOrExternalId$Params } from '../fn/work-center/get-work-center-by-name-or-external-id';
import { getWorkCenterGroups } from '../fn/work-center/get-work-center-groups';
import { GetWorkCenterGroups$Params } from '../fn/work-center/get-work-center-groups';
import { getWorkCenters } from '../fn/work-center/get-work-centers';
import { GetWorkCenters$Params } from '../fn/work-center/get-work-centers';
import { OperationTypeViewModel } from '../models/operation-type-view-model';
import { ShiftCalendarViewModel } from '../models/shift-calendar-view-model';
import { updateWorkCenters } from '../fn/work-center/update-work-centers';
import { UpdateWorkCenters$Params } from '../fn/work-center/update-work-centers';
import { WorkCenterGroupViewModel } from '../models/work-center-group-view-model';
import { WorkCenterViewModel } from '../models/work-center-view-model';

@Injectable({ providedIn: 'root' })
export class WorkCenterService extends BaseService {
  constructor(config: BaseDataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateWorkCenters()` */
  static readonly UpdateWorkCentersPath = '/api/v1/WorkCenter/UpdateWorkCentersList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkCenters()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkCenters$Response(params: UpdateWorkCenters$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateWorkCenters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWorkCenters$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkCenters(params: UpdateWorkCenters$Params, context?: HttpContext): Observable<void> {
    return this.updateWorkCenters$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getWorkCenterById()` */
  static readonly GetWorkCenterByIdPath = '/api/v1/WorkCenter/GetWorkCenterById/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterById$Response(params: GetWorkCenterById$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkCenterViewModel>> {
    return getWorkCenterById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterById(params: GetWorkCenterById$Params, context?: HttpContext): Observable<WorkCenterViewModel> {
    return this.getWorkCenterById$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkCenterViewModel>): WorkCenterViewModel => r.body)
    );
  }

  /** Path part for operation `getWorkCenterByExternalId()` */
  static readonly GetWorkCenterByExternalIdPath = '/api/v1/WorkCenter/GetWorkCenterByExternalId/{workCenterExternalId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterByExternalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterByExternalId$Response(params: GetWorkCenterByExternalId$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkCenterViewModel>> {
    return getWorkCenterByExternalId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterByExternalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterByExternalId(params: GetWorkCenterByExternalId$Params, context?: HttpContext): Observable<WorkCenterViewModel> {
    return this.getWorkCenterByExternalId$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkCenterViewModel>): WorkCenterViewModel => r.body)
    );
  }

  /** Path part for operation `getWorkCenterByNameOrExternalId()` */
  static readonly GetWorkCenterByNameOrExternalIdPath = '/api/v1/WorkCenter/GetWorkCenterByNameOrExternalId/{searchQuery}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterByNameOrExternalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterByNameOrExternalId$Response(params: GetWorkCenterByNameOrExternalId$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkCenterViewModel>> {
    return getWorkCenterByNameOrExternalId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterByNameOrExternalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterByNameOrExternalId(params: GetWorkCenterByNameOrExternalId$Params, context?: HttpContext): Observable<WorkCenterViewModel> {
    return this.getWorkCenterByNameOrExternalId$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkCenterViewModel>): WorkCenterViewModel => r.body)
    );
  }

  /** Path part for operation `getWorkCenters()` */
  static readonly GetWorkCentersPath = '/api/v1/WorkCenter/GetWorkCenters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenters$Response(params?: GetWorkCenters$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkCenterViewModel>>> {
    return getWorkCenters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenters(params?: GetWorkCenters$Params, context?: HttpContext): Observable<Array<WorkCenterViewModel>> {
    return this.getWorkCenters$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkCenterViewModel>>): Array<WorkCenterViewModel> => r.body)
    );
  }

  /** Path part for operation `getWorkCenterGroups()` */
  static readonly GetWorkCenterGroupsPath = '/api/v1/WorkCenter/GetWorkCenterGroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterGroups$Response(params?: GetWorkCenterGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkCenterGroupViewModel>>> {
    return getWorkCenterGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterGroups(params?: GetWorkCenterGroups$Params, context?: HttpContext): Observable<Array<WorkCenterGroupViewModel>> {
    return this.getWorkCenterGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkCenterGroupViewModel>>): Array<WorkCenterGroupViewModel> => r.body)
    );
  }

  /** Path part for operation `getShiftCalendars()` */
  static readonly GetShiftCalendarsPath = '/api/v1/WorkCenter/GetShiftCalendars';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShiftCalendars()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftCalendars$Response(params?: GetShiftCalendars$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShiftCalendarViewModel>>> {
    return getShiftCalendars(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShiftCalendars$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftCalendars(params?: GetShiftCalendars$Params, context?: HttpContext): Observable<Array<ShiftCalendarViewModel>> {
    return this.getShiftCalendars$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShiftCalendarViewModel>>): Array<ShiftCalendarViewModel> => r.body)
    );
  }

  /** Path part for operation `getOperationTypes()` */
  static readonly GetOperationTypesPath = '/api/v1/WorkCenter/GetOperationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOperationTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperationTypes$Response(params?: GetOperationTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OperationTypeViewModel>>> {
    return getOperationTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOperationTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperationTypes(params?: GetOperationTypes$Params, context?: HttpContext): Observable<Array<OperationTypeViewModel>> {
    return this.getOperationTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OperationTypeViewModel>>): Array<OperationTypeViewModel> => r.body)
    );
  }

}
