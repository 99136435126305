import { Component, OnInit } from '@angular/core';
import { AuthorizeService, AuthenticationResultStatus } from '../authorize.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { loginActions, queryParameterNames, applicationPaths, returnUrlType } from '../api-authorization.constants';
import { isSameSiteUrl } from '../api-authorization.utils';
import { SplashScreenService } from '../../services';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public message = new BehaviorSubject<string>('');
  public hasMessage = false;

  constructor(
    private authorizeService: AuthorizeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private splashScreenService: SplashScreenService
  ) {}

  public async ngOnInit() {
    const action = this.activatedRoute.snapshot.url[1];
    switch (action.path) {
      case loginActions.Login:
        await this.login(this.getReturnUrl());
        break;
      case loginActions.LoginCallback:
        await this.processLoginCallback();
        break;
      case loginActions.LoginFailed:
        const message: string = this.activatedRoute.snapshot.queryParamMap.get(queryParameterNames.Message);
        this.message.next(message);
        this.onError();
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }

  private async login(returnUrl: string): Promise<void> {
    const state: INavigationState = { returnUrl };
    const result = await this.authorizeService.signIn(state);
    // TODO:  Why is this needed ?
    this.message.next(undefined);
    this.hasMessage = false;
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        this.message.next(result.message);
        this.onError();
        break;
      default:
        throw new Error(`Invalid status result ${(result as any).status}.`);
    }
  }

  private async processLoginCallback(): Promise<void> {
    const url = window.location.href;
    const result = await this.authorizeService.completeSignIn(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as completeSignIn never redirects.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        this.message.next(result.message);
        this.onError();
        break;
    }
  }

  private async navigateToReturnUrl(returnUrl: string) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const returnUrlFromQuery: string = this.activatedRoute.snapshot.queryParams[returnUrlType];
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (returnUrlFromQuery && !isSameSiteUrl(returnUrlFromQuery)) {
      // This is an extra check to prevent open redirects.
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return state?.returnUrl || returnUrlFromQuery || applicationPaths.DefaultLoginRedirectPath;
  }

  private onError() {
    this.hasMessage = true;
    this.splashScreenService.hideSplashScreen();
  }
}

interface INavigationState {
  [returnUrlType]: string;
}
