/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAffectedTimes } from '../fn/tasks/get-affected-times';
import { GetAffectedTimes$Params } from '../fn/tasks/get-affected-times';
import { getByWorkCenterId } from '../fn/tasks/get-by-work-center-id';
import { GetByWorkCenterId$Params } from '../fn/tasks/get-by-work-center-id';
import { getByWorkCenterTaskId } from '../fn/tasks/get-by-work-center-task-id';
import { GetByWorkCenterTaskId$Params } from '../fn/tasks/get-by-work-center-task-id';
import { getOpenWorkCenterTasksByCategory } from '../fn/tasks/get-open-work-center-tasks-by-category';
import { GetOpenWorkCenterTasksByCategory$Params } from '../fn/tasks/get-open-work-center-tasks-by-category';
import { WorkCenterTask } from '../models/work-center-task';
import { WorkCenterTaskAffectedTimes } from '../models/work-center-task-affected-times';
import { WorkCenterTasksByCategory } from '../models/work-center-tasks-by-category';

@Injectable({ providedIn: 'root' })
export class TasksService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getByWorkCenterId()` */
  static readonly GetByWorkCenterIdPath = '/api/v1/Tasks/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByWorkCenterId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByWorkCenterId$Response(
    params: GetByWorkCenterId$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkCenterTask>>> {
    return getByWorkCenterId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByWorkCenterId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByWorkCenterId(params: GetByWorkCenterId$Params, context?: HttpContext): Observable<Array<WorkCenterTask>> {
    return this.getByWorkCenterId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkCenterTask>>): Array<WorkCenterTask> => r.body)
    );
  }

  /** Path part for operation `getByWorkCenterTaskId()` */
  static readonly GetByWorkCenterTaskIdPath = '/api/v1/Tasks/{workCenterTaskId}/ByWorkCenterTaskId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByWorkCenterTaskId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByWorkCenterTaskId$Response(
    params: GetByWorkCenterTaskId$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorkCenterTask>> {
    return getByWorkCenterTaskId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByWorkCenterTaskId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByWorkCenterTaskId(params: GetByWorkCenterTaskId$Params, context?: HttpContext): Observable<WorkCenterTask> {
    return this.getByWorkCenterTaskId$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkCenterTask>): WorkCenterTask => r.body)
    );
  }

  /** Path part for operation `getOpenWorkCenterTasksByCategory()` */
  static readonly GetOpenWorkCenterTasksByCategoryPath = '/api/v1/Tasks/{workCenterId}/ByCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenWorkCenterTasksByCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenWorkCenterTasksByCategory$Response(
    params: GetOpenWorkCenterTasksByCategory$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkCenterTasksByCategory>>> {
    return getOpenWorkCenterTasksByCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenWorkCenterTasksByCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenWorkCenterTasksByCategory(
    params: GetOpenWorkCenterTasksByCategory$Params,
    context?: HttpContext
  ): Observable<Array<WorkCenterTasksByCategory>> {
    return this.getOpenWorkCenterTasksByCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkCenterTasksByCategory>>): Array<WorkCenterTasksByCategory> => r.body)
    );
  }

  /** Path part for operation `getAffectedTimes()` */
  static readonly GetAffectedTimesPath = '/api/v1/Tasks/{workCenterTaskId}/AffectedTimes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAffectedTimes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAffectedTimes$Response(
    params: GetAffectedTimes$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorkCenterTaskAffectedTimes>> {
    return getAffectedTimes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAffectedTimes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAffectedTimes(params: GetAffectedTimes$Params, context?: HttpContext): Observable<WorkCenterTaskAffectedTimes> {
    return this.getAffectedTimes$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkCenterTaskAffectedTimes>): WorkCenterTaskAffectedTimes => r.body)
    );
  }
}
