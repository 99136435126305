/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AuthFunctionOperation } from '../../models/auth-function-operation';

export interface GetAllAuthFunctionOperation$Params {}

export function getAllAuthFunctionOperation(
  http: HttpClient,
  rootUrl: string,
  params?: GetAllAuthFunctionOperation$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<AuthFunctionOperation>>> {
  const rb = new RequestBuilder(rootUrl, getAllAuthFunctionOperation.PATH, 'post');
  if (params) {
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<AuthFunctionOperation>>;
    })
  );
}

getAllAuthFunctionOperation.PATH = '/api/AclRole/role/list/authOperation';
