/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AttributeGroupsAg } from '../../models/attribute-groups-ag';

export interface UpdateAttributeGroups$Params {
  body: AttributeGroupsAg;
}

export function updateAttributeGroups(
  http: HttpClient,
  rootUrl: string,
  params: UpdateAttributeGroups$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<number>> {
  const rb = new RequestBuilder(rootUrl, updateAttributeGroups.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
    })
  );
}

updateAttributeGroups.PATH = '/api/AttributeGroups';
