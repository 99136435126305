import { Component, OnInit } from '@angular/core';
import { AuthenticationResultStatus, AuthorizeService } from '../authorize.service';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { logoutActions, applicationPaths, returnUrlType } from '../api-authorization.constants';
import { isSameSiteUrl } from '../api-authorization.utils';
import { SplashScreenService } from '../../services/splash-screen/splash-screen.service';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
@Component({
  selector: 'lib-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  public message = new BehaviorSubject<string>('');

  constructor(
    private authorizeService: AuthorizeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private splashScreenService: SplashScreenService
  ) {}

  public async ngOnInit() {
    const action = this.activatedRoute.snapshot.url[1];
    console.info('Logout component initialized with action: ', action.path, action);
    switch (action.path) {
      case logoutActions.Logout:
        if (!!window.history.state.local) {
          await this.logout(this.getReturnUrl());
        } else {
          // This prevents regular links to <app>/authentication/logout from triggering a logout
          this.message.next('ACCOUNT.LOGOUT_WARN_EXTERNAL_LOGOUT');
        }

        break;
      case logoutActions.LogoutCallback:
        await this.processLogoutCallback();
        break;
      case logoutActions.LoggedOut:
        this.message.next('ACCOUNT.LOGOUT_SUCCESS');
        this.removeSplashScreen();
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }

  private async logout(returnUrl: string): Promise<void> {
    console.info('Processing logout');
    const state: INavigationState = { returnUrl };
    const isauthenticated = await this.authorizeService.isAuthenticated().pipe(take(1)).toPromise();
    if (isauthenticated) {
      const result = await this.authorizeService.signOut(state);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          await this.navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          this.message.next(result.message);
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    } else {
      this.message.next('ACCOUNT.LOGOUT_SUCCESS');
      this.removeSplashScreen();
    }
  }

  private async processLogoutCallback(): Promise<void> {
    console.info('Processing logout callback');
    const url = window.location.href;
    const result = await this.authorizeService.completeSignOut(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // Sign out callbacks shall not redirect
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        console.info('Logout success', result);
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        console.info('Logout failed', result);
        this.message.next(result.message);
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  }

  private async navigateToReturnUrl(returnUrl: string) {
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const returnUrlFromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (returnUrlFromQuery && !isSameSiteUrl(returnUrlFromQuery)) {
      // This is an extra check to prevent open redirects.
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return state?.returnUrl || returnUrlFromQuery || applicationPaths.LoggedOut;
  }

  private removeSplashScreen() {
    this.splashScreenService.hideSplashScreen();
  }
}

interface INavigationState {
  [returnUrlType]: string;
}
