import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WarehouseLocationViewModel, WarehouseViewModel, WarehouseService } from 'chronos-basedata-client';

@Injectable({
  providedIn: 'root'
})
export class WarehouseDsService {
  constructor(private warehouseService: WarehouseService) {}

  public getWarehouseLocations(siteId: number): Observable<WarehouseLocationViewModel[]> {
    return this.warehouseService.getWarehouseLocations({
      siteId
    });
  }

  public getWarehouses(siteId: number): Observable<WarehouseViewModel[]> {
    return this.warehouseService.getWarehouses({
      siteId
    });
  }

  public getWarehouseLocationsByWarehouse(warehouseId: number): Observable<WarehouseLocationViewModel[]> {
    return this.warehouseService.getWarehouseLocationsByWarehouse({
      warehouseId
    });
  }
}
