/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addDataSource } from '../fn/master-data-source/add-data-source';
import { AddDataSource$Params } from '../fn/master-data-source/add-data-source';
import { addType } from '../fn/master-data-source/add-type';
import { AddType$Params } from '../fn/master-data-source/add-type';
import { CategoryList } from '../models/category-list';
import { deleteDataSource } from '../fn/master-data-source/delete-data-source';
import { DeleteDataSource$Params } from '../fn/master-data-source/delete-data-source';
import { deleteType } from '../fn/master-data-source/delete-type';
import { DeleteType$Params } from '../fn/master-data-source/delete-type';
import { getAllPageTextIds } from '../fn/master-data-source/get-all-page-text-ids';
import { GetAllPageTextIds$Params } from '../fn/master-data-source/get-all-page-text-ids';
import { getAllTypes } from '../fn/master-data-source/get-all-types';
import { GetAllTypes$Params } from '../fn/master-data-source/get-all-types';
import { getDataSources } from '../fn/master-data-source/get-data-sources';
import { GetDataSources$Params } from '../fn/master-data-source/get-data-sources';
import { getDataSourcesById } from '../fn/master-data-source/get-data-sources-by-id';
import { GetDataSourcesById$Params } from '../fn/master-data-source/get-data-sources-by-id';
import { getDataSourcesOrgName } from '../fn/master-data-source/get-data-sources-org-name';
import { GetDataSourcesOrgName$Params } from '../fn/master-data-source/get-data-sources-org-name';
import { getDataTypeById } from '../fn/master-data-source/get-data-type-by-id';
import { GetDataTypeById$Params } from '../fn/master-data-source/get-data-type-by-id';
import { getTypes } from '../fn/master-data-source/get-types';
import { GetTypes$Params } from '../fn/master-data-source/get-types';
import { setDefaultDataType } from '../fn/master-data-source/set-default-data-type';
import { SetDefaultDataType$Params } from '../fn/master-data-source/set-default-data-type';
import { SystabcatSc } from '../models/systabcat-sc';
import { SystabSb } from '../models/systab-sb';
import { updateDataSource } from '../fn/master-data-source/update-data-source';
import { UpdateDataSource$Params } from '../fn/master-data-source/update-data-source';
import { updateType } from '../fn/master-data-source/update-type';
import { UpdateType$Params } from '../fn/master-data-source/update-type';

@Injectable({ providedIn: 'root' })
export class MasterDataSourceService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDataSources()` */
  static readonly GetDataSourcesPath = '/api/MasterDataSource/dataSourceDetails/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataSources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataSources$Response(params: GetDataSources$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SystabcatSc>>> {
    return getDataSources(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDataSources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataSources(params: GetDataSources$Params, context?: HttpContext): Observable<Array<SystabcatSc>> {
    return this.getDataSources$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SystabcatSc>>): Array<SystabcatSc> => r.body)
    );
  }

  /** Path part for operation `getDataSourcesOrgName()` */
  static readonly GetDataSourcesOrgNamePath = '/api/MasterDataSource/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataSourcesOrgName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataSourcesOrgName$Response(
    params: GetDataSourcesOrgName$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CategoryList>>> {
    return getDataSourcesOrgName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDataSourcesOrgName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataSourcesOrgName(params: GetDataSourcesOrgName$Params, context?: HttpContext): Observable<Array<CategoryList>> {
    return this.getDataSourcesOrgName$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryList>>): Array<CategoryList> => r.body)
    );
  }

  /** Path part for operation `getDataSourcesById()` */
  static readonly GetDataSourcesByIdPath = '/api/MasterDataSource/dataSourcesById/{orid}/{scid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataSourcesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataSourcesById$Response(params: GetDataSourcesById$Params, context?: HttpContext): Observable<StrictHttpResponse<SystabcatSc>> {
    return getDataSourcesById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDataSourcesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataSourcesById(params: GetDataSourcesById$Params, context?: HttpContext): Observable<SystabcatSc> {
    return this.getDataSourcesById$Response(params, context).pipe(map((r: StrictHttpResponse<SystabcatSc>): SystabcatSc => r.body));
  }

  /** Path part for operation `updateDataSource()` */
  static readonly UpdateDataSourcePath = '/api/MasterDataSource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDataSource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDataSource$Response(params: UpdateDataSource$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return updateDataSource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDataSource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDataSource(params: UpdateDataSource$Params, context?: HttpContext): Observable<number> {
    return this.updateDataSource$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `addDataSource()` */
  static readonly AddDataSourcePath = '/api/MasterDataSource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDataSource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addDataSource$Response(params: AddDataSource$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return addDataSource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDataSource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addDataSource(params: AddDataSource$Params, context?: HttpContext): Observable<number> {
    return this.addDataSource$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `getTypes()` */
  static readonly GetTypesPath = '/api/MasterDataSource/{orid}/{scid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypes$Response(params: GetTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SystabSb>>> {
    return getTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypes(params: GetTypes$Params, context?: HttpContext): Observable<Array<SystabSb>> {
    return this.getTypes$Response(params, context).pipe(map((r: StrictHttpResponse<Array<SystabSb>>): Array<SystabSb> => r.body));
  }

  /** Path part for operation `deleteDataSource()` */
  static readonly DeleteDataSourcePath = '/api/MasterDataSource/{orid}/{scid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDataSource()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDataSource$Response(params: DeleteDataSource$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return deleteDataSource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDataSource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDataSource(params: DeleteDataSource$Params, context?: HttpContext): Observable<number> {
    return this.deleteDataSource$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `getAllTypes()` */
  static readonly GetAllTypesPath = '/api/MasterDataSource/all/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTypes$Response(params: GetAllTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SystabSb>>> {
    return getAllTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTypes(params: GetAllTypes$Params, context?: HttpContext): Observable<Array<SystabSb>> {
    return this.getAllTypes$Response(params, context).pipe(map((r: StrictHttpResponse<Array<SystabSb>>): Array<SystabSb> => r.body));
  }

  /** Path part for operation `setDefaultDataType()` */
  static readonly SetDefaultDataTypePath = '/api/MasterDataSource/setDefault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDefaultDataType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDefaultDataType$Response(params: SetDefaultDataType$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return setDefaultDataType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setDefaultDataType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDefaultDataType(params: SetDefaultDataType$Params, context?: HttpContext): Observable<number> {
    return this.setDefaultDataType$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `updateType()` */
  static readonly UpdateTypePath = '/api/MasterDataSource/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateType$Response(params: UpdateType$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return updateType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateType(params: UpdateType$Params, context?: HttpContext): Observable<number> {
    return this.updateType$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `addType()` */
  static readonly AddTypePath = '/api/MasterDataSource/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addType$Response(params: AddType$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return addType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addType(params: AddType$Params, context?: HttpContext): Observable<number> {
    return this.addType$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `deleteType()` */
  static readonly DeleteTypePath = '/api/MasterDataSource/deleteType/{orid}/{sbid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteType$Response(params: DeleteType$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return deleteType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteType(params: DeleteType$Params, context?: HttpContext): Observable<number> {
    return this.deleteType$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `getDataTypeById()` */
  static readonly GetDataTypeByIdPath = '/api/MasterDataSource/dataTypesById/{orid}/{sbid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataTypeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataTypeById$Response(params: GetDataTypeById$Params, context?: HttpContext): Observable<StrictHttpResponse<SystabSb>> {
    return getDataTypeById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDataTypeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataTypeById(params: GetDataTypeById$Params, context?: HttpContext): Observable<SystabSb> {
    return this.getDataTypeById$Response(params, context).pipe(map((r: StrictHttpResponse<SystabSb>): SystabSb => r.body));
  }

  /** Path part for operation `getAllPageTextIds()` */
  static readonly GetAllPageTextIdsPath = '/api/MasterDataSource/allPageText/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPageTextIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPageTextIds$Response(params: GetAllPageTextIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getAllPageTextIds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPageTextIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPageTextIds(params: GetAllPageTextIds$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getAllPageTextIds$Response(params, context).pipe(map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body));
  }
}
