/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getWasteAssignment } from '../fn/waste-assignment/get-waste-assignment';
import { GetWasteAssignment$Params } from '../fn/waste-assignment/get-waste-assignment';
import { WasteAssignment } from '../models/waste-assignment';

@Injectable({ providedIn: 'root' })
export class WasteAssignmentService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWasteAssignment()` */
  static readonly GetWasteAssignmentPath = '/api/v1/WasteAssignment/{productionOrderId}/{wasteAssignmentType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWasteAssignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWasteAssignment$Response(
    params: GetWasteAssignment$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WasteAssignment>>> {
    return getWasteAssignment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWasteAssignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWasteAssignment(params: GetWasteAssignment$Params, context?: HttpContext): Observable<Array<WasteAssignment>> {
    return this.getWasteAssignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WasteAssignment>>): Array<WasteAssignment> => r.body)
    );
  }
}
