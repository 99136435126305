import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Filters } from './workcenter.model';
import { WorKCenterState, WorkCenterStore } from './workcenter.store';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WorkCenterViewModel } from 'chronos-basedata-client';

@Injectable({ providedIn: 'root' })
export class WorkCenterQuery extends QueryEntity<WorKCenterState, WorkCenterViewModel> {
  public isLoading$ = this.selectLoading();
  public filters$: Observable<Filters> = this.select((state) => state.filters);

  public allWorkCenters$ = this.filters$.pipe(
    switchMap((filters) =>
      this.selectAll({
        filterBy: (workCenter: WorkCenterViewModel) => this.filterByExternalWorkCenter(filters, workCenter)
      })
    )
  );

  constructor(protected store: WorkCenterStore) {
    super(store);
  }

  private filterByWorkCenterStatus(filters: Filters, workCenter: WorkCenterViewModel): boolean {
    if (filters.isActiveInActive === null) return true;
    else return filters.isActiveInActive === workCenter.isActive;
  }

  private filterBySite(filters: Filters, workCenter: WorkCenterViewModel): boolean {
    if (!filters.isSite) {
      return true;
    }
    if (filters.isSite) {
      workCenter.site.id === workCenter.site.id; // TODO: get proper site id
    }
  }

  private filterByExternalWorkCenter(filters: Filters, workCenter: WorkCenterViewModel) {
    if (!filters.workCenterExternalId) {
      return true;
    } else if (workCenter.externalWorkCenterId) {
      return workCenter.externalWorkCenterId.toLowerCase().includes(filters.workCenterExternalId.toLowerCase());
    }

    return false;
  }
}
