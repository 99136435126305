/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addProductionOrderSetupWaste } from '../fn/production-order/add-production-order-setup-waste';
import { AddProductionOrderSetupWaste$Params } from '../fn/production-order/add-production-order-setup-waste';
import { addProductionOrderWasteAssignmentToOuter } from '../fn/production-order/add-production-order-waste-assignment-to-outer';
import { AddProductionOrderWasteAssignmentToOuter$Params } from '../fn/production-order/add-production-order-waste-assignment-to-outer';
import { BillOfMaterial } from '../models/bill-of-material';
import { cancelEndProduction } from '../fn/production-order/cancel-end-production';
import { CancelEndProduction$Params } from '../fn/production-order/cancel-end-production';
import { cancelProductionOrder } from '../fn/production-order/cancel-production-order';
import { CancelProductionOrder$Params } from '../fn/production-order/cancel-production-order';
import { cancelProductionOrderPresetup } from '../fn/production-order/cancel-production-order-presetup';
import { CancelProductionOrderPresetup$Params } from '../fn/production-order/cancel-production-order-presetup';
import { changeContainerTargetBobbinQuantity } from '../fn/production-order/change-container-target-bobbin-quantity';
import { ChangeContainerTargetBobbinQuantity$Params } from '../fn/production-order/change-container-target-bobbin-quantity';
import { changeContainerTargetQuantity } from '../fn/production-order/change-container-target-quantity';
import { ChangeContainerTargetQuantity$Params } from '../fn/production-order/change-container-target-quantity';
import { changeOuterQuantity } from '../fn/production-order/change-outer-quantity';
import { ChangeOuterQuantity$Params } from '../fn/production-order/change-outer-quantity';
import { changeProductionOrderOuterInfo } from '../fn/production-order/change-production-order-outer-info';
import { ChangeProductionOrderOuterInfo$Params } from '../fn/production-order/change-production-order-outer-info';
import { checkProductionOrder } from '../fn/production-order/check-production-order';
import { CheckProductionOrder$Params } from '../fn/production-order/check-production-order';
import { confirmProductionOrderKpi } from '../fn/production-order/confirm-production-order-kpi';
import { ConfirmProductionOrderKpi$Params } from '../fn/production-order/confirm-production-order-kpi';
import { DefaultTargetContainerQuantityInfo } from '../models/default-target-container-quantity-info';
import { deleteProductionOrderSetupWaste } from '../fn/production-order/delete-production-order-setup-waste';
import { DeleteProductionOrderSetupWaste$Params } from '../fn/production-order/delete-production-order-setup-waste';
import { deleteProductionOrderWasteAssignmentFromOuter } from '../fn/production-order/delete-production-order-waste-assignment-from-outer';
import { DeleteProductionOrderWasteAssignmentFromOuter$Params } from '../fn/production-order/delete-production-order-waste-assignment-from-outer';
import { editProductionOrderDeviationReason } from '../fn/production-order/edit-production-order-deviation-reason';
import { EditProductionOrderDeviationReason$Params } from '../fn/production-order/edit-production-order-deviation-reason';
import { editProductionOrderSetupPhase } from '../fn/production-order/edit-production-order-setup-phase';
import { EditProductionOrderSetupPhase$Params } from '../fn/production-order/edit-production-order-setup-phase';
import { endProductionForManualMachine } from '../fn/production-order/end-production-for-manual-machine';
import { EndProductionForManualMachine$Params } from '../fn/production-order/end-production-for-manual-machine';
import { endProductionOrderProduction } from '../fn/production-order/end-production-order-production';
import { EndProductionOrderProduction$Params } from '../fn/production-order/end-production-order-production';
import { finishProductionOrder } from '../fn/production-order/finish-production-order';
import { FinishProductionOrder$Params } from '../fn/production-order/finish-production-order';
import { getAllKpis } from '../fn/production-order/get-all-kpis';
import { GetAllKpis$Params } from '../fn/production-order/get-all-kpis';
import { getBillOfMaterials } from '../fn/production-order/get-bill-of-materials';
import { GetBillOfMaterials$Params } from '../fn/production-order/get-bill-of-materials';
import { getDefaultTargetContainerQuantity } from '../fn/production-order/get-default-target-container-quantity';
import { GetDefaultTargetContainerQuantity$Params } from '../fn/production-order/get-default-target-container-quantity';
import { getFinishedInfos } from '../fn/production-order/get-finished-infos';
import { GetFinishedInfos$Params } from '../fn/production-order/get-finished-infos';
import { getHeaderKpi } from '../fn/production-order/get-header-kpi';
import { GetHeaderKpi$Params } from '../fn/production-order/get-header-kpi';
import { getManualModeFinishingData } from '../fn/production-order/get-manual-mode-finishing-data';
import { GetManualModeFinishingData$Params } from '../fn/production-order/get-manual-mode-finishing-data';
import { getManualTransportProcessInfo } from '../fn/production-order/get-manual-transport-process-info';
import { GetManualTransportProcessInfo$Params } from '../fn/production-order/get-manual-transport-process-info';
import { getOuter } from '../fn/production-order/get-outer';
import { GetOuter$Params } from '../fn/production-order/get-outer';
import { getOuterViewQuantityDetails } from '../fn/production-order/get-outer-view-quantity-details';
import { GetOuterViewQuantityDetails$Params } from '../fn/production-order/get-outer-view-quantity-details';
import { getPrintInfo } from '../fn/production-order/get-print-info';
import { GetPrintInfo$Params } from '../fn/production-order/get-print-info';
import { getProductionLoyaltyReasons } from '../fn/production-order/get-production-loyalty-reasons';
import { GetProductionLoyaltyReasons$Params } from '../fn/production-order/get-production-loyalty-reasons';
import { getProductionOrder } from '../fn/production-order/get-production-order';
import { GetProductionOrder$Params } from '../fn/production-order/get-production-order';
import { getProductionOrderDetails } from '../fn/production-order/get-production-order-details';
import { GetProductionOrderDetails$Params } from '../fn/production-order/get-production-order-details';
import { getProductionOrderInfoByExternalId } from '../fn/production-order/get-production-order-info-by-external-id';
import { GetProductionOrderInfoByExternalId$Params } from '../fn/production-order/get-production-order-info-by-external-id';
import { getProductionOrderInfoByExternalId2 } from '../fn/production-order/get-production-order-info-by-external-id-2';
import { GetProductionOrderInfoByExternalId2$Params } from '../fn/production-order/get-production-order-info-by-external-id-2';
import { getProductionOrderPredecessorInfo } from '../fn/production-order/get-production-order-predecessor-info';
import { GetProductionOrderPredecessorInfo$Params } from '../fn/production-order/get-production-order-predecessor-info';
import { getProjectedRunEnd } from '../fn/production-order/get-projected-run-end';
import { GetProjectedRunEnd$Params } from '../fn/production-order/get-projected-run-end';
import { getRunPhases } from '../fn/production-order/get-run-phases';
import { GetRunPhases$Params } from '../fn/production-order/get-run-phases';
import { getSetupPhaseState } from '../fn/production-order/get-setup-phase-state';
import { GetSetupPhaseState$Params } from '../fn/production-order/get-setup-phase-state';
import { HeaderKpiViewModel } from '../models/header-kpi-view-model';
import { KpiValue } from '../models/kpi-value';
import { ManualTransportProcessInfo } from '../models/manual-transport-process-info';
import { Outer } from '../models/outer';
import { OuterPrintInfo } from '../models/outer-print-info';
import { OuterQuantityDetails } from '../models/outer-quantity-details';
import { presetupProductionOrder } from '../fn/production-order/presetup-production-order';
import { PresetupProductionOrder$Params } from '../fn/production-order/presetup-production-order';
import { printLabelForOuter } from '../fn/production-order/print-label-for-outer';
import { PrintLabelForOuter$Params } from '../fn/production-order/print-label-for-outer';
import { ProductionOrder } from '../models/production-order';
import { ProductionOrderData } from '../models/production-order-data';
import { ProductionOrderExternalInfo } from '../models/production-order-external-info';
import { ProductionOrderFinishedInfos } from '../models/production-order-finished-infos';
import { ProductionOrderManualModeFinishingDataViewModel } from '../models/production-order-manual-mode-finishing-data-view-model';
import { ProductionOrderPredecessorInfo } from '../models/production-order-predecessor-info';
import { ProductionOrderProductionLoyalty } from '../models/production-order-production-loyalty';
import { ProductionOrderSetupPhaseState } from '../models/production-order-setup-phase-state';
import { ProjectedRunEnd } from '../models/projected-run-end';
import { reopenProductionOrder } from '../fn/production-order/reopen-production-order';
import { ReopenProductionOrder$Params } from '../fn/production-order/reopen-production-order';
import { resetProductionOrders } from '../fn/production-order/reset-production-orders';
import { ResetProductionOrders$Params } from '../fn/production-order/reset-production-orders';
import { RunPhase } from '../models/run-phase';
import { saveManualModeFinishingValues } from '../fn/production-order/save-manual-mode-finishing-values';
import { SaveManualModeFinishingValues$Params } from '../fn/production-order/save-manual-mode-finishing-values';
import { searchOrders } from '../fn/production-order/search-orders';
import { SearchOrders$Params } from '../fn/production-order/search-orders';
import { SearchOrdersViewModel } from '../models/search-orders-view-model';
import { setAsNextProductionOrder } from '../fn/production-order/set-as-next-production-order';
import { SetAsNextProductionOrder$Params } from '../fn/production-order/set-as-next-production-order';
import { setCurrentRunSubPhase } from '../fn/production-order/set-current-run-sub-phase';
import { SetCurrentRunSubPhase$Params } from '../fn/production-order/set-current-run-sub-phase';
import { setProductionOrderFinishedInfo } from '../fn/production-order/set-production-order-finished-info';
import { SetProductionOrderFinishedInfo$Params } from '../fn/production-order/set-production-order-finished-info';
import { setSetupEndTime } from '../fn/production-order/set-setup-end-time';
import { SetSetupEndTime$Params } from '../fn/production-order/set-setup-end-time';
import { splitBillOfMaterialData } from '../fn/production-order/split-bill-of-material-data';
import { SplitBillOfMaterialData } from '../models/split-bill-of-material-data';
import { SplitBillOfMaterialData$Params } from '../fn/production-order/split-bill-of-material-data';
import { splitBillOfMaterialRow } from '../fn/production-order/split-bill-of-material-row';
import { SplitBillOfMaterialRow$Params } from '../fn/production-order/split-bill-of-material-row';
import { startProductionOrder } from '../fn/production-order/start-production-order';
import { StartProductionOrder$Params } from '../fn/production-order/start-production-order';
import { startProductionOrderManualMachine } from '../fn/production-order/start-production-order-manual-machine';
import { StartProductionOrderManualMachine$Params } from '../fn/production-order/start-production-order-manual-machine';
import { startProductionOrderWithAutomaticPeriodSelection } from '../fn/production-order/start-production-order-with-automatic-period-selection';
import { StartProductionOrderWithAutomaticPeriodSelection$Params } from '../fn/production-order/start-production-order-with-automatic-period-selection';
import { suggestQuantityForLastProducedMaterial } from '../fn/production-order/suggest-quantity-for-last-produced-material';
import { SuggestQuantityForLastProducedMaterial$Params } from '../fn/production-order/suggest-quantity-for-last-produced-material';
import { SuggestQuantityForLastProducedMaterialViewModel } from '../models/suggest-quantity-for-last-produced-material-view-model';
import { uncheckProductionOrder } from '../fn/production-order/uncheck-production-order';
import { UncheckProductionOrder$Params } from '../fn/production-order/uncheck-production-order';
import { unconfirmProductionOrderKpi } from '../fn/production-order/unconfirm-production-order-kpi';
import { UnconfirmProductionOrderKpi$Params } from '../fn/production-order/unconfirm-production-order-kpi';

@Injectable({ providedIn: 'root' })
export class ProductionOrderService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `checkProductionOrder()` */
  static readonly CheckProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkProductionOrder$Response(params: CheckProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkProductionOrder(params: CheckProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.checkProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `uncheckProductionOrder()` */
  static readonly UncheckProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Uncheck';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uncheckProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uncheckProductionOrder$Response(params: UncheckProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return uncheckProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uncheckProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uncheckProductionOrder(params: UncheckProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.uncheckProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `presetupProductionOrder()` */
  static readonly PresetupProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Presetup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `presetupProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  presetupProductionOrder$Response(params: PresetupProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return presetupProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `presetupProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  presetupProductionOrder(params: PresetupProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.presetupProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `cancelProductionOrderPresetup()` */
  static readonly CancelProductionOrderPresetupPath = '/api/v1/ProductionOrder/{productionOrderId}/CancelPresetup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelProductionOrderPresetup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelProductionOrderPresetup$Response(
    params: CancelProductionOrderPresetup$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return cancelProductionOrderPresetup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelProductionOrderPresetup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelProductionOrderPresetup(params: CancelProductionOrderPresetup$Params, context?: HttpContext): Observable<void> {
    return this.cancelProductionOrderPresetup$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `startProductionOrder()` */
  static readonly StartProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Start';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startProductionOrder$Response(params: StartProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return startProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startProductionOrder(params: StartProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.startProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `startProductionOrderManualMachine()` */
  static readonly StartProductionOrderManualMachinePath = '/api/v1/ProductionOrder/{productionOrderId}/StartProductionOrderManualMachine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startProductionOrderManualMachine()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startProductionOrderManualMachine$Response(
    params: StartProductionOrderManualMachine$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return startProductionOrderManualMachine(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startProductionOrderManualMachine$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startProductionOrderManualMachine(params: StartProductionOrderManualMachine$Params, context?: HttpContext): Observable<void> {
    return this.startProductionOrderManualMachine$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `startProductionOrderWithAutomaticPeriodSelection()` */
  static readonly StartProductionOrderWithAutomaticPeriodSelectionPath =
    '/api/v1/ProductionOrder/{productionOrderId}/StartWithAutomaticPeriodSelection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startProductionOrderWithAutomaticPeriodSelection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startProductionOrderWithAutomaticPeriodSelection$Response(
    params: StartProductionOrderWithAutomaticPeriodSelection$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return startProductionOrderWithAutomaticPeriodSelection(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startProductionOrderWithAutomaticPeriodSelection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startProductionOrderWithAutomaticPeriodSelection(
    params: StartProductionOrderWithAutomaticPeriodSelection$Params,
    context?: HttpContext
  ): Observable<void> {
    return this.startProductionOrderWithAutomaticPeriodSelection$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cancelProductionOrder()` */
  static readonly CancelProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelProductionOrder$Response(params: CancelProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cancelProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelProductionOrder(params: CancelProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.cancelProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `resetProductionOrders()` */
  static readonly ResetProductionOrdersPath = '/api/v1/ProductionOrder/Reset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetProductionOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetProductionOrders$Response(params?: ResetProductionOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetProductionOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetProductionOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetProductionOrders(params?: ResetProductionOrders$Params, context?: HttpContext): Observable<void> {
    return this.resetProductionOrders$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `finishProductionOrder()` */
  static readonly FinishProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Finish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finishProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finishProductionOrder$Response(params: FinishProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return finishProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finishProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finishProductionOrder(params: FinishProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.finishProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `endProductionOrderProduction()` */
  static readonly EndProductionOrderProductionPath = '/api/v1/ProductionOrder/{productionOrderId}/EndProduction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `endProductionOrderProduction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  endProductionOrderProduction$Response(
    params: EndProductionOrderProduction$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return endProductionOrderProduction(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `endProductionOrderProduction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  endProductionOrderProduction(params: EndProductionOrderProduction$Params, context?: HttpContext): Observable<void> {
    return this.endProductionOrderProduction$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `cancelEndProduction()` */
  static readonly CancelEndProductionPath = '/api/v1/ProductionOrder/{productionOrderId}/CancelEndProduction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelEndProduction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelEndProduction$Response(params: CancelEndProduction$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cancelEndProduction(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelEndProduction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelEndProduction(params: CancelEndProduction$Params, context?: HttpContext): Observable<void> {
    return this.cancelEndProduction$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `endProductionForManualMachine()` */
  static readonly EndProductionForManualMachinePath = '/api/v1/ProductionOrder/{productionOrderId}/EndProductionForManualMachine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `endProductionForManualMachine()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  endProductionForManualMachine$Response(
    params: EndProductionForManualMachine$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return endProductionForManualMachine(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `endProductionForManualMachine$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  endProductionForManualMachine(params: EndProductionForManualMachine$Params, context?: HttpContext): Observable<void> {
    return this.endProductionForManualMachine$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `saveManualModeFinishingValues()` */
  static readonly SaveManualModeFinishingValuesPath = '/api/v1/ProductionOrder/{productionOrderId}/SaveManualModeFinishingValues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveManualModeFinishingValues()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveManualModeFinishingValues$Response(
    params: SaveManualModeFinishingValues$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return saveManualModeFinishingValues(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveManualModeFinishingValues$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveManualModeFinishingValues(params: SaveManualModeFinishingValues$Params, context?: HttpContext): Observable<void> {
    return this.saveManualModeFinishingValues$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `editProductionOrderDeviationReason()` */
  static readonly EditProductionOrderDeviationReasonPath = '/api/v1/ProductionOrder/{productionOrderId}/EditDeviationReason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProductionOrderDeviationReason()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProductionOrderDeviationReason$Response(
    params: EditProductionOrderDeviationReason$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return editProductionOrderDeviationReason(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editProductionOrderDeviationReason$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProductionOrderDeviationReason(params: EditProductionOrderDeviationReason$Params, context?: HttpContext): Observable<void> {
    return this.editProductionOrderDeviationReason$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setAsNextProductionOrder()` */
  static readonly SetAsNextProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/SetNext';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setAsNextProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAsNextProductionOrder$Response(params: SetAsNextProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setAsNextProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setAsNextProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAsNextProductionOrder(params: SetAsNextProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.setAsNextProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setProductionOrderFinishedInfo()` */
  static readonly SetProductionOrderFinishedInfoPath = '/api/v1/ProductionOrder/{productionOrderId}/SetFinishedInfos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setProductionOrderFinishedInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setProductionOrderFinishedInfo$Response(
    params: SetProductionOrderFinishedInfo$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return setProductionOrderFinishedInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setProductionOrderFinishedInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setProductionOrderFinishedInfo(params: SetProductionOrderFinishedInfo$Params, context?: HttpContext): Observable<void> {
    return this.setProductionOrderFinishedInfo$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setCurrentRunSubPhase()` */
  static readonly SetCurrentRunSubPhasePath = '/api/v1/ProductionOrder/{productionOrderId}/SetCurrentRunSubPhase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCurrentRunSubPhase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCurrentRunSubPhase$Response(params: SetCurrentRunSubPhase$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setCurrentRunSubPhase(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCurrentRunSubPhase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCurrentRunSubPhase(params: SetCurrentRunSubPhase$Params, context?: HttpContext): Observable<void> {
    return this.setCurrentRunSubPhase$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `confirmProductionOrderKpi()` */
  static readonly ConfirmProductionOrderKpiPath = '/api/v1/ProductionOrder/{productionOrderId}/ConfirmKpi';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmProductionOrderKpi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmProductionOrderKpi$Response(
    params: ConfirmProductionOrderKpi$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return confirmProductionOrderKpi(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmProductionOrderKpi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmProductionOrderKpi(params: ConfirmProductionOrderKpi$Params, context?: HttpContext): Observable<void> {
    return this.confirmProductionOrderKpi$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `unconfirmProductionOrderKpi()` */
  static readonly UnconfirmProductionOrderKpiPath = '/api/v1/ProductionOrder/{productionOrderId}/UnconfirmKpi';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unconfirmProductionOrderKpi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unconfirmProductionOrderKpi$Response(
    params: UnconfirmProductionOrderKpi$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return unconfirmProductionOrderKpi(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unconfirmProductionOrderKpi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unconfirmProductionOrderKpi(params: UnconfirmProductionOrderKpi$Params, context?: HttpContext): Observable<void> {
    return this.unconfirmProductionOrderKpi$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `reopenProductionOrder()` */
  static readonly ReopenProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}/Reopen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reopenProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reopenProductionOrder$Response(params: ReopenProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reopenProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reopenProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reopenProductionOrder(params: ReopenProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.reopenProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `addProductionOrderSetupWaste()` */
  static readonly AddProductionOrderSetupWastePath = '/api/v1/ProductionOrder/{productionOrderId}/AddSetupWasteToProductionOrder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addProductionOrderSetupWaste()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProductionOrderSetupWaste$Response(
    params: AddProductionOrderSetupWaste$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return addProductionOrderSetupWaste(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addProductionOrderSetupWaste$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProductionOrderSetupWaste(params: AddProductionOrderSetupWaste$Params, context?: HttpContext): Observable<void> {
    return this.addProductionOrderSetupWaste$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `deleteProductionOrderSetupWaste()` */
  static readonly DeleteProductionOrderSetupWastePath = '/api/v1/ProductionOrder/{productionOrderId}/DeleteSetupWasteFromProductionOrder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProductionOrderSetupWaste()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteProductionOrderSetupWaste$Response(
    params: DeleteProductionOrderSetupWaste$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return deleteProductionOrderSetupWaste(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteProductionOrderSetupWaste$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteProductionOrderSetupWaste(params: DeleteProductionOrderSetupWaste$Params, context?: HttpContext): Observable<void> {
    return this.deleteProductionOrderSetupWaste$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `editProductionOrderSetupPhase()` */
  static readonly EditProductionOrderSetupPhasePath = '/api/v1/ProductionOrder/{productionOrderId}/EditSetupPhase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editProductionOrderSetupPhase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProductionOrderSetupPhase$Response(
    params: EditProductionOrderSetupPhase$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return editProductionOrderSetupPhase(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editProductionOrderSetupPhase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editProductionOrderSetupPhase(params: EditProductionOrderSetupPhase$Params, context?: HttpContext): Observable<void> {
    return this.editProductionOrderSetupPhase$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setSetupEndTime()` */
  static readonly SetSetupEndTimePath = '/api/v1/ProductionOrder/{productionOrderId}/SetSetupEndTime';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSetupEndTime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSetupEndTime$Response(params: SetSetupEndTime$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setSetupEndTime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setSetupEndTime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSetupEndTime(params: SetSetupEndTime$Params, context?: HttpContext): Observable<void> {
    return this.setSetupEndTime$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `changeContainerTargetQuantity()` */
  static readonly ChangeContainerTargetQuantityPath = '/api/v1/ProductionOrder/{productionOrderId}/container/targetQuantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeContainerTargetQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeContainerTargetQuantity$Response(
    params: ChangeContainerTargetQuantity$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return changeContainerTargetQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeContainerTargetQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeContainerTargetQuantity(params: ChangeContainerTargetQuantity$Params, context?: HttpContext): Observable<void> {
    return this.changeContainerTargetQuantity$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `changeContainerTargetBobbinQuantity()` */
  static readonly ChangeContainerTargetBobbinQuantityPath = '/api/v1/ProductionOrder/{productionOrderId}/container/targetBobbinQuantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeContainerTargetBobbinQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeContainerTargetBobbinQuantity$Response(
    params: ChangeContainerTargetBobbinQuantity$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return changeContainerTargetBobbinQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeContainerTargetBobbinQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeContainerTargetBobbinQuantity(params: ChangeContainerTargetBobbinQuantity$Params, context?: HttpContext): Observable<void> {
    return this.changeContainerTargetBobbinQuantity$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `printLabelForOuter()` */
  static readonly PrintLabelForOuterPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer/PrintLabel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printLabelForOuter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  printLabelForOuter$Response(params: PrintLabelForOuter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return printLabelForOuter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `printLabelForOuter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  printLabelForOuter(params: PrintLabelForOuter$Params, context?: HttpContext): Observable<void> {
    return this.printLabelForOuter$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `changeProductionOrderOuterInfo()` */
  static readonly ChangeProductionOrderOuterInfoPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer/Info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeProductionOrderOuterInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeProductionOrderOuterInfo$Response(
    params: ChangeProductionOrderOuterInfo$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return changeProductionOrderOuterInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeProductionOrderOuterInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeProductionOrderOuterInfo(params: ChangeProductionOrderOuterInfo$Params, context?: HttpContext): Observable<void> {
    return this.changeProductionOrderOuterInfo$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `changeOuterQuantity()` */
  static readonly ChangeOuterQuantityPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer/ChangeOuterQuantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeOuterQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeOuterQuantity$Response(params: ChangeOuterQuantity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeOuterQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeOuterQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeOuterQuantity(params: ChangeOuterQuantity$Params, context?: HttpContext): Observable<void> {
    return this.changeOuterQuantity$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `addProductionOrderWasteAssignmentToOuter()` */
  static readonly AddProductionOrderWasteAssignmentToOuterPath =
    '/api/v1/ProductionOrder/{productionOrderId}/Outer/AddWasteAssignmentToOuter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addProductionOrderWasteAssignmentToOuter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProductionOrderWasteAssignmentToOuter$Response(
    params: AddProductionOrderWasteAssignmentToOuter$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return addProductionOrderWasteAssignmentToOuter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addProductionOrderWasteAssignmentToOuter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProductionOrderWasteAssignmentToOuter(
    params: AddProductionOrderWasteAssignmentToOuter$Params,
    context?: HttpContext
  ): Observable<void> {
    return this.addProductionOrderWasteAssignmentToOuter$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `deleteProductionOrderWasteAssignmentFromOuter()` */
  static readonly DeleteProductionOrderWasteAssignmentFromOuterPath =
    '/api/v1/ProductionOrder/{productionOrderId}/Outer/DeleteWasteAssignmentFromOuter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProductionOrderWasteAssignmentFromOuter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteProductionOrderWasteAssignmentFromOuter$Response(
    params: DeleteProductionOrderWasteAssignmentFromOuter$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return deleteProductionOrderWasteAssignmentFromOuter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteProductionOrderWasteAssignmentFromOuter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteProductionOrderWasteAssignmentFromOuter(
    params: DeleteProductionOrderWasteAssignmentFromOuter$Params,
    context?: HttpContext
  ): Observable<void> {
    return this.deleteProductionOrderWasteAssignmentFromOuter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `splitBillOfMaterialRow()` */
  static readonly SplitBillOfMaterialRowPath = '/api/v1/ProductionOrder/{productionOrderId}/SplitBillOfMaterialRow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `splitBillOfMaterialRow()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  splitBillOfMaterialRow$Response(params: SplitBillOfMaterialRow$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return splitBillOfMaterialRow(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `splitBillOfMaterialRow$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  splitBillOfMaterialRow(params: SplitBillOfMaterialRow$Params, context?: HttpContext): Observable<void> {
    return this.splitBillOfMaterialRow$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getManualTransportProcessInfo()` */
  static readonly GetManualTransportProcessInfoPath = '/api/v1/ProductionOrder/{productionOrderId}/GetManualTransportProcessInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualTransportProcessInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualTransportProcessInfo$Response(
    params: GetManualTransportProcessInfo$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualTransportProcessInfo>> {
    return getManualTransportProcessInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getManualTransportProcessInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualTransportProcessInfo(
    params: GetManualTransportProcessInfo$Params,
    context?: HttpContext
  ): Observable<ManualTransportProcessInfo> {
    return this.getManualTransportProcessInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualTransportProcessInfo>): ManualTransportProcessInfo => r.body)
    );
  }

  /** Path part for operation `getProductionOrder()` */
  static readonly GetProductionOrderPath = '/api/v1/ProductionOrder/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrder$Response(params: GetProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductionOrder>> {
    return getProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrder(params: GetProductionOrder$Params, context?: HttpContext): Observable<ProductionOrder> {
    return this.getProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<ProductionOrder>): ProductionOrder => r.body));
  }

  /** Path part for operation `getProductionOrderInfoByExternalId()` */
  static readonly GetProductionOrderInfoByExternalIdPath =
    '/api/v1/ProductionOrder/{externalProductionOrderId}/GetProductionOrderInfoByExternalId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderInfoByExternalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderInfoByExternalId$Response(
    params: GetProductionOrderInfoByExternalId$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProductionOrderExternalInfo>> {
    return getProductionOrderInfoByExternalId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderInfoByExternalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderInfoByExternalId(
    params: GetProductionOrderInfoByExternalId$Params,
    context?: HttpContext
  ): Observable<ProductionOrderExternalInfo> {
    return this.getProductionOrderInfoByExternalId$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductionOrderExternalInfo>): ProductionOrderExternalInfo => r.body)
    );
  }

  /** Path part for operation `getProductionOrderInfoByExternalId2()` */
  static readonly GetProductionOrderInfoByExternalId2Path =
    '/api/v1/ProductionOrder/GetProductionOrderInfoByExternalId/{externalProductionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderInfoByExternalId2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderInfoByExternalId2$Response(
    params: GetProductionOrderInfoByExternalId2$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProductionOrderExternalInfo>> {
    return getProductionOrderInfoByExternalId2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderInfoByExternalId2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderInfoByExternalId2(
    params: GetProductionOrderInfoByExternalId2$Params,
    context?: HttpContext
  ): Observable<ProductionOrderExternalInfo> {
    return this.getProductionOrderInfoByExternalId2$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductionOrderExternalInfo>): ProductionOrderExternalInfo => r.body)
    );
  }

  /** Path part for operation `getHeaderKpi()` */
  static readonly GetHeaderKpiPath = '/api/v1/ProductionOrder/GetHeaderKpi/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHeaderKpi()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaderKpi$Response(params: GetHeaderKpi$Params, context?: HttpContext): Observable<StrictHttpResponse<HeaderKpiViewModel>> {
    return getHeaderKpi(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHeaderKpi$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaderKpi(params: GetHeaderKpi$Params, context?: HttpContext): Observable<HeaderKpiViewModel> {
    return this.getHeaderKpi$Response(params, context).pipe(map((r: StrictHttpResponse<HeaderKpiViewModel>): HeaderKpiViewModel => r.body));
  }

  /** Path part for operation `getProductionOrderPredecessorInfo()` */
  static readonly GetProductionOrderPredecessorInfoPath = '/api/v1/ProductionOrder/{productionOrderId}/PredecessorInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderPredecessorInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderPredecessorInfo$Response(
    params: GetProductionOrderPredecessorInfo$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProductionOrderPredecessorInfo>> {
    return getProductionOrderPredecessorInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderPredecessorInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderPredecessorInfo(
    params: GetProductionOrderPredecessorInfo$Params,
    context?: HttpContext
  ): Observable<ProductionOrderPredecessorInfo> {
    return this.getProductionOrderPredecessorInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductionOrderPredecessorInfo>): ProductionOrderPredecessorInfo => r.body)
    );
  }

  /** Path part for operation `getProductionOrderDetails()` */
  static readonly GetProductionOrderDetailsPath = '/api/v1/ProductionOrder/{workCenterId}/GetProductionOrderDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProductionOrderDetails$Response(
    params: GetProductionOrderDetails$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProductionOrderData>>> {
    return getProductionOrderDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProductionOrderDetails(params: GetProductionOrderDetails$Params, context?: HttpContext): Observable<Array<ProductionOrderData>> {
    return this.getProductionOrderDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductionOrderData>>): Array<ProductionOrderData> => r.body)
    );
  }

  /** Path part for operation `getBillOfMaterials()` */
  static readonly GetBillOfMaterialsPath = '/api/v1/ProductionOrder/{productionOrderId}/GetBillOfMaterials';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBillOfMaterials()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillOfMaterials$Response(
    params: GetBillOfMaterials$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BillOfMaterial>>> {
    return getBillOfMaterials(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBillOfMaterials$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillOfMaterials(params: GetBillOfMaterials$Params, context?: HttpContext): Observable<Array<BillOfMaterial>> {
    return this.getBillOfMaterials$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillOfMaterial>>): Array<BillOfMaterial> => r.body)
    );
  }

  /** Path part for operation `getSetupPhaseState()` */
  static readonly GetSetupPhaseStatePath = '/api/v1/ProductionOrder/{productionOrderId}/GetSetupPhaseState';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetupPhaseState()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetupPhaseState$Response(
    params: GetSetupPhaseState$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProductionOrderSetupPhaseState>> {
    return getSetupPhaseState(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSetupPhaseState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetupPhaseState(params: GetSetupPhaseState$Params, context?: HttpContext): Observable<ProductionOrderSetupPhaseState> {
    return this.getSetupPhaseState$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductionOrderSetupPhaseState>): ProductionOrderSetupPhaseState => r.body)
    );
  }

  /** Path part for operation `getManualModeFinishingData()` */
  static readonly GetManualModeFinishingDataPath = '/api/v1/ProductionOrder/{productionOrderId}/GetManualModeFinishingData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualModeFinishingData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualModeFinishingData$Response(
    params: GetManualModeFinishingData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProductionOrderManualModeFinishingDataViewModel>> {
    return getManualModeFinishingData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getManualModeFinishingData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualModeFinishingData(
    params: GetManualModeFinishingData$Params,
    context?: HttpContext
  ): Observable<ProductionOrderManualModeFinishingDataViewModel> {
    return this.getManualModeFinishingData$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<ProductionOrderManualModeFinishingDataViewModel>): ProductionOrderManualModeFinishingDataViewModel => r.body
      )
    );
  }

  /** Path part for operation `getProductionLoyaltyReasons()` */
  static readonly GetProductionLoyaltyReasonsPath = '/api/v1/ProductionOrder/{productionOrderId}/ProductionLoyaltyReasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionLoyaltyReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionLoyaltyReasons$Response(
    params: GetProductionLoyaltyReasons$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProductionOrderProductionLoyalty>> {
    return getProductionLoyaltyReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionLoyaltyReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionLoyaltyReasons(
    params: GetProductionLoyaltyReasons$Params,
    context?: HttpContext
  ): Observable<ProductionOrderProductionLoyalty> {
    return this.getProductionLoyaltyReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductionOrderProductionLoyalty>): ProductionOrderProductionLoyalty => r.body)
    );
  }

  /** Path part for operation `getFinishedInfos()` */
  static readonly GetFinishedInfosPath = '/api/v1/ProductionOrder/{productionOrderId}/GetFinishedInfos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinishedInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinishedInfos$Response(
    params: GetFinishedInfos$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProductionOrderFinishedInfos>> {
    return getFinishedInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinishedInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinishedInfos(params: GetFinishedInfos$Params, context?: HttpContext): Observable<ProductionOrderFinishedInfos> {
    return this.getFinishedInfos$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductionOrderFinishedInfos>): ProductionOrderFinishedInfos => r.body)
    );
  }

  /** Path part for operation `getRunPhases()` */
  static readonly GetRunPhasesPath = '/api/v1/ProductionOrder/{productionOrderId}/RunPhases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRunPhases()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRunPhases$Response(params: GetRunPhases$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RunPhase>>> {
    return getRunPhases(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRunPhases$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRunPhases(params: GetRunPhases$Params, context?: HttpContext): Observable<Array<RunPhase>> {
    return this.getRunPhases$Response(params, context).pipe(map((r: StrictHttpResponse<Array<RunPhase>>): Array<RunPhase> => r.body));
  }

  /** Path part for operation `getAllKpis()` */
  static readonly GetAllKpisPath = '/api/v1/ProductionOrder/{productionOrderId}/GetAllKpis';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllKpis()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllKpis$Response(params: GetAllKpis$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KpiValue>>> {
    return getAllKpis(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllKpis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllKpis(params: GetAllKpis$Params, context?: HttpContext): Observable<Array<KpiValue>> {
    return this.getAllKpis$Response(params, context).pipe(map((r: StrictHttpResponse<Array<KpiValue>>): Array<KpiValue> => r.body));
  }

  /** Path part for operation `getOuter()` */
  static readonly GetOuterPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOuter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOuter$Response(params: GetOuter$Params, context?: HttpContext): Observable<StrictHttpResponse<Outer>> {
    return getOuter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOuter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOuter(params: GetOuter$Params, context?: HttpContext): Observable<Outer> {
    return this.getOuter$Response(params, context).pipe(map((r: StrictHttpResponse<Outer>): Outer => r.body));
  }

  /** Path part for operation `getPrintInfo()` */
  static readonly GetPrintInfoPath = '/api/v1/ProductionOrder/{productionOrderId}/Outer/PrintInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrintInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrintInfo$Response(params: GetPrintInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<OuterPrintInfo>> {
    return getPrintInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPrintInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrintInfo(params: GetPrintInfo$Params, context?: HttpContext): Observable<OuterPrintInfo> {
    return this.getPrintInfo$Response(params, context).pipe(map((r: StrictHttpResponse<OuterPrintInfo>): OuterPrintInfo => r.body));
  }

  /** Path part for operation `getProjectedRunEnd()` */
  static readonly GetProjectedRunEndPath = '/api/v1/ProductionOrder/{productionOrderId}/GetProjectedRunEnd';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProjectedRunEnd()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectedRunEnd$Response(params: GetProjectedRunEnd$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectedRunEnd>> {
    return getProjectedRunEnd(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProjectedRunEnd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectedRunEnd(params: GetProjectedRunEnd$Params, context?: HttpContext): Observable<ProjectedRunEnd> {
    return this.getProjectedRunEnd$Response(params, context).pipe(map((r: StrictHttpResponse<ProjectedRunEnd>): ProjectedRunEnd => r.body));
  }

  /** Path part for operation `getDefaultTargetContainerQuantity()` */
  static readonly GetDefaultTargetContainerQuantityPath = '/api/v1/ProductionOrder/{productionOrderId}/GetDefaultTargetContainerQuantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultTargetContainerQuantity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultTargetContainerQuantity$Response(
    params: GetDefaultTargetContainerQuantity$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<DefaultTargetContainerQuantityInfo>> {
    return getDefaultTargetContainerQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultTargetContainerQuantity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultTargetContainerQuantity(
    params: GetDefaultTargetContainerQuantity$Params,
    context?: HttpContext
  ): Observable<DefaultTargetContainerQuantityInfo> {
    return this.getDefaultTargetContainerQuantity$Response(params, context).pipe(
      map((r: StrictHttpResponse<DefaultTargetContainerQuantityInfo>): DefaultTargetContainerQuantityInfo => r.body)
    );
  }

  /** Path part for operation `splitBillOfMaterialData()` */
  static readonly SplitBillOfMaterialDataPath =
    '/api/v1/ProductionOrder/{productionOrderId}/billOfMaterials/{billOfMaterialId}/SplitBillOfMaterialData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `splitBillOfMaterialData()` instead.
   *
   * This method doesn't expect any request body.
   */
  splitBillOfMaterialData$Response(
    params: SplitBillOfMaterialData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<SplitBillOfMaterialData>> {
    return splitBillOfMaterialData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `splitBillOfMaterialData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  splitBillOfMaterialData(params: SplitBillOfMaterialData$Params, context?: HttpContext): Observable<SplitBillOfMaterialData> {
    return this.splitBillOfMaterialData$Response(params, context).pipe(
      map((r: StrictHttpResponse<SplitBillOfMaterialData>): SplitBillOfMaterialData => r.body)
    );
  }

  /** Path part for operation `suggestQuantityForLastProducedMaterial()` */
  static readonly SuggestQuantityForLastProducedMaterialPath =
    '/api/v1/ProductionOrder/{productionOrderId}/SuggestQuantityForLastProducedMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestQuantityForLastProducedMaterial()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestQuantityForLastProducedMaterial$Response(
    params: SuggestQuantityForLastProducedMaterial$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<SuggestQuantityForLastProducedMaterialViewModel>> {
    return suggestQuantityForLastProducedMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suggestQuantityForLastProducedMaterial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestQuantityForLastProducedMaterial(
    params: SuggestQuantityForLastProducedMaterial$Params,
    context?: HttpContext
  ): Observable<SuggestQuantityForLastProducedMaterialViewModel> {
    return this.suggestQuantityForLastProducedMaterial$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<SuggestQuantityForLastProducedMaterialViewModel>): SuggestQuantityForLastProducedMaterialViewModel => r.body
      )
    );
  }

  /** Path part for operation `getOuterViewQuantityDetails()` */
  static readonly GetOuterViewQuantityDetailsPath = '/api/v1/ProductionOrder/{productionOrderId}/GetOuterViewQuantityDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOuterViewQuantityDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOuterViewQuantityDetails$Response(
    params: GetOuterViewQuantityDetails$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<OuterQuantityDetails>> {
    return getOuterViewQuantityDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOuterViewQuantityDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOuterViewQuantityDetails(params: GetOuterViewQuantityDetails$Params, context?: HttpContext): Observable<OuterQuantityDetails> {
    return this.getOuterViewQuantityDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<OuterQuantityDetails>): OuterQuantityDetails => r.body)
    );
  }

  /** Path part for operation `searchOrders()` */
  static readonly SearchOrdersPath = '/api/v1/ProductionOrder/{workCenterId}/SearchOrders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchOrders$Response(params: SearchOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SearchOrdersViewModel>>> {
    return searchOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchOrders(params: SearchOrders$Params, context?: HttpContext): Observable<Array<SearchOrdersViewModel>> {
    return this.searchOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SearchOrdersViewModel>>): Array<SearchOrdersViewModel> => r.body)
    );
  }
}
