/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { WidgetSettings } from '../../models/widget-settings';

export interface UpdateWidgetConfiguration$Params {
  orid?: number;
  wcid?: number;
  usid?: number;
  uwid?: number;
  body: WidgetSettings;
}

export function updateWidgetConfiguration(
  http: HttpClient,
  rootUrl: string,
  params: UpdateWidgetConfiguration$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<number>> {
  const rb = new RequestBuilder(rootUrl, updateWidgetConfiguration.PATH, 'put');
  if (params) {
    rb.query('orid', params.orid, {});
    rb.query('wcid', params.wcid, {});
    rb.query('usid', params.usid, {});
    rb.query('uwid', params.uwid, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
    })
  );
}

updateWidgetConfiguration.PATH = '/api/DashboardManagement/updateWidgetConfiguration';
