/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PageAccessDetails } from '../../models/page-access-details';

export interface GetAccessOfComponent$Params {
  pageName?: string;
}

export function getAccessOfComponent(
  http: HttpClient,
  rootUrl: string,
  params?: GetAccessOfComponent$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<PageAccessDetails>>> {
  const rb = new RequestBuilder(rootUrl, getAccessOfComponent.PATH, 'get');
  if (params) {
    rb.query('pageName', params.pageName, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<PageAccessDetails>>;
    })
  );
}

getAccessOfComponent.PATH = '/api/AclRole/accessOfComponent';
