/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LiveApiConfiguration, LiveApiConfigurationParams } from './live-api-configuration';

import { AclRoleService } from './services/acl-role.service';
import { AttributeGroupsService } from './services/attribute-groups.service';
import { AuthService } from './services/auth.service';
import { DashboardManagementService } from './services/dashboard-management.service';
import { FeaturesService } from './services/features.service';
import { FrontendConfigService } from './services/frontend-config.service';
import { GeneralSettingsService } from './services/general-settings.service';
import { GridSettingsService } from './services/grid-settings.service';
import { MasterDataSourceService } from './services/master-data-source.service';
import { MetaModelService } from './services/meta-model.service';
import { NavigationService } from './services/navigation.service';
import { OrganizationsService } from './services/organizations.service';
import { RoleService } from './services/role.service';
import { UserService } from './services/user.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AclRoleService,
    AttributeGroupsService,
    AuthService,
    DashboardManagementService,
    FeaturesService,
    FrontendConfigService,
    GeneralSettingsService,
    GridSettingsService,
    MasterDataSourceService,
    MetaModelService,
    NavigationService,
    OrganizationsService,
    RoleService,
    UserService,
    LiveApiConfiguration
  ]
})
export class LiveApiModule {
  static forRoot(params: LiveApiConfigurationParams): ModuleWithProviders<LiveApiModule> {
    return {
      ngModule: LiveApiModule,
      providers: [
        {
          provide: LiveApiConfiguration,
          useValue: params
        }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: LiveApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('LiveApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
