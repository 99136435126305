/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApprovalReport } from '../models/approval-report';
import { ApprovalReportConsumption } from '../models/approval-report-consumption';
import { ApprovalReportDates } from '../models/approval-report-dates';
import { ApprovalWorkCenter } from '../models/approval-work-center';
import { changeQuantity } from '../fn/approval-report/change-quantity';
import { ChangeQuantity$Params } from '../fn/approval-report/change-quantity';
import { confirmApprovalReport } from '../fn/approval-report/confirm-approval-report';
import { ConfirmApprovalReport$Params } from '../fn/approval-report/confirm-approval-report';
import { editApprovalReportSetupPhase } from '../fn/approval-report/edit-approval-report-setup-phase';
import { EditApprovalReportSetupPhase$Params } from '../fn/approval-report/edit-approval-report-setup-phase';
import { getApprovalReport } from '../fn/approval-report/get-approval-report';
import { GetApprovalReport$Params } from '../fn/approval-report/get-approval-report';
import { getApprovalSetupParameterKinds } from '../fn/approval-report/get-approval-setup-parameter-kinds';
import { GetApprovalSetupParameterKinds$Params } from '../fn/approval-report/get-approval-setup-parameter-kinds';
import { getApprovalSetupParameters } from '../fn/approval-report/get-approval-setup-parameters';
import { GetApprovalSetupParameters$Params } from '../fn/approval-report/get-approval-setup-parameters';
import { getApprovalWorkCenters } from '../fn/approval-report/get-approval-work-centers';
import { GetApprovalWorkCenters$Params } from '../fn/approval-report/get-approval-work-centers';
import { getDowntimeInfos } from '../fn/approval-report/get-downtime-infos';
import { GetDowntimeInfos$Params } from '../fn/approval-report/get-downtime-infos';
import { getOpenApprovalReportDates } from '../fn/approval-report/get-open-approval-report-dates';
import { GetOpenApprovalReportDates$Params } from '../fn/approval-report/get-open-approval-report-dates';
import { getProducedMaterialInfos } from '../fn/approval-report/get-produced-material-infos';
import { GetProducedMaterialInfos$Params } from '../fn/approval-report/get-produced-material-infos';
import { getProductionOrderConsumption } from '../fn/approval-report/get-production-order-consumption';
import { GetProductionOrderConsumption$Params } from '../fn/approval-report/get-production-order-consumption';
import { getProductionOrderInfos } from '../fn/approval-report/get-production-order-infos';
import { GetProductionOrderInfos$Params } from '../fn/approval-report/get-production-order-infos';
import { getShiftInfos } from '../fn/approval-report/get-shift-infos';
import { GetShiftInfos$Params } from '../fn/approval-report/get-shift-infos';
import { InfoList } from '../models/info-list';
import { interruptProductionOrder } from '../fn/approval-report/interrupt-production-order';
import { InterruptProductionOrder$Params } from '../fn/approval-report/interrupt-production-order';
import { putApprovalReportProductionQuantities } from '../fn/approval-report/put-approval-report-production-quantities';
import { PutApprovalReportProductionQuantities$Params } from '../fn/approval-report/put-approval-report-production-quantities';
import { putApprovalReportSetupParameter } from '../fn/approval-report/put-approval-report-setup-parameter';
import { PutApprovalReportSetupParameter$Params } from '../fn/approval-report/put-approval-report-setup-parameter';
import { putApprovalReportSetupParameterKind } from '../fn/approval-report/put-approval-report-setup-parameter-kind';
import { PutApprovalReportSetupParameterKind$Params } from '../fn/approval-report/put-approval-report-setup-parameter-kind';
import { reloadApprovalReportData } from '../fn/approval-report/reload-approval-report-data';
import { ReloadApprovalReportData$Params } from '../fn/approval-report/reload-approval-report-data';
import { SetupParameterKind } from '../models/setup-parameter-kind';
import { SetupParameters } from '../models/setup-parameters';
import { ShiftInfoList } from '../models/shift-info-list';

@Injectable({ providedIn: 'root' })
export class ApprovalReportService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getApprovalReport()` */
  static readonly GetApprovalReportPath = '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApprovalReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalReport$Response(params: GetApprovalReport$Params, context?: HttpContext): Observable<StrictHttpResponse<ApprovalReport>> {
    return getApprovalReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApprovalReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalReport(params: GetApprovalReport$Params, context?: HttpContext): Observable<ApprovalReport> {
    return this.getApprovalReport$Response(params, context).pipe(map((r: StrictHttpResponse<ApprovalReport>): ApprovalReport => r.body));
  }

  /** Path part for operation `confirmApprovalReport()` */
  static readonly ConfirmApprovalReportPath = '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmApprovalReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmApprovalReport$Response(params: ConfirmApprovalReport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return confirmApprovalReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmApprovalReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmApprovalReport(params: ConfirmApprovalReport$Params, context?: HttpContext): Observable<void> {
    return this.confirmApprovalReport$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `putApprovalReportSetupParameterKind()` */
  static readonly PutApprovalReportSetupParameterKindPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/setupParameterKind';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putApprovalReportSetupParameterKind()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putApprovalReportSetupParameterKind$Response(
    params: PutApprovalReportSetupParameterKind$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return putApprovalReportSetupParameterKind(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putApprovalReportSetupParameterKind$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putApprovalReportSetupParameterKind(params: PutApprovalReportSetupParameterKind$Params, context?: HttpContext): Observable<void> {
    return this.putApprovalReportSetupParameterKind$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `putApprovalReportSetupParameter()` */
  static readonly PutApprovalReportSetupParameterPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/setupParameters/{setupParameterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putApprovalReportSetupParameter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putApprovalReportSetupParameter$Response(
    params: PutApprovalReportSetupParameter$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return putApprovalReportSetupParameter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putApprovalReportSetupParameter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putApprovalReportSetupParameter(params: PutApprovalReportSetupParameter$Params, context?: HttpContext): Observable<void> {
    return this.putApprovalReportSetupParameter$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `putApprovalReportProductionQuantities()` */
  static readonly PutApprovalReportProductionQuantitiesPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/productionQuantities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putApprovalReportProductionQuantities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putApprovalReportProductionQuantities$Response(
    params: PutApprovalReportProductionQuantities$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return putApprovalReportProductionQuantities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putApprovalReportProductionQuantities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putApprovalReportProductionQuantities(params: PutApprovalReportProductionQuantities$Params, context?: HttpContext): Observable<void> {
    return this.putApprovalReportProductionQuantities$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `editApprovalReportSetupPhase()` */
  static readonly EditApprovalReportSetupPhasePath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/EditSetupPhase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editApprovalReportSetupPhase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editApprovalReportSetupPhase$Response(
    params: EditApprovalReportSetupPhase$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return editApprovalReportSetupPhase(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editApprovalReportSetupPhase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editApprovalReportSetupPhase(params: EditApprovalReportSetupPhase$Params, context?: HttpContext): Observable<void> {
    return this.editApprovalReportSetupPhase$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `changeQuantity()` */
  static readonly ChangeQuantityPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/changeQuantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeQuantity$Response(params: ChangeQuantity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeQuantity(params: ChangeQuantity$Params, context?: HttpContext): Observable<void> {
    return this.changeQuantity$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `interruptProductionOrder()` */
  static readonly InterruptProductionOrderPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/interruptProductionOrder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interruptProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interruptProductionOrder$Response(params: InterruptProductionOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return interruptProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `interruptProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interruptProductionOrder(params: InterruptProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.interruptProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `reloadApprovalReportData()` */
  static readonly ReloadApprovalReportDataPath = '/api/v1/workCenter/{workCenterId}/approvals/reloadApprovalReportData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reloadApprovalReportData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reloadApprovalReportData$Response(params: ReloadApprovalReportData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reloadApprovalReportData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reloadApprovalReportData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reloadApprovalReportData(params: ReloadApprovalReportData$Params, context?: HttpContext): Observable<void> {
    return this.reloadApprovalReportData$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getApprovalWorkCenters()` */
  static readonly GetApprovalWorkCentersPath = '/api/v1/workCenterGroups/{workCenterGroupId}/approvals/{approvalReportDate}/workCenters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApprovalWorkCenters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalWorkCenters$Response(
    params: GetApprovalWorkCenters$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApprovalWorkCenter>>> {
    return getApprovalWorkCenters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApprovalWorkCenters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalWorkCenters(params: GetApprovalWorkCenters$Params, context?: HttpContext): Observable<Array<ApprovalWorkCenter>> {
    return this.getApprovalWorkCenters$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApprovalWorkCenter>>): Array<ApprovalWorkCenter> => r.body)
    );
  }

  /** Path part for operation `getShiftInfos()` */
  static readonly GetShiftInfosPath = '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/shifts/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShiftInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftInfos$Response(params: GetShiftInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<ShiftInfoList>> {
    return getShiftInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShiftInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftInfos(params: GetShiftInfos$Params, context?: HttpContext): Observable<ShiftInfoList> {
    return this.getShiftInfos$Response(params, context).pipe(map((r: StrictHttpResponse<ShiftInfoList>): ShiftInfoList => r.body));
  }

  /** Path part for operation `getProductionOrderInfos()` */
  static readonly GetProductionOrderInfosPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/productionOrders/{productionOrderId}/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderInfos$Response(
    params: GetProductionOrderInfos$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<InfoList>> {
    return getProductionOrderInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderInfos(params: GetProductionOrderInfos$Params, context?: HttpContext): Observable<InfoList> {
    return this.getProductionOrderInfos$Response(params, context).pipe(map((r: StrictHttpResponse<InfoList>): InfoList => r.body));
  }

  /** Path part for operation `getDowntimeInfos()` */
  static readonly GetDowntimeInfosPath = '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/downtimes/{downtimeId}/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDowntimeInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDowntimeInfos$Response(params: GetDowntimeInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<InfoList>> {
    return getDowntimeInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDowntimeInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDowntimeInfos(params: GetDowntimeInfos$Params, context?: HttpContext): Observable<InfoList> {
    return this.getDowntimeInfos$Response(params, context).pipe(map((r: StrictHttpResponse<InfoList>): InfoList => r.body));
  }

  /** Path part for operation `getProducedMaterialInfos()` */
  static readonly GetProducedMaterialInfosPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/producedMaterials/{producedMaterialId}/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProducedMaterialInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProducedMaterialInfos$Response(
    params: GetProducedMaterialInfos$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<InfoList>> {
    return getProducedMaterialInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProducedMaterialInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProducedMaterialInfos(params: GetProducedMaterialInfos$Params, context?: HttpContext): Observable<InfoList> {
    return this.getProducedMaterialInfos$Response(params, context).pipe(map((r: StrictHttpResponse<InfoList>): InfoList => r.body));
  }

  /** Path part for operation `getOpenApprovalReportDates()` */
  static readonly GetOpenApprovalReportDatesPath = '/api/v1/workCenterGroups/{workCenterId}/approvals/approvalReportDates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenApprovalReportDates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenApprovalReportDates$Response(
    params: GetOpenApprovalReportDates$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApprovalReportDates>> {
    return getOpenApprovalReportDates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenApprovalReportDates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenApprovalReportDates(params: GetOpenApprovalReportDates$Params, context?: HttpContext): Observable<ApprovalReportDates> {
    return this.getOpenApprovalReportDates$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApprovalReportDates>): ApprovalReportDates => r.body)
    );
  }

  /** Path part for operation `getApprovalSetupParameterKinds()` */
  static readonly GetApprovalSetupParameterKindsPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/setupParameterKinds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApprovalSetupParameterKinds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalSetupParameterKinds$Response(
    params: GetApprovalSetupParameterKinds$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<SetupParameterKind>> {
    return getApprovalSetupParameterKinds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApprovalSetupParameterKinds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalSetupParameterKinds(params: GetApprovalSetupParameterKinds$Params, context?: HttpContext): Observable<SetupParameterKind> {
    return this.getApprovalSetupParameterKinds$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetupParameterKind>): SetupParameterKind => r.body)
    );
  }

  /** Path part for operation `getApprovalSetupParameters()` */
  static readonly GetApprovalSetupParametersPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/setupParameters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApprovalSetupParameters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalSetupParameters$Response(
    params: GetApprovalSetupParameters$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<SetupParameters>> {
    return getApprovalSetupParameters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApprovalSetupParameters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalSetupParameters(params: GetApprovalSetupParameters$Params, context?: HttpContext): Observable<SetupParameters> {
    return this.getApprovalSetupParameters$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetupParameters>): SetupParameters => r.body)
    );
  }

  /** Path part for operation `getProductionOrderConsumption()` */
  static readonly GetProductionOrderConsumptionPath =
    '/api/v1/workCenter/{workCenterId}/approvals/{approvalReportDate}/items/{approvalReportLineId}/consumption';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderConsumption()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderConsumption$Response(
    params: GetProductionOrderConsumption$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApprovalReportConsumption>> {
    return getProductionOrderConsumption(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderConsumption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderConsumption(
    params: GetProductionOrderConsumption$Params,
    context?: HttpContext
  ): Observable<ApprovalReportConsumption> {
    return this.getProductionOrderConsumption$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApprovalReportConsumption>): ApprovalReportConsumption => r.body)
    );
  }
}
