/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface DeleteWidgetConfiguration$Params {
  orid?: number;
  wcid?: number;
}

export function deleteWidgetConfiguration(
  http: HttpClient,
  rootUrl: string,
  params?: DeleteWidgetConfiguration$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<number>> {
  const rb = new RequestBuilder(rootUrl, deleteWidgetConfiguration.PATH, 'delete');
  if (params) {
    rb.query('orid', params.orid, {});
    rb.query('wcid', params.wcid, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
    })
  );
}

deleteWidgetConfiguration.PATH = '/api/DashboardManagement/deleteWidgetConfiguration';
