/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addWasteAssignmentToProducedMaterial } from '../fn/containers/add-waste-assignment-to-produced-material';
import { AddWasteAssignmentToProducedMaterial$Params } from '../fn/containers/add-waste-assignment-to-produced-material';
import { changeContainerBobbinQuantity } from '../fn/containers/change-container-bobbin-quantity';
import { ChangeContainerBobbinQuantity$Params } from '../fn/containers/change-container-bobbin-quantity';
import { changeContainerQuantity } from '../fn/containers/change-container-quantity';
import { ChangeContainerQuantity$Params } from '../fn/containers/change-container-quantity';
import { Container } from '../models/container';
import { createContainerFromPool } from '../fn/containers/create-container-from-pool';
import { CreateContainerFromPool$Params } from '../fn/containers/create-container-from-pool';
import { CreateManualPalletData } from '../models/create-manual-pallet-data';
import { deleteWasteAssignmentFromProducedMaterial } from '../fn/containers/delete-waste-assignment-from-produced-material';
import { DeleteWasteAssignmentFromProducedMaterial$Params } from '../fn/containers/delete-waste-assignment-from-produced-material';
import { finishProducedMaterial } from '../fn/containers/finish-produced-material';
import { FinishProducedMaterial$Params } from '../fn/containers/finish-produced-material';
import { getContainer } from '../fn/containers/get-container';
import { GetContainer$Params } from '../fn/containers/get-container';
import { getInputContainerList } from '../fn/containers/get-input-container-list';
import { GetInputContainerList$Params } from '../fn/containers/get-input-container-list';
import { getManualContainerCreationData } from '../fn/containers/get-manual-container-creation-data';
import { GetManualContainerCreationData$Params } from '../fn/containers/get-manual-container-creation-data';
import { getProducedMaterialList } from '../fn/containers/get-produced-material-list';
import { GetProducedMaterialList$Params } from '../fn/containers/get-produced-material-list';
import { openProducedMaterial } from '../fn/containers/open-produced-material';
import { OpenProducedMaterial$Params } from '../fn/containers/open-produced-material';
import { PrimaryMaterialMounted } from '../models/primary-material-mounted';
import { printLabel } from '../fn/containers/print-label';
import { PrintLabel$Params } from '../fn/containers/print-label';
import { ProducedMaterialsViewModel } from '../models/produced-materials-view-model';
import { removeLastMark } from '../fn/containers/remove-last-mark';
import { RemoveLastMark$Params } from '../fn/containers/remove-last-mark';
import { setContainerTargetBobbinQuantity } from '../fn/containers/set-container-target-bobbin-quantity';
import { SetContainerTargetBobbinQuantity$Params } from '../fn/containers/set-container-target-bobbin-quantity';
import { setContainerTargetQuantity } from '../fn/containers/set-container-target-quantity';
import { SetContainerTargetQuantity$Params } from '../fn/containers/set-container-target-quantity';
import { setLastMark } from '../fn/containers/set-last-mark';
import { SetLastMark$Params } from '../fn/containers/set-last-mark';
import { setPrintLabelInfo } from '../fn/containers/set-print-label-info';
import { SetPrintLabelInfo$Params } from '../fn/containers/set-print-label-info';
import { switchManualProductionOrder } from '../fn/containers/switch-manual-production-order';
import { SwitchManualProductionOrder$Params } from '../fn/containers/switch-manual-production-order';

@Injectable({ providedIn: 'root' })
export class ContainersService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createContainerFromPool()` */
  static readonly CreateContainerFromPoolPath = '/api/v1/Containers/{productionOrderId}/CreateFromPool';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContainerFromPool()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContainerFromPool$Response(params: CreateContainerFromPool$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createContainerFromPool(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContainerFromPool$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContainerFromPool(params: CreateContainerFromPool$Params, context?: HttpContext): Observable<void> {
    return this.createContainerFromPool$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `printLabel()` */
  static readonly PrintLabelPath = '/api/v1/Containers/{containerId}/PrintLabel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printLabel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  printLabel$Response(params: PrintLabel$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return printLabel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `printLabel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  printLabel(params: PrintLabel$Params, context?: HttpContext): Observable<void> {
    return this.printLabel$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setPrintLabelInfo()` */
  static readonly SetPrintLabelInfoPath = '/api/v1/Containers/{containerId}/SetPrintLabelInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPrintLabelInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPrintLabelInfo$Response(params: SetPrintLabelInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setPrintLabelInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setPrintLabelInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPrintLabelInfo(params: SetPrintLabelInfo$Params, context?: HttpContext): Observable<void> {
    return this.setPrintLabelInfo$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `finishProducedMaterial()` */
  static readonly FinishProducedMaterialPath = '/api/v1/Containers/{producedMaterialId}/Finish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finishProducedMaterial()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finishProducedMaterial$Response(params: FinishProducedMaterial$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return finishProducedMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finishProducedMaterial$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finishProducedMaterial(params: FinishProducedMaterial$Params, context?: HttpContext): Observable<void> {
    return this.finishProducedMaterial$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `openProducedMaterial()` */
  static readonly OpenProducedMaterialPath = '/api/v1/Containers/{producedMaterialId}/Open';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openProducedMaterial()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openProducedMaterial$Response(params: OpenProducedMaterial$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return openProducedMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openProducedMaterial$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openProducedMaterial(params: OpenProducedMaterial$Params, context?: HttpContext): Observable<void> {
    return this.openProducedMaterial$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setLastMark()` */
  static readonly SetLastMarkPath = '/api/v1/Containers/{producedMaterialId}/SetLastMark';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setLastMark()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setLastMark$Response(params: SetLastMark$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setLastMark(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setLastMark$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setLastMark(params: SetLastMark$Params, context?: HttpContext): Observable<void> {
    return this.setLastMark$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `removeLastMark()` */
  static readonly RemoveLastMarkPath = '/api/v1/Containers/{producedMaterialId}/RemoveLastMark';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeLastMark()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeLastMark$Response(params: RemoveLastMark$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeLastMark(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeLastMark$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeLastMark(params: RemoveLastMark$Params, context?: HttpContext): Observable<void> {
    return this.removeLastMark$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `addWasteAssignmentToProducedMaterial()` */
  static readonly AddWasteAssignmentToProducedMaterialPath = '/api/v1/Containers/{producedMaterialId}/AddWasteAssignmentToProducedMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addWasteAssignmentToProducedMaterial()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addWasteAssignmentToProducedMaterial$Response(
    params: AddWasteAssignmentToProducedMaterial$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return addWasteAssignmentToProducedMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addWasteAssignmentToProducedMaterial$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addWasteAssignmentToProducedMaterial(params: AddWasteAssignmentToProducedMaterial$Params, context?: HttpContext): Observable<void> {
    return this.addWasteAssignmentToProducedMaterial$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `deleteWasteAssignmentFromProducedMaterial()` */
  static readonly DeleteWasteAssignmentFromProducedMaterialPath =
    '/api/v1/Containers/{producedMaterialId}/DeleteWasteAssignmentFromProducedMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWasteAssignmentFromProducedMaterial()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteWasteAssignmentFromProducedMaterial$Response(
    params: DeleteWasteAssignmentFromProducedMaterial$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return deleteWasteAssignmentFromProducedMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWasteAssignmentFromProducedMaterial$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteWasteAssignmentFromProducedMaterial(
    params: DeleteWasteAssignmentFromProducedMaterial$Params,
    context?: HttpContext
  ): Observable<void> {
    return this.deleteWasteAssignmentFromProducedMaterial$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `changeContainerQuantity()` */
  static readonly ChangeContainerQuantityPath = '/api/v1/Containers/{producedMaterialId}/ChangeContainerQuantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeContainerQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeContainerQuantity$Response(params: ChangeContainerQuantity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeContainerQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeContainerQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeContainerQuantity(params: ChangeContainerQuantity$Params, context?: HttpContext): Observable<void> {
    return this.changeContainerQuantity$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `changeContainerBobbinQuantity()` */
  static readonly ChangeContainerBobbinQuantityPath = '/api/v1/Containers/{producedMaterialId}/ChangeContainerBobbinQuantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeContainerBobbinQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeContainerBobbinQuantity$Response(
    params: ChangeContainerBobbinQuantity$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return changeContainerBobbinQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeContainerBobbinQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeContainerBobbinQuantity(params: ChangeContainerBobbinQuantity$Params, context?: HttpContext): Observable<void> {
    return this.changeContainerBobbinQuantity$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setContainerTargetQuantity()` */
  static readonly SetContainerTargetQuantityPath = '/api/v1/Containers/{productionOrderId}/SetContainerTargetQuantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setContainerTargetQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setContainerTargetQuantity$Response(
    params: SetContainerTargetQuantity$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return setContainerTargetQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setContainerTargetQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setContainerTargetQuantity(params: SetContainerTargetQuantity$Params, context?: HttpContext): Observable<void> {
    return this.setContainerTargetQuantity$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setContainerTargetBobbinQuantity()` */
  static readonly SetContainerTargetBobbinQuantityPath = '/api/v1/Containers/{productionOrderId}/SetContainerTargetBobbinQuantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setContainerTargetBobbinQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setContainerTargetBobbinQuantity$Response(
    params: SetContainerTargetBobbinQuantity$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return setContainerTargetBobbinQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setContainerTargetBobbinQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setContainerTargetBobbinQuantity(params: SetContainerTargetBobbinQuantity$Params, context?: HttpContext): Observable<void> {
    return this.setContainerTargetBobbinQuantity$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `switchManualProductionOrder()` */
  static readonly SwitchManualProductionOrderPath = '/api/v1/Containers/{productionOrderId}/SwitchManualProductionOrder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `switchManualProductionOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  switchManualProductionOrder$Response(
    params: SwitchManualProductionOrder$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return switchManualProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `switchManualProductionOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  switchManualProductionOrder(params: SwitchManualProductionOrder$Params, context?: HttpContext): Observable<void> {
    return this.switchManualProductionOrder$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getContainer()` */
  static readonly GetContainerPath = '/api/v1/Containers/{containerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContainer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContainer$Response(params: GetContainer$Params, context?: HttpContext): Observable<StrictHttpResponse<Container>> {
    return getContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContainer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContainer(params: GetContainer$Params, context?: HttpContext): Observable<Container> {
    return this.getContainer$Response(params, context).pipe(map((r: StrictHttpResponse<Container>): Container => r.body));
  }

  /** Path part for operation `getProducedMaterialList()` */
  static readonly GetProducedMaterialListPath = '/api/v1/Containers/{productionOrderId}/ProducedMaterials';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProducedMaterialList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProducedMaterialList$Response(
    params: GetProducedMaterialList$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProducedMaterialsViewModel>> {
    return getProducedMaterialList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProducedMaterialList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProducedMaterialList(params: GetProducedMaterialList$Params, context?: HttpContext): Observable<ProducedMaterialsViewModel> {
    return this.getProducedMaterialList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProducedMaterialsViewModel>): ProducedMaterialsViewModel => r.body)
    );
  }

  /** Path part for operation `getManualContainerCreationData()` */
  static readonly GetManualContainerCreationDataPath = '/api/v1/Containers/{productionOrderId}/GetManualContainerCreationData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualContainerCreationData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualContainerCreationData$Response(
    params: GetManualContainerCreationData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreateManualPalletData>> {
    return getManualContainerCreationData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getManualContainerCreationData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualContainerCreationData(params: GetManualContainerCreationData$Params, context?: HttpContext): Observable<CreateManualPalletData> {
    return this.getManualContainerCreationData$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateManualPalletData>): CreateManualPalletData => r.body)
    );
  }

  /** Path part for operation `getInputContainerList()` */
  static readonly GetInputContainerListPath = '/api/v1/Containers/{workCenterId}/InputContainers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInputContainerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInputContainerList$Response(
    params: GetInputContainerList$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PrimaryMaterialMounted>>> {
    return getInputContainerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInputContainerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInputContainerList(params: GetInputContainerList$Params, context?: HttpContext): Observable<Array<PrimaryMaterialMounted>> {
    return this.getInputContainerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PrimaryMaterialMounted>>): Array<PrimaryMaterialMounted> => r.body)
    );
  }
}
