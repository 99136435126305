import { Component, EventEmitter, Input, Optional, Output, SkipSelf } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'lib-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss']
})
export class ModalFooterComponent {
  @Input() public submitLabel = 'MODAL_FOOTER.SAVE';
  @Input() public submitDisabled = false;
  @Input() public declineLabel = 'MODAL_FOOTER.CANCEL';
  @Input() public showDeclineButton = true;
  @Input() public whenLoadingTopic? = '';
  @Input() public resetButtonLoadingTopic? = '';
  @Input() public submitId = 'submit_modal';
  @Input() public checkBoxLabel = 'MODAL_FOOTER.WARNING';
  @Input() public checkBox = false;
  @Input() public checkBoxCommand = false;
  @Input() public isFooterButtonSwapped? = false;
  @Input() public isResetVisible? = false;
  @Input() public isAutoFocusOnSubmitButton? = true;

  @Output() public submitClicked = new EventEmitter<void>();
  @Output() public declineClicked = new EventEmitter<void>();
  @Output() public checkBoxClick = new EventEmitter<boolean>();
  @Output() public resetClicked = new EventEmitter<void>();

  public resetLabel? = 'MODAL_FOOTER.RESET';

  constructor(@SkipSelf() @Optional() private config: DynamicDialogConfig) {}

  public onSubmit(): void {
    this.submitClicked.emit();
  }

  public onDecline(): void {
    this.declineClicked.emit();
  }

  public checkBoxClicked(checkBoxvValue: boolean): void {
    this.checkBoxClick.emit(checkBoxvValue);
  }

  public onReset(): void {
    this.resetClicked.emit();
  }

  public onLoading(isLoading: boolean): void {
    if (this.config) {
      this.config.closable = !isLoading;
    }
  }
}
