import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DowntimeReasonComponent } from '@bd/modules/downtime-reason/containers/downtime-reason.component';
import { WorkCenterComponent } from '@bd/modules/work-center/containers/work-center.component';
import { ShellComponent } from '@bd/shell/containers/shell/shell.component';
import { authorizeGuard } from 'chronos-shared';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    canActivate: [authorizeGuard],
    children: [
      { path: '', redirectTo: 'work-center', pathMatch: 'full' },
      { path: 'work-center', component: WorkCenterComponent, pathMatch: 'full' },
      { path: 'downtime-reason', component: DowntimeReasonComponent, pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ShellRoutingModule {}
