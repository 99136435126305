/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { containerForProductionOrder } from '../fn/pack-line/container-for-production-order';
import { ContainerForProductionOrder } from '../models/container-for-production-order';
import { ContainerForProductionOrder$Params } from '../fn/pack-line/container-for-production-order';
import { ContainerForProductionOrderResponse } from '../models/container-for-production-order-response';
import { createDefaultContainersForProductionOrder } from '../fn/pack-line/create-default-containers-for-production-order';
import { CreateDefaultContainersForProductionOrder$Params } from '../fn/pack-line/create-default-containers-for-production-order';
import { createExtraContainerForProductionOrder } from '../fn/pack-line/create-extra-container-for-production-order';
import { CreateExtraContainerForProductionOrder$Params } from '../fn/pack-line/create-extra-container-for-production-order';
import { finishContainer } from '../fn/pack-line/finish-container';
import { FinishContainer$Params } from '../fn/pack-line/finish-container';

@Injectable({ providedIn: 'root' })
export class PackLineService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createDefaultContainersForProductionOrder()` */
  static readonly CreateDefaultContainersForProductionOrderPath =
    '/api/v1/PackLine/productionOrders/{externalProductionOrderId}/createDefaultContainers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDefaultContainersForProductionOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDefaultContainersForProductionOrder$Response(
    params: CreateDefaultContainersForProductionOrder$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContainerForProductionOrderResponse>> {
    return createDefaultContainersForProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDefaultContainersForProductionOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDefaultContainersForProductionOrder(
    params: CreateDefaultContainersForProductionOrder$Params,
    context?: HttpContext
  ): Observable<ContainerForProductionOrderResponse> {
    return this.createDefaultContainersForProductionOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContainerForProductionOrderResponse>): ContainerForProductionOrderResponse => r.body)
    );
  }

  /** Path part for operation `createExtraContainerForProductionOrder()` */
  static readonly CreateExtraContainerForProductionOrderPath =
    '/api/v1/PackLine/productionOrders/{externalProductionOrderId}/createExtraContainers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExtraContainerForProductionOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  createExtraContainerForProductionOrder$Response(
    params: CreateExtraContainerForProductionOrder$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContainerForProductionOrderResponse>> {
    return createExtraContainerForProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExtraContainerForProductionOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createExtraContainerForProductionOrder(
    params: CreateExtraContainerForProductionOrder$Params,
    context?: HttpContext
  ): Observable<ContainerForProductionOrderResponse> {
    return this.createExtraContainerForProductionOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContainerForProductionOrderResponse>): ContainerForProductionOrderResponse => r.body)
    );
  }

  /** Path part for operation `finishContainer()` */
  static readonly FinishContainerPath = '/api/v1/PackLine/productionOrders/{externalProductionOrderId}/finishContainer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finishContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finishContainer$Response(params: FinishContainer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return finishContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finishContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finishContainer(params: FinishContainer$Params, context?: HttpContext): Observable<void> {
    return this.finishContainer$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `containerForProductionOrder()` */
  static readonly ContainerForProductionOrderPath = '/api/v1/PackLine/productionOrders/{externalProductionOrderId}/containers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `containerForProductionOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  containerForProductionOrder$Response(
    params: ContainerForProductionOrder$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContainerForProductionOrder>> {
    return containerForProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `containerForProductionOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  containerForProductionOrder(params: ContainerForProductionOrder$Params, context?: HttpContext): Observable<ContainerForProductionOrder> {
    return this.containerForProductionOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContainerForProductionOrder>): ContainerForProductionOrder => r.body)
    );
  }
}
