/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FrontendConfig } from '../models/frontend-config';
import { get } from '../fn/frontend-config/get';
import { Get$Params } from '../fn/frontend-config/get';

@Injectable({ providedIn: 'root' })
export class FrontendConfigService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `get()` */
  static readonly GetPath = '/api/v1/FrontendConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params?: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<FrontendConfig>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params?: Get$Params, context?: HttpContext): Observable<FrontendConfig> {
    return this.get$Response(params, context).pipe(map((r: StrictHttpResponse<FrontendConfig>): FrontendConfig => r.body));
  }
}
