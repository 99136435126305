/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { changeInfo } from '../fn/virtual-container/change-info';
import { ChangeInfo$Params } from '../fn/virtual-container/change-info';
import { getVirtualContainerReasons } from '../fn/virtual-container/get-virtual-container-reasons';
import { GetVirtualContainerReasons$Params } from '../fn/virtual-container/get-virtual-container-reasons';
import { VirtualContainerReason } from '../models/virtual-container-reason';

@Injectable({ providedIn: 'root' })
export class VirtualContainerService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `changeInfo()` */
  static readonly ChangeInfoPath = '/api/v1/VirtualContainer/{containerId}/ChangeInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeInfo$Response(params: ChangeInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeInfo(params: ChangeInfo$Params, context?: HttpContext): Observable<void> {
    return this.changeInfo$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getVirtualContainerReasons()` */
  static readonly GetVirtualContainerReasonsPath = '/api/v1/VirtualContainer/Reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVirtualContainerReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVirtualContainerReasons$Response(
    params?: GetVirtualContainerReasons$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VirtualContainerReason>>> {
    return getVirtualContainerReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVirtualContainerReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVirtualContainerReasons(params?: GetVirtualContainerReasons$Params, context?: HttpContext): Observable<Array<VirtualContainerReason>> {
    return this.getVirtualContainerReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VirtualContainerReason>>): Array<VirtualContainerReason> => r.body)
    );
  }
}
