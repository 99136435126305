import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AttentionBadgeComponent } from './attention-badge/attention-badge.component';
import { MenuContainerComponent } from './menu-container/menu-container.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MenuContainerComponent, MenuItemComponent, AttentionBadgeComponent],
  exports: [MenuItemComponent, MenuContainerComponent, AttentionBadgeComponent],
  imports: [CommonModule, TranslateModule]
})
export class MainMenuNavigationModule {}
