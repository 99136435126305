/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MetamodelMe } from '../../models/metamodel-me';

export interface GetMetaModelLevel$Params {
  orid?: number;
  level?: number;
}

export function getMetaModelLevel(
  http: HttpClient,
  rootUrl: string,
  params?: GetMetaModelLevel$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<MetamodelMe>> {
  const rb = new RequestBuilder(rootUrl, getMetaModelLevel.PATH, 'get');
  if (params) {
    rb.query('orid', params.orid, {});
    rb.query('level', params.level, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MetamodelMe>;
    })
  );
}

getMetaModelLevel.PATH = '/api/MetaModel/bylevel';
