<p-multiSelect
  [displaySelectedLabel]="true"
  [maxSelectedLabels]="20"
  [options]="currentOptions"
  [style]="inlineStyle"
  [panelStyle]="panelStyle"
  [(ngModel)]="selectedValues"
  (ngModelChange)="onModelChange()"
  [disabled]="disabled"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [filter]="filter"
  (onBlur)="onBlur()"
  [inputId]="inputId"
>
  <ng-template pTemplate="header">
    <div class="checkbox-all-text">{{ 'MULTISELECT_ALL_LABEL' | translate }}</div>
  </ng-template>
</p-multiSelect>
