/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root'
})
export class CoreApiConfiguration {
  rootUrl: string = '';
}

/**
 * Parameters for `CoreApiModule.forRoot()`
 */
export interface CoreApiConfigurationParams {
  rootUrl?: string;
}
