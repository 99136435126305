/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addWidgetConfiguration } from '../fn/dashboard-management/add-widget-configuration';
import { AddWidgetConfiguration$Params } from '../fn/dashboard-management/add-widget-configuration';
import { deleteWidgetConfiguration } from '../fn/dashboard-management/delete-widget-configuration';
import { DeleteWidgetConfiguration$Params } from '../fn/dashboard-management/delete-widget-configuration';
import { getDashboardManagementUsers } from '../fn/dashboard-management/get-dashboard-management-users';
import { GetDashboardManagementUsers$Params } from '../fn/dashboard-management/get-dashboard-management-users';
import { getOrganizations } from '../fn/dashboard-management/get-organizations';
import { GetOrganizations$Params } from '../fn/dashboard-management/get-organizations';
import { getWidgetAttributes } from '../fn/dashboard-management/get-widget-attributes';
import { GetWidgetAttributes$Params } from '../fn/dashboard-management/get-widget-attributes';
import { getWidgetAttributesWithValues } from '../fn/dashboard-management/get-widget-attributes-with-values';
import { GetWidgetAttributesWithValues$Params } from '../fn/dashboard-management/get-widget-attributes-with-values';
import { getWidgetConfigDetails } from '../fn/dashboard-management/get-widget-config-details';
import { GetWidgetConfigDetails$Params } from '../fn/dashboard-management/get-widget-config-details';
import { getWidgetConfiguration } from '../fn/dashboard-management/get-widget-configuration';
import { GetWidgetConfiguration$Params } from '../fn/dashboard-management/get-widget-configuration';
import { getWidgetConfigurationDetails } from '../fn/dashboard-management/get-widget-configuration-details';
import { GetWidgetConfigurationDetails$Params } from '../fn/dashboard-management/get-widget-configuration-details';
import { getWidgets } from '../fn/dashboard-management/get-widgets';
import { GetWidgets$Params } from '../fn/dashboard-management/get-widgets';
import { getWidgetTiles } from '../fn/dashboard-management/get-widget-tiles';
import { GetWidgetTiles$Params } from '../fn/dashboard-management/get-widget-tiles';
import { OrganizationOr } from '../models/organization-or';
import { Tile } from '../models/tile';
import { updateWidgetConfiguration } from '../fn/dashboard-management/update-widget-configuration';
import { UpdateWidgetConfiguration$Params } from '../fn/dashboard-management/update-widget-configuration';
import { updateWidgetConfigurationRange } from '../fn/dashboard-management/update-widget-configuration-range';
import { UpdateWidgetConfigurationRange$Params } from '../fn/dashboard-management/update-widget-configuration-range';
import { UserUsDto } from '../models/user-us-dto';
import { UwSettings } from '../models/uw-settings';
import { UxwidgetconfigWc } from '../models/uxwidgetconfig-wc';
import { UxwidgetUw } from '../models/uxwidget-uw';
import { WidgetList } from '../models/widget-list';
import { WidgetSettings } from '../models/widget-settings';

@Injectable({ providedIn: 'root' })
export class DashboardManagementService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOrganizations()` */
  static readonly GetOrganizationsPath = '/api/DashboardManagement/getOrganizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations$Response(
    params?: GetOrganizations$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationOr>>> {
    return getOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations(params?: GetOrganizations$Params, context?: HttpContext): Observable<Array<OrganizationOr>> {
    return this.getOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationOr>>): Array<OrganizationOr> => r.body)
    );
  }

  /** Path part for operation `getDashboardManagementUsers()` */
  static readonly GetDashboardManagementUsersPath = '/api/DashboardManagement/getDashboardManagementUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDashboardManagementUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDashboardManagementUsers$Response(
    params?: GetDashboardManagementUsers$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserUsDto>>> {
    return getDashboardManagementUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDashboardManagementUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDashboardManagementUsers(params?: GetDashboardManagementUsers$Params, context?: HttpContext): Observable<Array<UserUsDto>> {
    return this.getDashboardManagementUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserUsDto>>): Array<UserUsDto> => r.body)
    );
  }

  /** Path part for operation `getWidgetConfiguration()` */
  static readonly GetWidgetConfigurationPath = '/api/DashboardManagement/getWidgetConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgetConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetConfiguration$Response(
    params?: GetWidgetConfiguration$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<WidgetSettings>> {
    return getWidgetConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWidgetConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetConfiguration(params?: GetWidgetConfiguration$Params, context?: HttpContext): Observable<WidgetSettings> {
    return this.getWidgetConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<WidgetSettings>): WidgetSettings => r.body)
    );
  }

  /** Path part for operation `getWidgetTiles()` */
  static readonly GetWidgetTilesPath = '/api/DashboardManagement/getWidgetTiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgetTiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetTiles$Response(params?: GetWidgetTiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Tile>>> {
    return getWidgetTiles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWidgetTiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetTiles(params?: GetWidgetTiles$Params, context?: HttpContext): Observable<Array<Tile>> {
    return this.getWidgetTiles$Response(params, context).pipe(map((r: StrictHttpResponse<Array<Tile>>): Array<Tile> => r.body));
  }

  /** Path part for operation `addWidgetConfiguration()` */
  static readonly AddWidgetConfigurationPath = '/api/DashboardManagement/addWidgetConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addWidgetConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addWidgetConfiguration$Response(params: AddWidgetConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return addWidgetConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addWidgetConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addWidgetConfiguration(params: AddWidgetConfiguration$Params, context?: HttpContext): Observable<string> {
    return this.addWidgetConfiguration$Response(params, context).pipe(map((r: StrictHttpResponse<string>): string => r.body));
  }

  /** Path part for operation `updateWidgetConfiguration()` */
  static readonly UpdateWidgetConfigurationPath = '/api/DashboardManagement/updateWidgetConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWidgetConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWidgetConfiguration$Response(
    params: UpdateWidgetConfiguration$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    return updateWidgetConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWidgetConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWidgetConfiguration(params: UpdateWidgetConfiguration$Params, context?: HttpContext): Observable<number> {
    return this.updateWidgetConfiguration$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `updateWidgetConfigurationRange()` */
  static readonly UpdateWidgetConfigurationRangePath = '/api/DashboardManagement/update/widgetconfigurationrange';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWidgetConfigurationRange()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWidgetConfigurationRange$Response(
    params: UpdateWidgetConfigurationRange$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    return updateWidgetConfigurationRange(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWidgetConfigurationRange$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWidgetConfigurationRange(params: UpdateWidgetConfigurationRange$Params, context?: HttpContext): Observable<number> {
    return this.updateWidgetConfigurationRange$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `deleteWidgetConfiguration()` */
  static readonly DeleteWidgetConfigurationPath = '/api/DashboardManagement/deleteWidgetConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWidgetConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWidgetConfiguration$Response(
    params?: DeleteWidgetConfiguration$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    return deleteWidgetConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWidgetConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWidgetConfiguration(params?: DeleteWidgetConfiguration$Params, context?: HttpContext): Observable<number> {
    return this.deleteWidgetConfiguration$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `getWidgets()` */
  static readonly GetWidgetsPath = '/api/DashboardManagement/getWidgets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgets$Response(params?: GetWidgets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UxwidgetUw>>> {
    return getWidgets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWidgets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgets(params?: GetWidgets$Params, context?: HttpContext): Observable<Array<UxwidgetUw>> {
    return this.getWidgets$Response(params, context).pipe(map((r: StrictHttpResponse<Array<UxwidgetUw>>): Array<UxwidgetUw> => r.body));
  }

  /** Path part for operation `getWidgetConfigDetails()` */
  static readonly GetWidgetConfigDetailsPath = '/api/DashboardManagement/getWidgetConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgetConfigDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetConfigDetails$Response(
    params?: GetWidgetConfigDetails$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WidgetList>>> {
    return getWidgetConfigDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWidgetConfigDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetConfigDetails(params?: GetWidgetConfigDetails$Params, context?: HttpContext): Observable<Array<WidgetList>> {
    return this.getWidgetConfigDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WidgetList>>): Array<WidgetList> => r.body)
    );
  }

  /** Path part for operation `getWidgetAttributes()` */
  static readonly GetWidgetAttributesPath = '/api/DashboardManagement/getWidgetAttributes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgetAttributes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetAttributes$Response(
    params?: GetWidgetAttributes$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UwSettings>>> {
    return getWidgetAttributes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWidgetAttributes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetAttributes(params?: GetWidgetAttributes$Params, context?: HttpContext): Observable<Array<UwSettings>> {
    return this.getWidgetAttributes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UwSettings>>): Array<UwSettings> => r.body)
    );
  }

  /** Path part for operation `getWidgetAttributesWithValues()` */
  static readonly GetWidgetAttributesWithValuesPath = '/api/DashboardManagement/GetWidgetAttributesWithValues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgetAttributesWithValues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetAttributesWithValues$Response(
    params?: GetWidgetAttributesWithValues$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UwSettings>>> {
    return getWidgetAttributesWithValues(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWidgetAttributesWithValues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetAttributesWithValues(params?: GetWidgetAttributesWithValues$Params, context?: HttpContext): Observable<Array<UwSettings>> {
    return this.getWidgetAttributesWithValues$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UwSettings>>): Array<UwSettings> => r.body)
    );
  }

  /** Path part for operation `getWidgetConfigurationDetails()` */
  static readonly GetWidgetConfigurationDetailsPath = '/api/DashboardManagement/GetWidgetConfigurationDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgetConfigurationDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetConfigurationDetails$Response(
    params?: GetWidgetConfigurationDetails$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UxwidgetconfigWc>>> {
    return getWidgetConfigurationDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWidgetConfigurationDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgetConfigurationDetails(params?: GetWidgetConfigurationDetails$Params, context?: HttpContext): Observable<Array<UxwidgetconfigWc>> {
    return this.getWidgetConfigurationDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UxwidgetconfigWc>>): Array<UxwidgetconfigWc> => r.body)
    );
  }
}
