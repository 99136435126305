/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getChildNavigation } from '../fn/navigation/get-child-navigation';
import { GetChildNavigation$Params } from '../fn/navigation/get-child-navigation';
import { getNavigation } from '../fn/navigation/get-navigation';
import { GetNavigation$Params } from '../fn/navigation/get-navigation';
import { UxpageUp } from '../models/uxpage-up';
import { validateNavigation } from '../fn/navigation/validate-navigation';
import { ValidateNavigation$Params } from '../fn/navigation/validate-navigation';

@Injectable({ providedIn: 'root' })
export class NavigationService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getNavigation()` */
  static readonly GetNavigationPath = '/api/Navigation/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNavigation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNavigation$Response(params?: GetNavigation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UxpageUp>>> {
    return getNavigation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNavigation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNavigation(params?: GetNavigation$Params, context?: HttpContext): Observable<Array<UxpageUp>> {
    return this.getNavigation$Response(params, context).pipe(map((r: StrictHttpResponse<Array<UxpageUp>>): Array<UxpageUp> => r.body));
  }

  /** Path part for operation `getChildNavigation()` */
  static readonly GetChildNavigationPath = '/api/Navigation/list/child';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChildNavigation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChildNavigation$Response(params?: GetChildNavigation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UxpageUp>>> {
    return getChildNavigation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChildNavigation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChildNavigation(params?: GetChildNavigation$Params, context?: HttpContext): Observable<Array<UxpageUp>> {
    return this.getChildNavigation$Response(params, context).pipe(map((r: StrictHttpResponse<Array<UxpageUp>>): Array<UxpageUp> => r.body));
  }

  /** Path part for operation `validateNavigation()` */
  static readonly ValidateNavigationPath = '/api/Navigation/validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateNavigation()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateNavigation$Response(params?: ValidateNavigation$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return validateNavigation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateNavigation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateNavigation(params?: ValidateNavigation$Params, context?: HttpContext): Observable<boolean> {
    return this.validateNavigation$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }
}
