/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getGridMasterDetails } from '../fn/grid-settings/get-grid-master-details';
import { GetGridMasterDetails$Params } from '../fn/grid-settings/get-grid-master-details';
import { getGridSettingsByUser } from '../fn/grid-settings/get-grid-settings-by-user';
import { GetGridSettingsByUser$Params } from '../fn/grid-settings/get-grid-settings-by-user';
import { getGridSettingTypesByUser } from '../fn/grid-settings/get-grid-setting-types-by-user';
import { GetGridSettingTypesByUser$Params } from '../fn/grid-settings/get-grid-setting-types-by-user';
import { GridMasterGm } from '../models/grid-master-gm';
import { GridSettings } from '../models/grid-settings';
import { GridSettingsGs } from '../models/grid-settings-gs';
import { isUserAdminRole } from '../fn/grid-settings/is-user-admin-role';
import { IsUserAdminRole$Params } from '../fn/grid-settings/is-user-admin-role';
import { isUserSettingPresent } from '../fn/grid-settings/is-user-setting-present';
import { IsUserSettingPresent$Params } from '../fn/grid-settings/is-user-setting-present';
import { saveGridSettings } from '../fn/grid-settings/save-grid-settings';
import { SaveGridSettings$Params } from '../fn/grid-settings/save-grid-settings';

@Injectable({ providedIn: 'root' })
export class GridSettingsService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getGridMasterDetails()` */
  static readonly GetGridMasterDetailsPath = '/api/GridSettings/getGridMasterDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGridMasterDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGridMasterDetails$Response(
    params?: GetGridMasterDetails$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GridMasterGm>>> {
    return getGridMasterDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGridMasterDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGridMasterDetails(params?: GetGridMasterDetails$Params, context?: HttpContext): Observable<Array<GridMasterGm>> {
    return this.getGridMasterDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GridMasterGm>>): Array<GridMasterGm> => r.body)
    );
  }

  /** Path part for operation `getGridSettingsByUser()` */
  static readonly GetGridSettingsByUserPath = '/api/GridSettings/getGridSettingsByUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGridSettingsByUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGridSettingsByUser$Response(
    params?: GetGridSettingsByUser$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<GridSettings>> {
    return getGridSettingsByUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGridSettingsByUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGridSettingsByUser(params?: GetGridSettingsByUser$Params, context?: HttpContext): Observable<GridSettings> {
    return this.getGridSettingsByUser$Response(params, context).pipe(map((r: StrictHttpResponse<GridSettings>): GridSettings => r.body));
  }

  /** Path part for operation `getGridSettingTypesByUser()` */
  static readonly GetGridSettingTypesByUserPath = '/api/GridSettings/getGridSettingTypesByUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGridSettingTypesByUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGridSettingTypesByUser$Response(
    params?: GetGridSettingTypesByUser$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GridSettingsGs>>> {
    return getGridSettingTypesByUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGridSettingTypesByUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGridSettingTypesByUser(params?: GetGridSettingTypesByUser$Params, context?: HttpContext): Observable<Array<GridSettingsGs>> {
    return this.getGridSettingTypesByUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GridSettingsGs>>): Array<GridSettingsGs> => r.body)
    );
  }

  /** Path part for operation `isUserAdminRole()` */
  static readonly IsUserAdminRolePath = '/api/GridSettings/isUserAdminRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isUserAdminRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  isUserAdminRole$Response(params?: IsUserAdminRole$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isUserAdminRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isUserAdminRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isUserAdminRole(params?: IsUserAdminRole$Params, context?: HttpContext): Observable<boolean> {
    return this.isUserAdminRole$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `isUserSettingPresent()` */
  static readonly IsUserSettingPresentPath = '/api/GridSettings/isUserSettingPresent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isUserSettingPresent()` instead.
   *
   * This method doesn't expect any request body.
   */
  isUserSettingPresent$Response(params?: IsUserSettingPresent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isUserSettingPresent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isUserSettingPresent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isUserSettingPresent(params?: IsUserSettingPresent$Params, context?: HttpContext): Observable<boolean> {
    return this.isUserSettingPresent$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `saveGridSettings()` */
  static readonly SaveGridSettingsPath = '/api/GridSettings/saveGridSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveGridSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveGridSettings$Response(params: SaveGridSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return saveGridSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveGridSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveGridSettings(params: SaveGridSettings$Params, context?: HttpContext): Observable<number> {
    return this.saveGridSettings$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }
}
