/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BackwardTraceBomLine } from '../models/backward-trace-bom-line';
import { BackwardTraceExport } from '../models/backward-trace-export';
import { createTraceabilityData } from '../fn/traceability/create-traceability-data';
import { CreateTraceabilityData$Params } from '../fn/traceability/create-traceability-data';
import { ForwardTraceExport } from '../models/forward-trace-export';
import { ForwardTraceOrder } from '../models/forward-trace-order';
import { getMountedMaterialsForForwardSearch } from '../fn/traceability/get-mounted-materials-for-forward-search';
import { GetMountedMaterialsForForwardSearch$Params } from '../fn/traceability/get-mounted-materials-for-forward-search';
import { getMountedMaterialTraceForForwardSearch } from '../fn/traceability/get-mounted-material-trace-for-forward-search';
import { GetMountedMaterialTraceForForwardSearch$Params } from '../fn/traceability/get-mounted-material-trace-for-forward-search';
import { getProductionOrdersForBackwardSearch } from '../fn/traceability/get-production-orders-for-backward-search';
import { GetProductionOrdersForBackwardSearch$Params } from '../fn/traceability/get-production-orders-for-backward-search';
import { getProductionOrderTraceForBackwardSearch } from '../fn/traceability/get-production-order-trace-for-backward-search';
import { GetProductionOrderTraceForBackwardSearch$Params } from '../fn/traceability/get-production-order-trace-for-backward-search';
import { getSimpleBackwardSearch } from '../fn/traceability/get-simple-backward-search';
import { GetSimpleBackwardSearch$Params } from '../fn/traceability/get-simple-backward-search';
import { getSimpleForwardSearch } from '../fn/traceability/get-simple-forward-search';
import { GetSimpleForwardSearch$Params } from '../fn/traceability/get-simple-forward-search';
import { getTraceabilityBackwardSearch } from '../fn/traceability/get-traceability-backward-search';
import { GetTraceabilityBackwardSearch$Params } from '../fn/traceability/get-traceability-backward-search';
import { getTraceabilityBackwardSearchExport } from '../fn/traceability/get-traceability-backward-search-export';
import { GetTraceabilityBackwardSearchExport$Params } from '../fn/traceability/get-traceability-backward-search-export';
import { getTraceabilityForwardSearch } from '../fn/traceability/get-traceability-forward-search';
import { GetTraceabilityForwardSearch$Params } from '../fn/traceability/get-traceability-forward-search';
import { getTraceabilityForwardSearchExport } from '../fn/traceability/get-traceability-forward-search-export';
import { GetTraceabilityForwardSearchExport$Params } from '../fn/traceability/get-traceability-forward-search-export';
import { MountedMaterialForwardSearch } from '../models/mounted-material-forward-search';
import { MountedMaterialForwardSearchTrace } from '../models/mounted-material-forward-search-trace';
import { ProductionOrderBackwardSearch } from '../models/production-order-backward-search';
import { ProductionOrderBackwardSearchTrace } from '../models/production-order-backward-search-trace';

@Injectable({ providedIn: 'root' })
export class TraceabilityService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createTraceabilityData()` */
  static readonly CreateTraceabilityDataPath = '/api/v1/{workCenterId}/{runId}/CreateTraceabilityData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTraceabilityData()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTraceabilityData$Response(params: CreateTraceabilityData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createTraceabilityData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTraceabilityData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTraceabilityData(params: CreateTraceabilityData$Params, context?: HttpContext): Observable<void> {
    return this.createTraceabilityData$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getTraceabilityForwardSearch()` */
  static readonly GetTraceabilityForwardSearchPath = '/api/v1/getTraceabilityForwardSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTraceabilityForwardSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceabilityForwardSearch$Response(
    params?: GetTraceabilityForwardSearch$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ForwardTraceOrder>>> {
    return getTraceabilityForwardSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTraceabilityForwardSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceabilityForwardSearch(params?: GetTraceabilityForwardSearch$Params, context?: HttpContext): Observable<Array<ForwardTraceOrder>> {
    return this.getTraceabilityForwardSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ForwardTraceOrder>>): Array<ForwardTraceOrder> => r.body)
    );
  }

  /** Path part for operation `getTraceabilityBackwardSearch()` */
  static readonly GetTraceabilityBackwardSearchPath = '/api/v1/getTraceabilityBackwardSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTraceabilityBackwardSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceabilityBackwardSearch$Response(
    params?: GetTraceabilityBackwardSearch$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BackwardTraceBomLine>>> {
    return getTraceabilityBackwardSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTraceabilityBackwardSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceabilityBackwardSearch(
    params?: GetTraceabilityBackwardSearch$Params,
    context?: HttpContext
  ): Observable<Array<BackwardTraceBomLine>> {
    return this.getTraceabilityBackwardSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BackwardTraceBomLine>>): Array<BackwardTraceBomLine> => r.body)
    );
  }

  /** Path part for operation `getMountedMaterialsForForwardSearch()` */
  static readonly GetMountedMaterialsForForwardSearchPath = '/api/v1/getMountedMaterialsForForwardSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMountedMaterialsForForwardSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMountedMaterialsForForwardSearch$Response(
    params?: GetMountedMaterialsForForwardSearch$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MountedMaterialForwardSearch>>> {
    return getMountedMaterialsForForwardSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMountedMaterialsForForwardSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMountedMaterialsForForwardSearch(
    params?: GetMountedMaterialsForForwardSearch$Params,
    context?: HttpContext
  ): Observable<Array<MountedMaterialForwardSearch>> {
    return this.getMountedMaterialsForForwardSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MountedMaterialForwardSearch>>): Array<MountedMaterialForwardSearch> => r.body)
    );
  }

  /** Path part for operation `getProductionOrdersForBackwardSearch()` */
  static readonly GetProductionOrdersForBackwardSearchPath = '/api/v1/getProductionOrdersForBackwardSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrdersForBackwardSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrdersForBackwardSearch$Response(
    params?: GetProductionOrdersForBackwardSearch$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProductionOrderBackwardSearch>>> {
    return getProductionOrdersForBackwardSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrdersForBackwardSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrdersForBackwardSearch(
    params?: GetProductionOrdersForBackwardSearch$Params,
    context?: HttpContext
  ): Observable<Array<ProductionOrderBackwardSearch>> {
    return this.getProductionOrdersForBackwardSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductionOrderBackwardSearch>>): Array<ProductionOrderBackwardSearch> => r.body)
    );
  }

  /** Path part for operation `getMountedMaterialTraceForForwardSearch()` */
  static readonly GetMountedMaterialTraceForForwardSearchPath = '/api/v1/getMountedMaterialTraceForForwardSearch/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMountedMaterialTraceForForwardSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMountedMaterialTraceForForwardSearch$Response(
    params: GetMountedMaterialTraceForForwardSearch$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MountedMaterialForwardSearchTrace>>> {
    return getMountedMaterialTraceForForwardSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMountedMaterialTraceForForwardSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMountedMaterialTraceForForwardSearch(
    params: GetMountedMaterialTraceForForwardSearch$Params,
    context?: HttpContext
  ): Observable<Array<MountedMaterialForwardSearchTrace>> {
    return this.getMountedMaterialTraceForForwardSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MountedMaterialForwardSearchTrace>>): Array<MountedMaterialForwardSearchTrace> => r.body)
    );
  }

  /** Path part for operation `getProductionOrderTraceForBackwardSearch()` */
  static readonly GetProductionOrderTraceForBackwardSearchPath = '/api/v1/getProductionOrderTraceForBackwardSearch/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderTraceForBackwardSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderTraceForBackwardSearch$Response(
    params: GetProductionOrderTraceForBackwardSearch$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProductionOrderBackwardSearchTrace>>> {
    return getProductionOrderTraceForBackwardSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderTraceForBackwardSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderTraceForBackwardSearch(
    params: GetProductionOrderTraceForBackwardSearch$Params,
    context?: HttpContext
  ): Observable<Array<ProductionOrderBackwardSearchTrace>> {
    return this.getProductionOrderTraceForBackwardSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductionOrderBackwardSearchTrace>>): Array<ProductionOrderBackwardSearchTrace> => r.body)
    );
  }

  /** Path part for operation `getSimpleForwardSearch()` */
  static readonly GetSimpleForwardSearchPath = '/api/v1/getSimpleForwardSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleForwardSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleForwardSearch$Response(
    params?: GetSimpleForwardSearch$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ForwardTraceOrder>>> {
    return getSimpleForwardSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimpleForwardSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleForwardSearch(params?: GetSimpleForwardSearch$Params, context?: HttpContext): Observable<Array<ForwardTraceOrder>> {
    return this.getSimpleForwardSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ForwardTraceOrder>>): Array<ForwardTraceOrder> => r.body)
    );
  }

  /** Path part for operation `getTraceabilityForwardSearchExport()` */
  static readonly GetTraceabilityForwardSearchExportPath = '/api/v1/getTraceabilityForwardSearchExport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTraceabilityForwardSearchExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceabilityForwardSearchExport$Response(
    params?: GetTraceabilityForwardSearchExport$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ForwardTraceExport>> {
    return getTraceabilityForwardSearchExport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTraceabilityForwardSearchExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceabilityForwardSearchExport(
    params?: GetTraceabilityForwardSearchExport$Params,
    context?: HttpContext
  ): Observable<ForwardTraceExport> {
    return this.getTraceabilityForwardSearchExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ForwardTraceExport>): ForwardTraceExport => r.body)
    );
  }

  /** Path part for operation `getTraceabilityBackwardSearchExport()` */
  static readonly GetTraceabilityBackwardSearchExportPath = '/api/v1/getTraceabilityBackwardSearchExport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTraceabilityBackwardSearchExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceabilityBackwardSearchExport$Response(
    params?: GetTraceabilityBackwardSearchExport$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<BackwardTraceExport>> {
    return getTraceabilityBackwardSearchExport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTraceabilityBackwardSearchExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceabilityBackwardSearchExport(
    params?: GetTraceabilityBackwardSearchExport$Params,
    context?: HttpContext
  ): Observable<BackwardTraceExport> {
    return this.getTraceabilityBackwardSearchExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<BackwardTraceExport>): BackwardTraceExport => r.body)
    );
  }

  /** Path part for operation `getSimpleBackwardSearch()` */
  static readonly GetSimpleBackwardSearchPath = '/api/v1/getSimpleBackwardSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleBackwardSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleBackwardSearch$Response(
    params?: GetSimpleBackwardSearch$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BackwardTraceBomLine>>> {
    return getSimpleBackwardSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimpleBackwardSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleBackwardSearch(params?: GetSimpleBackwardSearch$Params, context?: HttpContext): Observable<Array<BackwardTraceBomLine>> {
    return this.getSimpleBackwardSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BackwardTraceBomLine>>): Array<BackwardTraceBomLine> => r.body)
    );
  }
}
