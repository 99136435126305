/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assign } from '../fn/trace-material/assign';
import { Assign$Params } from '../fn/trace-material/assign';
import { createAndAssign } from '../fn/trace-material/create-and-assign';
import { CreateAndAssign$Params } from '../fn/trace-material/create-and-assign';
import { getMaterialAvailableForArticle2 } from '../fn/trace-material/get-material-available-for-article-2';
import { GetMaterialAvailableForArticle2$Params } from '../fn/trace-material/get-material-available-for-article-2';
import { getTraceMaterialAssigned } from '../fn/trace-material/get-trace-material-assigned';
import { GetTraceMaterialAssigned$Params } from '../fn/trace-material/get-trace-material-assigned';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
import { TraceMaterial } from '../models/trace-material';
import { unassign } from '../fn/trace-material/unassign';
import { Unassign$Params } from '../fn/trace-material/unassign';

@Injectable({ providedIn: 'root' })
export class TraceMaterialService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createAndAssign()` */
  static readonly CreateAndAssignPath = '/api/v1/TraceMaterial/{workCenterId}/CreateAndAssign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAndAssign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAndAssign$Response(params: CreateAndAssign$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createAndAssign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAndAssign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAndAssign(params: CreateAndAssign$Params, context?: HttpContext): Observable<void> {
    return this.createAndAssign$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `assign()` */
  static readonly AssignPath = '/api/v1/TraceMaterial/{workCenterId}/Assign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assign$Response(params: Assign$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return assign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assign(params: Assign$Params, context?: HttpContext): Observable<void> {
    return this.assign$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `unassign()` */
  static readonly UnassignPath = '/api/v1/TraceMaterial/{mountedMaterialId}/Unassign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassign$Response(params: Unassign$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unassign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unassign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassign(params: Unassign$Params, context?: HttpContext): Observable<void> {
    return this.unassign$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getMaterialAvailableForArticle2()` */
  static readonly GetMaterialAvailableForArticle2Path = '/api/v1/TraceMaterial/{workCenterId}/materialAvailable/articles/{articleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableForArticle2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForArticle2$Response(
    params: GetMaterialAvailableForArticle2$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableForArticle2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableForArticle2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForArticle2(
    params: GetMaterialAvailableForArticle2$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForArticle2$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getTraceMaterialAssigned()` */
  static readonly GetTraceMaterialAssignedPath = '/api/v1/TraceMaterial/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTraceMaterialAssigned()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceMaterialAssigned$Response(
    params: GetTraceMaterialAssigned$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<TraceMaterial>> {
    return getTraceMaterialAssigned(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTraceMaterialAssigned$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTraceMaterialAssigned(params: GetTraceMaterialAssigned$Params, context?: HttpContext): Observable<TraceMaterial> {
    return this.getTraceMaterialAssigned$Response(params, context).pipe(
      map((r: StrictHttpResponse<TraceMaterial>): TraceMaterial => r.body)
    );
  }
}
