import { Injectable } from '@angular/core';
import { Filters } from './workcenter.model';
import { WorkCenterStore } from './workcenter.store';
import { WorkCenterViewModel } from 'chronos-basedata-client';

@Injectable({ providedIn: 'root' })
export class WorkCenterService {
  constructor(private workCenterStore: WorkCenterStore) {}

  public updateWorkCenters(workCenter: WorkCenterViewModel[]): void {
    this.workCenterStore.setLoading(true);
    this.workCenterStore.set(workCenter);
    this.workCenterStore.setLoading(false);
  }

  public updateWorkCenterItem(updatedWorkCenter: Partial<WorkCenterViewModel>): void {
    this.workCenterStore.setLoading(true);
    this.workCenterStore.update(updatedWorkCenter);
    this.workCenterStore.setLoading(false);
  }

  public updateFilters(filters: Filters): void {
    this.workCenterStore.updateFilters(filters);
  }

  public resetFilters(): void {
    this.workCenterStore.resetFilters();
  }
}
