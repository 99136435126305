/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assignMultipleReasonsToDowntime } from '../fn/downtimes/assign-multiple-reasons-to-downtime';
import { AssignMultipleReasonsToDowntime$Params } from '../fn/downtimes/assign-multiple-reasons-to-downtime';
import { assignReasonToDowntime } from '../fn/downtimes/assign-reason-to-downtime';
import { AssignReasonToDowntime$Params } from '../fn/downtimes/assign-reason-to-downtime';
import { cleanup } from '../fn/downtimes/cleanup';
import { Cleanup$Params } from '../fn/downtimes/cleanup';
import { convertToProduction } from '../fn/downtimes/convert-to-production';
import { ConvertToProduction$Params } from '../fn/downtimes/convert-to-production';
import { Downtime } from '../models/downtime';
import { DowntimeReason } from '../models/downtime-reason';
import { DowntimeSection } from '../models/downtime-section';
import { editDowntime } from '../fn/downtimes/edit-downtime';
import { EditDowntime$Params } from '../fn/downtimes/edit-downtime';
import { EditDowntimeStatus } from '../models/edit-downtime-status';
import { getDowntime } from '../fn/downtimes/get-downtime';
import { GetDowntime$Params } from '../fn/downtimes/get-downtime';
import { getEditDowntimeStatus } from '../fn/downtimes/get-edit-downtime-status';
import { GetEditDowntimeStatus$Params } from '../fn/downtimes/get-edit-downtime-status';
import { getReasonsFlatList } from '../fn/downtimes/get-reasons-flat-list';
import { GetReasonsFlatList$Params } from '../fn/downtimes/get-reasons-flat-list';
import { getReasonsTreeList } from '../fn/downtimes/get-reasons-tree-list';
import { GetReasonsTreeList$Params } from '../fn/downtimes/get-reasons-tree-list';
import { getReasonsTreeListAll } from '../fn/downtimes/get-reasons-tree-list-all';
import { GetReasonsTreeListAll$Params } from '../fn/downtimes/get-reasons-tree-list-all';
import { revertEditDowntime } from '../fn/downtimes/revert-edit-downtime';
import { RevertEditDowntime$Params } from '../fn/downtimes/revert-edit-downtime';
import { splitDowntime } from '../fn/downtimes/split-downtime';
import { SplitDowntime$Params } from '../fn/downtimes/split-downtime';

@Injectable({ providedIn: 'root' })
export class DowntimesService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `assignReasonToDowntime()` */
  static readonly AssignReasonToDowntimePath = '/api/v1/Downtimes/{downtimeId}/AssignReason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignReasonToDowntime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignReasonToDowntime$Response(params: AssignReasonToDowntime$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return assignReasonToDowntime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignReasonToDowntime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignReasonToDowntime(params: AssignReasonToDowntime$Params, context?: HttpContext): Observable<void> {
    return this.assignReasonToDowntime$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `assignMultipleReasonsToDowntime()` */
  static readonly AssignMultipleReasonsToDowntimePath = '/api/v1/Downtimes/AssignMultipleReasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignMultipleReasonsToDowntime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignMultipleReasonsToDowntime$Response(
    params: AssignMultipleReasonsToDowntime$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return assignMultipleReasonsToDowntime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignMultipleReasonsToDowntime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignMultipleReasonsToDowntime(params: AssignMultipleReasonsToDowntime$Params, context?: HttpContext): Observable<void> {
    return this.assignMultipleReasonsToDowntime$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `splitDowntime()` */
  static readonly SplitDowntimePath = '/api/v1/Downtimes/{downtimeId}/SplitDowntime';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `splitDowntime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  splitDowntime$Response(params: SplitDowntime$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return splitDowntime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `splitDowntime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  splitDowntime(params: SplitDowntime$Params, context?: HttpContext): Observable<void> {
    return this.splitDowntime$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `editDowntime()` */
  static readonly EditDowntimePath = '/api/v1/Downtimes/{downtimeId}/EditDowntime';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editDowntime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editDowntime$Response(params: EditDowntime$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editDowntime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editDowntime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editDowntime(params: EditDowntime$Params, context?: HttpContext): Observable<void> {
    return this.editDowntime$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `revertEditDowntime()` */
  static readonly RevertEditDowntimePath = '/api/v1/Downtimes/{downtimeId}/RevertEditDowntime';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revertEditDowntime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revertEditDowntime$Response(params: RevertEditDowntime$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revertEditDowntime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revertEditDowntime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revertEditDowntime(params: RevertEditDowntime$Params, context?: HttpContext): Observable<void> {
    return this.revertEditDowntime$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `convertToProduction()` */
  static readonly ConvertToProductionPath = '/api/v1/Downtimes/{downtimeId}/ConvertToProduction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `convertToProduction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  convertToProduction$Response(params: ConvertToProduction$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return convertToProduction(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `convertToProduction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  convertToProduction(params: ConvertToProduction$Params, context?: HttpContext): Observable<void> {
    return this.convertToProduction$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `cleanup()` */
  static readonly CleanupPath = '/api/v1/Downtimes/{downtimeId}/Cleanup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cleanup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cleanup$Response(params: Cleanup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cleanup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cleanup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cleanup(params: Cleanup$Params, context?: HttpContext): Observable<void> {
    return this.cleanup$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getDowntime()` */
  static readonly GetDowntimePath = '/api/v1/Downtimes/{downtimeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDowntime()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDowntime$Response(params: GetDowntime$Params, context?: HttpContext): Observable<StrictHttpResponse<Downtime>> {
    return getDowntime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDowntime$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDowntime(params: GetDowntime$Params, context?: HttpContext): Observable<Downtime> {
    return this.getDowntime$Response(params, context).pipe(map((r: StrictHttpResponse<Downtime>): Downtime => r.body));
  }

  /** Path part for operation `getReasonsTreeListAll()` */
  static readonly GetReasonsTreeListAllPath = '/api/v1/Downtimes/Reasons/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReasonsTreeListAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReasonsTreeListAll$Response(
    params: GetReasonsTreeListAll$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DowntimeSection>>> {
    return getReasonsTreeListAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReasonsTreeListAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReasonsTreeListAll(params: GetReasonsTreeListAll$Params, context?: HttpContext): Observable<Array<DowntimeSection>> {
    return this.getReasonsTreeListAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DowntimeSection>>): Array<DowntimeSection> => r.body)
    );
  }

  /** Path part for operation `getReasonsTreeList()` */
  static readonly GetReasonsTreeListPath = '/api/v1/Downtimes/ReasonsTreeList/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReasonsTreeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReasonsTreeList$Response(
    params: GetReasonsTreeList$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DowntimeSection>>> {
    return getReasonsTreeList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReasonsTreeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReasonsTreeList(params: GetReasonsTreeList$Params, context?: HttpContext): Observable<Array<DowntimeSection>> {
    return this.getReasonsTreeList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DowntimeSection>>): Array<DowntimeSection> => r.body)
    );
  }

  /** Path part for operation `getReasonsFlatList()` */
  static readonly GetReasonsFlatListPath = '/api/v1/Downtimes/ReasonsFlatList/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReasonsFlatList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReasonsFlatList$Response(
    params: GetReasonsFlatList$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DowntimeReason>>> {
    return getReasonsFlatList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReasonsFlatList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReasonsFlatList(params: GetReasonsFlatList$Params, context?: HttpContext): Observable<Array<DowntimeReason>> {
    return this.getReasonsFlatList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DowntimeReason>>): Array<DowntimeReason> => r.body)
    );
  }

  /** Path part for operation `getEditDowntimeStatus()` */
  static readonly GetEditDowntimeStatusPath = '/api/v1/Downtimes/EditDowntimeStatus/{downtimeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditDowntimeStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditDowntimeStatus$Response(
    params: GetEditDowntimeStatus$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<EditDowntimeStatus>> {
    return getEditDowntimeStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditDowntimeStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditDowntimeStatus(params: GetEditDowntimeStatus$Params, context?: HttpContext): Observable<EditDowntimeStatus> {
    return this.getEditDowntimeStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditDowntimeStatus>): EditDowntimeStatus => r.body)
    );
  }
}
