/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrDetailsDto } from '../../models/or-details-dto';

export interface GetMetaModel$Params {
  orid: number;
  attributeNames?: Array<string>;
}

export function getMetaModel(
  http: HttpClient,
  rootUrl: string,
  params: GetMetaModel$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<OrDetailsDto>>> {
  const rb = new RequestBuilder(rootUrl, getMetaModel.PATH, 'get');
  if (params) {
    rb.path('orid', params.orid, {});
    rb.query('attributeNames', params.attributeNames, { style: 'form', explode: true });
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<OrDetailsDto>>;
    })
  );
}

getMetaModel.PATH = '/api/MetaModel/{orid}';
