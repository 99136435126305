/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SystabcatSc } from '../../models/systabcat-sc';

export interface GetDataSourcesById$Params {
  orid: number;
  scid: number;
}

export function getDataSourcesById(
  http: HttpClient,
  rootUrl: string,
  params: GetDataSourcesById$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<SystabcatSc>> {
  const rb = new RequestBuilder(rootUrl, getDataSourcesById.PATH, 'get');
  if (params) {
    rb.path('orid', params.orid, {});
    rb.path('scid', params.scid, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SystabcatSc>;
    })
  );
}

getDataSourcesById.PATH = '/api/MasterDataSource/dataSourcesById/{orid}/{scid}';
