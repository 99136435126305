/**
 * Checks if the URL is a same-site URL.
 * This matches relative, protocol relative and absolute URLs to the same origin
 * @param url The URL to check
 */
export function isSameSiteUrl(url: string): boolean {
  // It's an absolute url with the same origin.
  if (url.startsWith(`${window.location.origin}/`)) {
    return true;
  }

  // It's a protocol relative url with the same origin.
  // For example: //www.example.com/api/Products
  if (url.startsWith(`//${window.location.host}/`)) {
    return true;
  }

  // It's a relative url like /api/Products
  if (/^\/([^\/].*|$)/.test(url)) {
    return true;
  }

  // It's an absolute or protocol relative url that
  // doesn't have the same origin.
  return false;
}
