/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authenticate } from '../fn/auth/authenticate';
import { Authenticate$Params } from '../fn/auth/authenticate';
import { AuthResponse } from '../models/auth-response';
import { changeUserPassword } from '../fn/auth/change-user-password';
import { ChangeUserPassword$Params } from '../fn/auth/change-user-password';
import { getAllUserDetails } from '../fn/auth/get-all-user-details';
import { GetAllUserDetails$Params } from '../fn/auth/get-all-user-details';
import { getUserAuth } from '../fn/auth/get-user-auth';
import { GetUserAuth$Params } from '../fn/auth/get-user-auth';
import { updateUserSettings } from '../fn/auth/update-user-settings';
import { UpdateUserSettings$Params } from '../fn/auth/update-user-settings';
import { UserUsDto } from '../models/user-us-dto';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authenticate()` */
  static readonly AuthenticatePath = '/api/Auth/authenticate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticate$Response(params: Authenticate$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthResponse>> {
    return authenticate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticate(params: Authenticate$Params, context?: HttpContext): Observable<AuthResponse> {
    return this.authenticate$Response(params, context).pipe(map((r: StrictHttpResponse<AuthResponse>): AuthResponse => r.body));
  }

  /** Path part for operation `getUserAuth()` */
  static readonly GetUserAuthPath = '/api/Auth/ad/userauth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAuth()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAuth$Response(params?: GetUserAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthResponse>> {
    return getUserAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserAuth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAuth(params?: GetUserAuth$Params, context?: HttpContext): Observable<AuthResponse> {
    return this.getUserAuth$Response(params, context).pipe(map((r: StrictHttpResponse<AuthResponse>): AuthResponse => r.body));
  }

  /** Path part for operation `getAllUserDetails()` */
  static readonly GetAllUserDetailsPath = '/api/Auth/getUsersById/{usid}/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUserDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUserDetails$Response(params: GetAllUserDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserUsDto>>> {
    return getAllUserDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllUserDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUserDetails(params: GetAllUserDetails$Params, context?: HttpContext): Observable<Array<UserUsDto>> {
    return this.getAllUserDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserUsDto>>): Array<UserUsDto> => r.body)
    );
  }

  /** Path part for operation `updateUserSettings()` */
  static readonly UpdateUserSettingsPath = '/api/Auth/updateUserSettings/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserSettings$Response(params: UpdateUserSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUsDto>> {
    return updateUserSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserSettings(params: UpdateUserSettings$Params, context?: HttpContext): Observable<UserUsDto> {
    return this.updateUserSettings$Response(params, context).pipe(map((r: StrictHttpResponse<UserUsDto>): UserUsDto => r.body));
  }

  /** Path part for operation `changeUserPassword()` */
  static readonly ChangeUserPasswordPath = '/api/Auth/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeUserPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeUserPassword$Response(params: ChangeUserPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return changeUserPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeUserPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeUserPassword(params: ChangeUserPassword$Params, context?: HttpContext): Observable<number> {
    return this.changeUserPassword$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }
}
