import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() public loadingTopic = '';
  @Input() public loadingIndicator = true;
  @Input() public disabled = false;
  @Input() public label = '';
  @Input() public buttonId = '';
  @Input() public typeClass = 'button--primary';
  @Input() public sizeClass = '';
  @Input() public isAutoFocus = false;
  @Output() public clicked = new EventEmitter<void>();

  public loading = false;

  public onClick(): void {
    if (this.disabled || this.loading) {
      return;
    }
    this.clicked.emit();
  }
}
