/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { cancelSendShiftReport } from '../fn/shift-report/cancel-send-shift-report';
import { CancelSendShiftReport$Params } from '../fn/shift-report/cancel-send-shift-report';
import { getHeaderShiftKpis } from '../fn/shift-report/get-header-shift-kpis';
import { GetHeaderShiftKpis$Params } from '../fn/shift-report/get-header-shift-kpis';
import { getOpenTasks } from '../fn/shift-report/get-open-tasks';
import { GetOpenTasks$Params } from '../fn/shift-report/get-open-tasks';
import { getProductionOrderReportForShift } from '../fn/shift-report/get-production-order-report-for-shift';
import { GetProductionOrderReportForShift$Params } from '../fn/shift-report/get-production-order-report-for-shift';
import { getShiftReport } from '../fn/shift-report/get-shift-report';
import { GetShiftReport$Params } from '../fn/shift-report/get-shift-report';
import { getShifts } from '../fn/shift-report/get-shifts';
import { GetShifts$Params } from '../fn/shift-report/get-shifts';
import { KpiValue } from '../models/kpi-value';
import { OpenTaskItem } from '../models/open-task-item';
import { sendShiftReport } from '../fn/shift-report/send-shift-report';
import { SendShiftReport$Params } from '../fn/shift-report/send-shift-report';
import { Shift } from '../models/shift';
import { ShiftReport } from '../models/shift-report';
import { ShiftReportItem } from '../models/shift-report-item';

@Injectable({ providedIn: 'root' })
export class ShiftReportService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendShiftReport()` */
  static readonly SendShiftReportPath = '/api/v1/ShiftReport/{workCenterId}/SendShiftReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendShiftReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendShiftReport$Response(params: SendShiftReport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendShiftReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendShiftReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendShiftReport(params: SendShiftReport$Params, context?: HttpContext): Observable<void> {
    return this.sendShiftReport$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `cancelSendShiftReport()` */
  static readonly CancelSendShiftReportPath = '/api/v1/ShiftReport/{workCenterId}/CancelSendShiftReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelSendShiftReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelSendShiftReport$Response(params: CancelSendShiftReport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cancelSendShiftReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelSendShiftReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelSendShiftReport(params: CancelSendShiftReport$Params, context?: HttpContext): Observable<void> {
    return this.cancelSendShiftReport$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getShifts()` */
  static readonly GetShiftsPath = '/api/v1/ShiftReport/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShifts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShifts$Response(params: GetShifts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Shift>>> {
    return getShifts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShifts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShifts(params: GetShifts$Params, context?: HttpContext): Observable<Array<Shift>> {
    return this.getShifts$Response(params, context).pipe(map((r: StrictHttpResponse<Array<Shift>>): Array<Shift> => r.body));
  }

  /** Path part for operation `getShiftReport()` */
  static readonly GetShiftReportPath = '/api/v1/ShiftReport/{workCenterId}/GetShiftReport/{shiftId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShiftReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftReport$Response(params: GetShiftReport$Params, context?: HttpContext): Observable<StrictHttpResponse<ShiftReport>> {
    return getShiftReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShiftReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftReport(params: GetShiftReport$Params, context?: HttpContext): Observable<ShiftReport> {
    return this.getShiftReport$Response(params, context).pipe(map((r: StrictHttpResponse<ShiftReport>): ShiftReport => r.body));
  }

  /** Path part for operation `getOpenTasks()` */
  static readonly GetOpenTasksPath = '/api/v1/ShiftReport/{workCenterId}/OpenTasks/{shiftId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenTasks$Response(params: GetOpenTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OpenTaskItem>>> {
    return getOpenTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenTasks(params: GetOpenTasks$Params, context?: HttpContext): Observable<Array<OpenTaskItem>> {
    return this.getOpenTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OpenTaskItem>>): Array<OpenTaskItem> => r.body)
    );
  }

  /** Path part for operation `getHeaderShiftKpis()` */
  static readonly GetHeaderShiftKpisPath = '/api/v1/ShiftReport/{workCenterId}/GetHeaderShiftKpis/{shiftId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHeaderShiftKpis()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaderShiftKpis$Response(params: GetHeaderShiftKpis$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KpiValue>>> {
    return getHeaderShiftKpis(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHeaderShiftKpis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaderShiftKpis(params: GetHeaderShiftKpis$Params, context?: HttpContext): Observable<Array<KpiValue>> {
    return this.getHeaderShiftKpis$Response(params, context).pipe(map((r: StrictHttpResponse<Array<KpiValue>>): Array<KpiValue> => r.body));
  }

  /** Path part for operation `getProductionOrderReportForShift()` */
  static readonly GetProductionOrderReportForShiftPath =
    '/api/v1/ShiftReport/{productionOrderId}/GetProductionOrderReportForShift/{shiftId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderReportForShift()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderReportForShift$Response(
    params: GetProductionOrderReportForShift$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ShiftReportItem>> {
    return getProductionOrderReportForShift(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderReportForShift$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderReportForShift(params: GetProductionOrderReportForShift$Params, context?: HttpContext): Observable<ShiftReportItem> {
    return this.getProductionOrderReportForShift$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShiftReportItem>): ShiftReportItem => r.body)
    );
  }
}
