/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SystabcatSc } from '../../models/systabcat-sc';

export interface GetDropdownMasterData$Params {
  orid: number;
}

export function getDropdownMasterData(
  http: HttpClient,
  rootUrl: string,
  params: GetDropdownMasterData$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<SystabcatSc>>> {
  const rb = new RequestBuilder(rootUrl, getDropdownMasterData.PATH, 'get');
  if (params) {
    rb.path('orid', params.orid, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<SystabcatSc>>;
    })
  );
}

getDropdownMasterData.PATH = '/api/MetaModel/dropdown/{orid}';
