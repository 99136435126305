/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UsersettingUe } from '../../models/usersetting-ue';
import { WcSettings } from '../../models/wc-settings';

export interface AddUserSettings$Params {
  orid: number;
  usid?: number;
  ueid?: number;
  body: WcSettings;
}

export function addUserSettings(
  http: HttpClient,
  rootUrl: string,
  params: AddUserSettings$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<UsersettingUe>> {
  const rb = new RequestBuilder(rootUrl, addUserSettings.PATH, 'post');
  if (params) {
    rb.path('orid', params.orid, {});
    rb.query('usid', params.usid, {});
    rb.query('ueid', params.ueid, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UsersettingUe>;
    })
  );
}

addUserSettings.PATH = '/addusersettings/{orid}';
