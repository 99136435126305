/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAvailableMaterials } from '../fn/material/get-available-materials';
import { GetAvailableMaterials$Params } from '../fn/material/get-available-materials';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
import { mountContainer } from '../fn/material/mount-container';
import { MountContainer$Params } from '../fn/material/mount-container';
import { MountContainerResponse } from '../models/mount-container-response';

@Injectable({ providedIn: 'root' })
export class MaterialService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mountContainer()` */
  static readonly MountContainerPath = '/api/v1/Material/{workCenterId}/Mount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mountContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mountContainer$Response(params: MountContainer$Params, context?: HttpContext): Observable<StrictHttpResponse<MountContainerResponse>> {
    return mountContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mountContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mountContainer(params: MountContainer$Params, context?: HttpContext): Observable<MountContainerResponse> {
    return this.mountContainer$Response(params, context).pipe(
      map((r: StrictHttpResponse<MountContainerResponse>): MountContainerResponse => r.body)
    );
  }

  /** Path part for operation `getAvailableMaterials()` */
  static readonly GetAvailableMaterialsPath = '/api/v1/Material/{workCenterId}/GetAvailableMaterials';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableMaterials()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableMaterials$Response(
    params: GetAvailableMaterials$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getAvailableMaterials(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableMaterials$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableMaterials(params: GetAvailableMaterials$Params, context?: HttpContext): Observable<MaterialAvailableAtLocation> {
    return this.getAvailableMaterials$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }
}
