/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MaterialAvailableAtLocation } from '../../models/material-available-at-location';
import { MaterialLocationsMode } from '../../models/material-locations-mode';
import { MaterialLocationsSelectedValueMode } from '../../models/material-locations-selected-value-mode';
import { MaterialTypeFilterCriteria } from '../../models/material-type-filter-criteria';

export interface GetAvailableMaterials$Params {
  workCenterId: number;
  MaterialFilterCriteria?: MaterialTypeFilterCriteria;
  MaterialLocationsMode?: MaterialLocationsMode;
  MaterialLocationsSelectedValueMode?: MaterialLocationsSelectedValueMode;
  ArticleId?: number;
  ProductionOrderId?: number;
}

export function getAvailableMaterials(
  http: HttpClient,
  rootUrl: string,
  params: GetAvailableMaterials$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
  const rb = new RequestBuilder(rootUrl, getAvailableMaterials.PATH, 'get');
  if (params) {
    rb.path('workCenterId', params.workCenterId, {});
    rb.query('MaterialFilterCriteria', params.MaterialFilterCriteria, {});
    rb.query('MaterialLocationsMode', params.MaterialLocationsMode, {});
    rb.query('MaterialLocationsSelectedValueMode', params.MaterialLocationsSelectedValueMode, {});
    rb.query('ArticleId', params.ArticleId, {});
    rb.query('ProductionOrderId', params.ProductionOrderId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MaterialAvailableAtLocation>;
    })
  );
}

getAvailableMaterials.PATH = '/api/v1/Material/{workCenterId}/GetAvailableMaterials';
