/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PageWidgetDetail } from '../../models/page-widget-detail';

export interface GetAllPageWidgetsOfRole$Params {
  arid?: number;
  body: Array<number>;
}

export function getAllPageWidgetsOfRole(
  http: HttpClient,
  rootUrl: string,
  params: GetAllPageWidgetsOfRole$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<PageWidgetDetail>>> {
  const rb = new RequestBuilder(rootUrl, getAllPageWidgetsOfRole.PATH, 'post');
  if (params) {
    rb.query('arid', params.arid, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<PageWidgetDetail>>;
    })
  );
}

getAllPageWidgetsOfRole.PATH = '/api/AclRole/aclPageWidgetsWithRole';
