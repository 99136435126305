import { HttpErrorResponse } from '@angular/common/http';
import { LogLevel, SerializedBackendError, SerializedBackendWarning } from './models';
import * as R from 'ramda';

export class LogEntry {
  public entryDate: Date = new Date();
  public message = '';
  public level: LogLevel = LogLevel.Debug;
  public extraInfo: any[] = [];
  public logWithDate = true;
  public serializedBackendWarning: SerializedBackendWarning;
  public serializedBackendError: SerializedBackendError;
  public internalWorkCenterId: number;
  public internalPileTurnerId: number;
  public apiUrl: string;

  private readonly BACKEND_STATUS_OK_START = 200;
  private readonly BACKEND_STATUS_OK_END = 299;
  private readonly BACKEND_WARNING_CODE = 400;
  private readonly BACKEND_ERROR_CODE = 500;

  public buildLogString(): string {
    let logString = '';

    if (this.logWithDate) {
      logString = `${new Date().toISOString()}\n`;
    }
    logString += `Level: ${LogLevel[this.level]}`;
    logString += `\nMessage: ${this.message}`;
    if (this.extraInfo.length) {
      logString += `\nExtra Info: ${this.formatParams(this.extraInfo)}`;
    }

    return logString;
  }

  public serializeBackendWarning(params: any[]): SerializedBackendWarning {
    if (params[0]?.status !== this.BACKEND_WARNING_CODE) {
      return null;
    } else {
      try {
        const errorField = params[0]?.error;
        if (typeof errorField === 'string') {
          const parsedError = JSON.parse(errorField);
          return parsedError && typeof parsedError === 'object' ? new SerializedBackendWarning(parsedError) : null;
        } else {
          return new SerializedBackendWarning({ ...params[0].error });
        }
      } catch (e) {
        return null;
      }
    }
  }

  public serializeBackendError(params: any[]): SerializedBackendError {
    const errorResponse = params[0] as HttpErrorResponse;
    if (errorResponse === undefined || errorResponse === null) {
      return null;
    }

    if (errorResponse.ok) {
      return null;
    } else if (errorResponse.status === this.BACKEND_ERROR_CODE) {
      if (errorResponse.error === null || errorResponse.error === undefined) {
        return this.extractGenericErrorInformation(errorResponse);
      } else {
        try {
          return new SerializedBackendError({ ...errorResponse.error });
        } catch (e) {
          return this.extractGenericErrorInformation(errorResponse);
        }
      }
    }

    return null;
  }

  private extractGenericErrorInformation(errorResponse: HttpErrorResponse): SerializedBackendError {
    return new SerializedBackendError({
      message: `${errorResponse.status} ${errorResponse.statusText}`,
      details: errorResponse.message,
      innerException: null,
      source: errorResponse.url,
      stackTrace: null,
      type: errorResponse.name
    });
  }

  public extractParameter(params: any[], parameterName: string): string {
    return R.prop(parameterName)(params.find((param) => R.prop(parameterName, param)));
  }

  private formatParams(params: any[]): string {
    let paramsString: string = params.join(',');

    if (params.some((p) => typeof p === 'object')) {
      paramsString = '';
      for (const item of params) {
        paramsString += `${JSON.stringify(item)},`;
      }
    }

    return paramsString;
  }
}
