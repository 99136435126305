/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GridSettingsGs } from '../../models/grid-settings-gs';

export interface GetGridSettingTypesByUser$Params {
  gmname?: string;
}

export function getGridSettingTypesByUser(
  http: HttpClient,
  rootUrl: string,
  params?: GetGridSettingTypesByUser$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<GridSettingsGs>>> {
  const rb = new RequestBuilder(rootUrl, getGridSettingTypesByUser.PATH, 'get');
  if (params) {
    rb.query('gmname', params.gmname, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<GridSettingsGs>>;
    })
  );
}

getGridSettingTypesByUser.PATH = '/api/GridSettings/getGridSettingTypesByUser';
