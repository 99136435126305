/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { recreatePeriods } from '../fn/simulation/recreate-periods';
import { RecreatePeriods$Params } from '../fn/simulation/recreate-periods';
import { setSpeed } from '../fn/simulation/set-speed';
import { SetSpeed$Params } from '../fn/simulation/set-speed';

@Injectable({ providedIn: 'root' })
export class SimulationService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `setSpeed()` */
  static readonly SetSpeedPath = '/api/v1/Simulation/{workCenterId}/speed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSpeed()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSpeed$Response(params: SetSpeed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setSpeed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setSpeed$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSpeed(params: SetSpeed$Params, context?: HttpContext): Observable<void> {
    return this.setSpeed$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `recreatePeriods()` */
  static readonly RecreatePeriodsPath = '/api/v1/Simulation/{workCenterId}/RecreatePeriods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recreatePeriods()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recreatePeriods$Response(params: RecreatePeriods$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return recreatePeriods(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recreatePeriods$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recreatePeriods(params: RecreatePeriods$Params, context?: HttpContext): Observable<void> {
    return this.recreatePeriods$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }
}
