/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MachineSchedule } from '../../models/machine-schedule';

export interface GetFiltered$Params {
  workCenterId: number;
  ShowFinishedOrders?: boolean;
  ShiftFilterId?: number;
  CalculatePrimaryMachineLocationKpi?: boolean;
  CalculateSecondaryMachineLocationKpi?: boolean;
}

export function getFiltered(
  http: HttpClient,
  rootUrl: string,
  params: GetFiltered$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<MachineSchedule>>> {
  const rb = new RequestBuilder(rootUrl, getFiltered.PATH, 'get');
  if (params) {
    rb.path('workCenterId', params.workCenterId, {});
    rb.query('ShowFinishedOrders', params.ShowFinishedOrders, {});
    rb.query('ShiftFilterId', params.ShiftFilterId, {});
    rb.query('CalculatePrimaryMachineLocationKpi', params.CalculatePrimaryMachineLocationKpi, {});
    rb.query('CalculateSecondaryMachineLocationKpi', params.CalculateSecondaryMachineLocationKpi, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<MachineSchedule>>;
    })
  );
}

getFiltered.PATH = '/api/v1/MachineSchedule/{workCenterId}/Filtered';
