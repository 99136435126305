/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { editInformation } from '../fn/document-management/edit-information';
import { EditInformation$Params } from '../fn/document-management/edit-information';
import { getDocuments } from '../fn/document-management/get-documents';
import { GetDocuments$Params } from '../fn/document-management/get-documents';
import { getPrintedLabelInfo } from '../fn/document-management/get-printed-label-info';
import { GetPrintedLabelInfo$Params } from '../fn/document-management/get-printed-label-info';
import { getProductionOrderInformation } from '../fn/document-management/get-production-order-information';
import { GetProductionOrderInformation$Params } from '../fn/document-management/get-production-order-information';
import { markAsDisplayed } from '../fn/document-management/mark-as-displayed';
import { MarkAsDisplayed$Params } from '../fn/document-management/mark-as-displayed';
import { ProductionOrderDocumentInfo } from '../models/production-order-document-info';
import { ProductionOrderDocuments } from '../models/production-order-documents';
import { ProductionOrderPrintedLabelInfo } from '../models/production-order-printed-label-info';

@Injectable({ providedIn: 'root' })
export class DocumentManagementService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `editInformation()` */
  static readonly EditInformationPath = '/api/v1/DocumentManagement/{productionOrderId}/EditInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editInformation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editInformation$Response(params: EditInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editInformation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editInformation(params: EditInformation$Params, context?: HttpContext): Observable<void> {
    return this.editInformation$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `markAsDisplayed()` */
  static readonly MarkAsDisplayedPath = '/api/v1/DocumentManagement/{productionOrderId}/MarkAsDisplayed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAsDisplayed()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markAsDisplayed$Response(params: MarkAsDisplayed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markAsDisplayed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAsDisplayed$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markAsDisplayed(params: MarkAsDisplayed$Params, context?: HttpContext): Observable<void> {
    return this.markAsDisplayed$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getProductionOrderInformation()` */
  static readonly GetProductionOrderInformationPath = '/api/v1/DocumentManagement/{productionOrderId}/GetProductionOrderInformation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderInformation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderInformation$Response(
    params: GetProductionOrderInformation$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProductionOrderDocumentInfo>> {
    return getProductionOrderInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderInformation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderInformation(
    params: GetProductionOrderInformation$Params,
    context?: HttpContext
  ): Observable<ProductionOrderDocumentInfo> {
    return this.getProductionOrderInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductionOrderDocumentInfo>): ProductionOrderDocumentInfo => r.body)
    );
  }

  /** Path part for operation `getDocuments()` */
  static readonly GetDocumentsPath = '/api/v1/DocumentManagement/{productionOrderId}/GetDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params: GetDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductionOrderDocuments>> {
    return getDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params: GetDocuments$Params, context?: HttpContext): Observable<ProductionOrderDocuments> {
    return this.getDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductionOrderDocuments>): ProductionOrderDocuments => r.body)
    );
  }

  /** Path part for operation `getPrintedLabelInfo()` */
  static readonly GetPrintedLabelInfoPath = '/api/v1/DocumentManagement/{productionOrderId}/GetPrintedLabelInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrintedLabelInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrintedLabelInfo$Response(
    params: GetPrintedLabelInfo$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProductionOrderPrintedLabelInfo>> {
    return getPrintedLabelInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPrintedLabelInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrintedLabelInfo(params: GetPrintedLabelInfo$Params, context?: HttpContext): Observable<ProductionOrderPrintedLabelInfo> {
    return this.getPrintedLabelInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductionOrderPrintedLabelInfo>): ProductionOrderPrintedLabelInfo => r.body)
    );
  }
}
