/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AttributeGroupingData } from '../models/attribute-grouping-data';
import { AttributeGroupsAg } from '../models/attribute-groups-ag';
import { createNodeType } from '../fn/meta-model/create-node-type';
import { CreateNodeType$Params } from '../fn/meta-model/create-node-type';
import { DatatypeDe } from '../models/datatype-de';
import { deleteAttribute } from '../fn/meta-model/delete-attribute';
import { DeleteAttribute$Params } from '../fn/meta-model/delete-attribute';
import { deleteMetaModel } from '../fn/meta-model/delete-meta-model';
import { DeleteMetaModel$Params } from '../fn/meta-model/delete-meta-model';
import { editNodeType } from '../fn/meta-model/edit-node-type';
import { EditNodeType$Params } from '../fn/meta-model/edit-node-type';
import { getActiveNodeType } from '../fn/meta-model/get-active-node-type';
import { GetActiveNodeType$Params } from '../fn/meta-model/get-active-node-type';
import { getAttributesGroupingData } from '../fn/meta-model/get-attributes-grouping-data';
import { GetAttributesGroupingData$Params } from '../fn/meta-model/get-attributes-grouping-data';
import { getAttributeTypeMasterData } from '../fn/meta-model/get-attribute-type-master-data';
import { GetAttributeTypeMasterData$Params } from '../fn/meta-model/get-attribute-type-master-data';
import { getDatatype } from '../fn/meta-model/get-datatype';
import { GetDatatype$Params } from '../fn/meta-model/get-datatype';
import { getDropdownMasterData } from '../fn/meta-model/get-dropdown-master-data';
import { GetDropdownMasterData$Params } from '../fn/meta-model/get-dropdown-master-data';
import { getMetaModel } from '../fn/meta-model/get-meta-model';
import { GetMetaModel$Params } from '../fn/meta-model/get-meta-model';
import { getMetaModelLevel } from '../fn/meta-model/get-meta-model-level';
import { GetMetaModelLevel$Params } from '../fn/meta-model/get-meta-model-level';
import { getMetaModelList } from '../fn/meta-model/get-meta-model-list';
import { GetMetaModelList$Params } from '../fn/meta-model/get-meta-model-list';
import { getMetaModelListType } from '../fn/meta-model/get-meta-model-list-type';
import { GetMetaModelListType$Params } from '../fn/meta-model/get-meta-model-list-type';
import { getMetaModelNodeTypes } from '../fn/meta-model/get-meta-model-node-types';
import { GetMetaModelNodeTypes$Params } from '../fn/meta-model/get-meta-model-node-types';
import { getMetaModelType } from '../fn/meta-model/get-meta-model-type';
import { GetMetaModelType$Params } from '../fn/meta-model/get-meta-model-type';
import { importMasterData } from '../fn/meta-model/import-master-data';
import { ImportMasterData$Params } from '../fn/meta-model/import-master-data';
import { ImportMetaDataResponse } from '../models/import-meta-data-response';
import { isAttributeNameExists } from '../fn/meta-model/is-attribute-name-exists';
import { IsAttributeNameExists$Params } from '../fn/meta-model/is-attribute-name-exists';
import { MetadataDm } from '../models/metadata-dm';
import { MetamodelMe } from '../models/metamodel-me';
import { OrDetailsDto } from '../models/or-details-dto';
import { SystabcatSc } from '../models/systabcat-sc';

@Injectable({ providedIn: 'root' })
export class MetaModelService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMetaModel()` */
  static readonly GetMetaModelPath = '/api/MetaModel/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetaModel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModel$Response(params: GetMetaModel$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrDetailsDto>>> {
    return getMetaModel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetaModel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModel(params: GetMetaModel$Params, context?: HttpContext): Observable<Array<OrDetailsDto>> {
    return this.getMetaModel$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrDetailsDto>>): Array<OrDetailsDto> => r.body)
    );
  }

  /** Path part for operation `importMasterData()` */
  static readonly ImportMasterDataPath = '/api/MetaModel/masterData/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importMasterData()` instead.
   *
   * This method doesn't expect any request body.
   */
  importMasterData$Response(
    params?: ImportMasterData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<ImportMetaDataResponse>> {
    return importMasterData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importMasterData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importMasterData(params?: ImportMasterData$Params, context?: HttpContext): Observable<ImportMetaDataResponse> {
    return this.importMasterData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImportMetaDataResponse>): ImportMetaDataResponse => r.body)
    );
  }

  /** Path part for operation `getAttributesGroupingData()` */
  static readonly GetAttributesGroupingDataPath = '/api/MetaModel/getAttributeGroupingData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttributesGroupingData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributesGroupingData$Response(
    params?: GetAttributesGroupingData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AttributeGroupingData>>> {
    return getAttributesGroupingData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttributesGroupingData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributesGroupingData(params?: GetAttributesGroupingData$Params, context?: HttpContext): Observable<Array<AttributeGroupingData>> {
    return this.getAttributesGroupingData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttributeGroupingData>>): Array<AttributeGroupingData> => r.body)
    );
  }

  /** Path part for operation `getMetaModelNodeTypes()` */
  static readonly GetMetaModelNodeTypesPath = '/api/MetaModel/masterData/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetaModelNodeTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelNodeTypes$Response(
    params: GetMetaModelNodeTypes$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MetamodelMe>>> {
    return getMetaModelNodeTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetaModelNodeTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelNodeTypes(params: GetMetaModelNodeTypes$Params, context?: HttpContext): Observable<Array<MetamodelMe>> {
    return this.getMetaModelNodeTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MetamodelMe>>): Array<MetamodelMe> => r.body)
    );
  }

  /** Path part for operation `getMetaModelList()` */
  static readonly GetMetaModelListPath = '/api/MetaModel/metamodels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetaModelList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelList$Response(params?: GetMetaModelList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MetamodelMe>>> {
    return getMetaModelList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetaModelList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelList(params?: GetMetaModelList$Params, context?: HttpContext): Observable<Array<MetamodelMe>> {
    return this.getMetaModelList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MetamodelMe>>): Array<MetamodelMe> => r.body)
    );
  }

  /** Path part for operation `getMetaModelListType()` */
  static readonly GetMetaModelListTypePath = '/api/MetaModel/metamodelstype/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetaModelListType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelListType$Response(
    params: GetMetaModelListType$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MetamodelMe>>> {
    return getMetaModelListType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetaModelListType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelListType(params: GetMetaModelListType$Params, context?: HttpContext): Observable<Array<MetamodelMe>> {
    return this.getMetaModelListType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MetamodelMe>>): Array<MetamodelMe> => r.body)
    );
  }

  /** Path part for operation `getMetaModelType()` */
  static readonly GetMetaModelTypePath = '/api/MetaModel/metamodeltype/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetaModelType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelType$Response(params: GetMetaModelType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MetamodelMe>>> {
    return getMetaModelType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetaModelType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelType(params: GetMetaModelType$Params, context?: HttpContext): Observable<Array<MetamodelMe>> {
    return this.getMetaModelType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MetamodelMe>>): Array<MetamodelMe> => r.body)
    );
  }

  /** Path part for operation `getDatatype()` */
  static readonly GetDatatypePath = '/api/MetaModel/datatype/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDatatype()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatatype$Response(params: GetDatatype$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DatatypeDe>>> {
    return getDatatype(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDatatype$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatatype(params: GetDatatype$Params, context?: HttpContext): Observable<Array<DatatypeDe>> {
    return this.getDatatype$Response(params, context).pipe(map((r: StrictHttpResponse<Array<DatatypeDe>>): Array<DatatypeDe> => r.body));
  }

  /** Path part for operation `getActiveNodeType()` */
  static readonly GetActiveNodeTypePath = '/api/MetaModel/nodetype/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveNodeType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveNodeType$Response(params: GetActiveNodeType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MetamodelMe>>> {
    return getActiveNodeType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveNodeType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveNodeType(params: GetActiveNodeType$Params, context?: HttpContext): Observable<Array<MetamodelMe>> {
    return this.getActiveNodeType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MetamodelMe>>): Array<MetamodelMe> => r.body)
    );
  }

  /** Path part for operation `getDropdownMasterData()` */
  static readonly GetDropdownMasterDataPath = '/api/MetaModel/dropdown/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDropdownMasterData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDropdownMasterData$Response(
    params: GetDropdownMasterData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SystabcatSc>>> {
    return getDropdownMasterData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDropdownMasterData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDropdownMasterData(params: GetDropdownMasterData$Params, context?: HttpContext): Observable<Array<SystabcatSc>> {
    return this.getDropdownMasterData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SystabcatSc>>): Array<SystabcatSc> => r.body)
    );
  }

  /** Path part for operation `getAttributeTypeMasterData()` */
  static readonly GetAttributeTypeMasterDataPath = '/api/MetaModel/attributeTypeDropdown/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttributeTypeMasterData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributeTypeMasterData$Response(
    params: GetAttributeTypeMasterData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AttributeGroupsAg>>> {
    return getAttributeTypeMasterData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttributeTypeMasterData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttributeTypeMasterData(params: GetAttributeTypeMasterData$Params, context?: HttpContext): Observable<Array<AttributeGroupsAg>> {
    return this.getAttributeTypeMasterData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttributeGroupsAg>>): Array<AttributeGroupsAg> => r.body)
    );
  }

  /** Path part for operation `editNodeType()` */
  static readonly EditNodeTypePath = '/api/MetaModel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editNodeType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editNodeType$Response(params: EditNodeType$Params, context?: HttpContext): Observable<StrictHttpResponse<MetamodelMe>> {
    return editNodeType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editNodeType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editNodeType(params: EditNodeType$Params, context?: HttpContext): Observable<MetamodelMe> {
    return this.editNodeType$Response(params, context).pipe(map((r: StrictHttpResponse<MetamodelMe>): MetamodelMe => r.body));
  }

  /** Path part for operation `createNodeType()` */
  static readonly CreateNodeTypePath = '/api/MetaModel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNodeType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNodeType$Response(params: CreateNodeType$Params, context?: HttpContext): Observable<StrictHttpResponse<MetamodelMe>> {
    return createNodeType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNodeType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNodeType(params: CreateNodeType$Params, context?: HttpContext): Observable<MetamodelMe> {
    return this.createNodeType$Response(params, context).pipe(map((r: StrictHttpResponse<MetamodelMe>): MetamodelMe => r.body));
  }

  /** Path part for operation `deleteAttribute()` */
  static readonly DeleteAttributePath = '/api/MetaModel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAttribute()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteAttribute$Response(params: DeleteAttribute$Params, context?: HttpContext): Observable<StrictHttpResponse<MetadataDm>> {
    return deleteAttribute(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAttribute$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteAttribute(params: DeleteAttribute$Params, context?: HttpContext): Observable<MetadataDm> {
    return this.deleteAttribute$Response(params, context).pipe(map((r: StrictHttpResponse<MetadataDm>): MetadataDm => r.body));
  }

  /** Path part for operation `deleteMetaModel()` */
  static readonly DeleteMetaModelPath = '/removemetamodel/{meid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMetaModel()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMetaModel$Response(params: DeleteMetaModel$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return deleteMetaModel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMetaModel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMetaModel(params: DeleteMetaModel$Params, context?: HttpContext): Observable<number> {
    return this.deleteMetaModel$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `getMetaModelLevel()` */
  static readonly GetMetaModelLevelPath = '/api/MetaModel/bylevel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetaModelLevel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelLevel$Response(params?: GetMetaModelLevel$Params, context?: HttpContext): Observable<StrictHttpResponse<MetamodelMe>> {
    return getMetaModelLevel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetaModelLevel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaModelLevel(params?: GetMetaModelLevel$Params, context?: HttpContext): Observable<MetamodelMe> {
    return this.getMetaModelLevel$Response(params, context).pipe(map((r: StrictHttpResponse<MetamodelMe>): MetamodelMe => r.body));
  }

  /** Path part for operation `isAttributeNameExists()` */
  static readonly IsAttributeNameExistsPath = '/api/MetaModel/IsAttributeNameExists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isAttributeNameExists()` instead.
   *
   * This method doesn't expect any request body.
   */
  isAttributeNameExists$Response(params?: IsAttributeNameExists$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isAttributeNameExists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isAttributeNameExists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isAttributeNameExists(params?: IsAttributeNameExists$Params, context?: HttpContext): Observable<boolean> {
    return this.isAttributeNameExists$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }
}
