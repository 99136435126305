import { Component, Input } from '@angular/core';
import { MenuItem } from '../../../models/menu-item.model';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-menu-container',
  templateUrl: './menu-container.component.html',
  styleUrls: ['./menu-container.component.scss']
})
export class MenuContainerComponent {
  @Input() public menuItems: Observable<MenuItem[]>;

  private defaultIsActiveMatchOptions: IsActiveMatchOptions = {
    paths: 'exact',
    queryParams: 'exact',
    fragment: 'ignored',
    matrixParams: 'ignored'
  };

  constructor(private router: Router) {}

  public isMenuItemActive(menuItem: MenuItem): boolean {
    if (!menuItem.link) {
      return false;
    }

    const link = typeof menuItem.link === 'function' ? menuItem.link() : menuItem.link;

    const isActiveMatchOptions = menuItem.isActiveMatchOptions ?? this.defaultIsActiveMatchOptions;

    return this.router.isActive(link, isActiveMatchOptions);
  }
}
