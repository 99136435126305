/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GridSettings } from '../../models/grid-settings';
import { UserSettingType } from '../../models/user-setting-type';

export interface GetGridSettingsByUser$Params {
  gmname?: string;
  settingType?: UserSettingType;
}

export function getGridSettingsByUser(
  http: HttpClient,
  rootUrl: string,
  params?: GetGridSettingsByUser$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<GridSettings>> {
  const rb = new RequestBuilder(rootUrl, getGridSettingsByUser.PATH, 'get');
  if (params) {
    rb.query('gmname', params.gmname, {});
    rb.query('settingType', params.settingType, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GridSettings>;
    })
  );
}

getGridSettingsByUser.PATH = '/api/GridSettings/getGridSettingsByUser';
