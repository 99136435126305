/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activeInactiveOrganization } from '../fn/organizations/active-inactive-organization';
import { ActiveInactiveOrganization$Params } from '../fn/organizations/active-inactive-organization';
import { add } from '../fn/organizations/add';
import { Add$Params } from '../fn/organizations/add';
import { delete$ } from '../fn/organizations/delete';
import { Delete$Params } from '../fn/organizations/delete';
import { getActiveOrganizationForSpeedMonitor } from '../fn/organizations/get-active-organization-for-speed-monitor';
import { GetActiveOrganizationForSpeedMonitor$Params } from '../fn/organizations/get-active-organization-for-speed-monitor';
import { getActiveOrganizations } from '../fn/organizations/get-active-organizations';
import { GetActiveOrganizations$Params } from '../fn/organizations/get-active-organizations';
import { getActiveOrganizationsForUser } from '../fn/organizations/get-active-organizations-for-user';
import { GetActiveOrganizationsForUser$Params } from '../fn/organizations/get-active-organizations-for-user';
import { getDetails } from '../fn/organizations/get-details';
import { GetDetails$Params } from '../fn/organizations/get-details';
import { getOrganizationByName } from '../fn/organizations/get-organization-by-name';
import { GetOrganizationByName$Params } from '../fn/organizations/get-organization-by-name';
import { getParentOrganizationDetails } from '../fn/organizations/get-parent-organization-details';
import { GetParentOrganizationDetails$Params } from '../fn/organizations/get-parent-organization-details';
import { getReverseTreeOfTenant } from '../fn/organizations/get-reverse-tree-of-tenant';
import { GetReverseTreeOfTenant$Params } from '../fn/organizations/get-reverse-tree-of-tenant';
import { getTenantList } from '../fn/organizations/get-tenant-list';
import { GetTenantList$Params } from '../fn/organizations/get-tenant-list';
import { getTree } from '../fn/organizations/get-tree';
import { GetTree$Params } from '../fn/organizations/get-tree';
import { getTreeDetails } from '../fn/organizations/get-tree-details';
import { GetTreeDetails$Params } from '../fn/organizations/get-tree-details';
import { getTreeStructure } from '../fn/organizations/get-tree-structure';
import { GetTreeStructure$Params } from '../fn/organizations/get-tree-structure';
import { loadMetaAttributes } from '../fn/organizations/load-meta-attributes';
import { LoadMetaAttributes$Params } from '../fn/organizations/load-meta-attributes';
import { loadMetaAttributesWithValue } from '../fn/organizations/load-meta-attributes-with-value';
import { LoadMetaAttributesWithValue$Params } from '../fn/organizations/load-meta-attributes-with-value';
import { MetaAttribute } from '../models/meta-attribute';
import { OrganizationOr } from '../models/organization-or';
import { OrgnizationList } from '../models/orgnization-list';
import { saveGeneralInformationMetaAttributesAsync } from '../fn/organizations/save-general-information-meta-attributes-async';
import { SaveGeneralInformationMetaAttributesAsync$Params } from '../fn/organizations/save-general-information-meta-attributes-async';
import { update } from '../fn/organizations/update';
import { Update$Params } from '../fn/organizations/update';
import { VerticalTreeNode } from '../models/vertical-tree-node';

@Injectable({ providedIn: 'root' })
export class OrganizationsService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOrganizationByName()` */
  static readonly GetOrganizationByNamePath = '/api/Organizations/getOrganizationByName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationByName$Response(
    params?: GetOrganizationByName$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrganizationOr>> {
    return getOrganizationByName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationByName(params?: GetOrganizationByName$Params, context?: HttpContext): Observable<OrganizationOr> {
    return this.getOrganizationByName$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationOr>): OrganizationOr => r.body)
    );
  }

  /** Path part for operation `loadMetaAttributesWithValue()` */
  static readonly LoadMetaAttributesWithValuePath = '/api/Organizations/loadMetaAttributesWithValue/{orid}/{meid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadMetaAttributesWithValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadMetaAttributesWithValue$Response(
    params: LoadMetaAttributesWithValue$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MetaAttribute>>> {
    return loadMetaAttributesWithValue(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadMetaAttributesWithValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadMetaAttributesWithValue(params: LoadMetaAttributesWithValue$Params, context?: HttpContext): Observable<Array<MetaAttribute>> {
    return this.loadMetaAttributesWithValue$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MetaAttribute>>): Array<MetaAttribute> => r.body)
    );
  }

  /** Path part for operation `loadMetaAttributes()` */
  static readonly LoadMetaAttributesPath = '/api/Organizations/loadMetaAttributes/{orid}/{meid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadMetaAttributes()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadMetaAttributes$Response(
    params: LoadMetaAttributes$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MetaAttribute>>> {
    return loadMetaAttributes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadMetaAttributes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadMetaAttributes(params: LoadMetaAttributes$Params, context?: HttpContext): Observable<Array<MetaAttribute>> {
    return this.loadMetaAttributes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MetaAttribute>>): Array<MetaAttribute> => r.body)
    );
  }

  /** Path part for operation `saveGeneralInformationMetaAttributesAsync()` */
  static readonly SaveGeneralInformationMetaAttributesAsyncPath = '/api/Organizations/saveGeneralInformation/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveGeneralInformationMetaAttributesAsync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveGeneralInformationMetaAttributesAsync$Response(
    params: SaveGeneralInformationMetaAttributesAsync$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    return saveGeneralInformationMetaAttributesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveGeneralInformationMetaAttributesAsync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveGeneralInformationMetaAttributesAsync(
    params: SaveGeneralInformationMetaAttributesAsync$Params,
    context?: HttpContext
  ): Observable<number> {
    return this.saveGeneralInformationMetaAttributesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `activeInactiveOrganization()` */
  static readonly ActiveInactiveOrganizationPath = '/api/Organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeInactiveOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeInactiveOrganization$Response(
    params: ActiveInactiveOrganization$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    return activeInactiveOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeInactiveOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeInactiveOrganization(params: ActiveInactiveOrganization$Params, context?: HttpContext): Observable<number> {
    return this.activeInactiveOrganization$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `add()` */
  static readonly AddPath = '/api/Organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `add()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  add$Response(params: Add$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationOr>> {
    return add(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `add$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  add(params: Add$Params, context?: HttpContext): Observable<OrganizationOr> {
    return this.add$Response(params, context).pipe(map((r: StrictHttpResponse<OrganizationOr>): OrganizationOr => r.body));
  }

  /** Path part for operation `update()` */
  static readonly UpdatePath = '/api/Organizations/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationOr>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: Update$Params, context?: HttpContext): Observable<OrganizationOr> {
    return this.update$Response(params, context).pipe(map((r: StrictHttpResponse<OrganizationOr>): OrganizationOr => r.body));
  }

  /** Path part for operation `delete()` */
  static readonly DeletePath = '/api/Organizations/delete/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<boolean> {
    return this.delete$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `getActiveOrganizations()` */
  static readonly GetActiveOrganizationsPath = '/api/Organizations/activeOrganizations/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveOrganizations$Response(
    params: GetActiveOrganizations$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationOr>>> {
    return getActiveOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveOrganizations(params: GetActiveOrganizations$Params, context?: HttpContext): Observable<Array<OrganizationOr>> {
    return this.getActiveOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationOr>>): Array<OrganizationOr> => r.body)
    );
  }

  /** Path part for operation `getActiveOrganizationsForUser()` */
  static readonly GetActiveOrganizationsForUserPath = '/api/Organizations/activeOrganizationsForUser/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveOrganizationsForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveOrganizationsForUser$Response(
    params: GetActiveOrganizationsForUser$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationOr>>> {
    return getActiveOrganizationsForUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveOrganizationsForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveOrganizationsForUser(params: GetActiveOrganizationsForUser$Params, context?: HttpContext): Observable<Array<OrganizationOr>> {
    return this.getActiveOrganizationsForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationOr>>): Array<OrganizationOr> => r.body)
    );
  }

  /** Path part for operation `getActiveOrganizationForSpeedMonitor()` */
  static readonly GetActiveOrganizationForSpeedMonitorPath = '/api/Organizations/activeOrganizationForSpeedMonitor/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveOrganizationForSpeedMonitor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveOrganizationForSpeedMonitor$Response(
    params: GetActiveOrganizationForSpeedMonitor$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrganizationOr>> {
    return getActiveOrganizationForSpeedMonitor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveOrganizationForSpeedMonitor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveOrganizationForSpeedMonitor(
    params: GetActiveOrganizationForSpeedMonitor$Params,
    context?: HttpContext
  ): Observable<OrganizationOr> {
    return this.getActiveOrganizationForSpeedMonitor$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationOr>): OrganizationOr => r.body)
    );
  }

  /** Path part for operation `getTree()` */
  static readonly GetTreePath = '/api/Organizations/tree/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTree$Response(params: GetTree$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrgnizationList>>> {
    return getTree(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTree(params: GetTree$Params, context?: HttpContext): Observable<Array<OrgnizationList>> {
    return this.getTree$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrgnizationList>>): Array<OrgnizationList> => r.body)
    );
  }

  /** Path part for operation `getTreeStructure()` */
  static readonly GetTreeStructurePath = '/api/Organizations/verticaltree';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTreeStructure()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTreeStructure$Response(
    params?: GetTreeStructure$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VerticalTreeNode>>> {
    return getTreeStructure(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTreeStructure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTreeStructure(params?: GetTreeStructure$Params, context?: HttpContext): Observable<Array<VerticalTreeNode>> {
    return this.getTreeStructure$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VerticalTreeNode>>): Array<VerticalTreeNode> => r.body)
    );
  }

  /** Path part for operation `getTenantList()` */
  static readonly GetTenantListPath = '/api/Organizations/tenants/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantList$Response(params: GetTenantList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationOr>>> {
    return getTenantList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantList(params: GetTenantList$Params, context?: HttpContext): Observable<Array<OrganizationOr>> {
    return this.getTenantList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationOr>>): Array<OrganizationOr> => r.body)
    );
  }

  /** Path part for operation `getParentOrganizationDetails()` */
  static readonly GetParentOrganizationDetailsPath = '/api/Organizations/parentstree/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getParentOrganizationDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParentOrganizationDetails$Response(
    params: GetParentOrganizationDetails$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationOr>>> {
    return getParentOrganizationDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getParentOrganizationDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParentOrganizationDetails(params: GetParentOrganizationDetails$Params, context?: HttpContext): Observable<Array<OrganizationOr>> {
    return this.getParentOrganizationDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationOr>>): Array<OrganizationOr> => r.body)
    );
  }

  /** Path part for operation `getReverseTreeOfTenant()` */
  static readonly GetReverseTreeOfTenantPath = '/api/Organizations/reversetreeoftenant/{orid}/{meid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReverseTreeOfTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReverseTreeOfTenant$Response(
    params: GetReverseTreeOfTenant$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationOr>>> {
    return getReverseTreeOfTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReverseTreeOfTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReverseTreeOfTenant(params: GetReverseTreeOfTenant$Params, context?: HttpContext): Observable<Array<OrganizationOr>> {
    return this.getReverseTreeOfTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationOr>>): Array<OrganizationOr> => r.body)
    );
  }

  /** Path part for operation `getTreeDetails()` */
  static readonly GetTreeDetailsPath = '/api/Organizations/treeDetails/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTreeDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTreeDetails$Response(params: GetTreeDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationOr>>> {
    return getTreeDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTreeDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTreeDetails(params: GetTreeDetails$Params, context?: HttpContext): Observable<Array<OrganizationOr>> {
    return this.getTreeDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationOr>>): Array<OrganizationOr> => r.body)
    );
  }

  /** Path part for operation `getDetails()` */
  static readonly GetDetailsPath = '/api/Organizations/details/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetails$Response(params: GetDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationOr>> {
    return getDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetails(params: GetDetails$Params, context?: HttpContext): Observable<OrganizationOr> {
    return this.getDetails$Response(params, context).pipe(map((r: StrictHttpResponse<OrganizationOr>): OrganizationOr => r.body));
  }
}
