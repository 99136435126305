/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { LiveApiConfiguration } from '../live-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AclroleAr } from '../models/aclrole-ar';
import { activeInactiveUser } from '../fn/user/active-inactive-user';
import { ActiveInactiveUser$Params } from '../fn/user/active-inactive-user';
import { addUser } from '../fn/user/add-user';
import { AddUser$Params } from '../fn/user/add-user';
import { addUserSettings } from '../fn/user/add-user-settings';
import { AddUserSettings$Params } from '../fn/user/add-user-settings';
import { getLanguages } from '../fn/user/get-languages';
import { GetLanguages$Params } from '../fn/user/get-languages';
import { getTimeZones } from '../fn/user/get-time-zones';
import { GetTimeZones$Params } from '../fn/user/get-time-zones';
import { getUser } from '../fn/user/get-user';
import { GetUser$Params } from '../fn/user/get-user';
import { getUserGroups } from '../fn/user/get-user-groups';
import { GetUserGroups$Params } from '../fn/user/get-user-groups';
import { getUserRoles } from '../fn/user/get-user-roles';
import { GetUserRoles$Params } from '../fn/user/get-user-roles';
import { getUsers } from '../fn/user/get-users';
import { GetUsers$Params } from '../fn/user/get-users';
import { getUserSetting } from '../fn/user/get-user-setting';
import { GetUserSetting$Params } from '../fn/user/get-user-setting';
import { isValidUserEmail } from '../fn/user/is-valid-user-email';
import { IsValidUserEmail$Params } from '../fn/user/is-valid-user-email';
import { isValidUserName } from '../fn/user/is-valid-user-name';
import { IsValidUserName$Params } from '../fn/user/is-valid-user-name';
import { LocaleLc } from '../models/locale-lc';
import { removeUser } from '../fn/user/remove-user';
import { RemoveUser$Params } from '../fn/user/remove-user';
import { TimezoneTz } from '../models/timezone-tz';
import { updateUser } from '../fn/user/update-user';
import { UpdateUser$Params } from '../fn/user/update-user';
import { updateUserSetting } from '../fn/user/update-user-setting';
import { UpdateUserSetting$Params } from '../fn/user/update-user-setting';
import { userGetUsersWithWorkcenterId } from '../fn/user/user-get-users-with-workcenter-id';
import { UserGetUsersWithWorkcenterId$Params } from '../fn/user/user-get-users-with-workcenter-id';
import { UsergrpUg } from '../models/usergrp-ug';
import { UsersettingUe } from '../models/usersetting-ue';
import { UserUsDto } from '../models/user-us-dto';
import { UserWorkcentersDetail } from '../models/user-workcenters-detail';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: LiveApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/getusers/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserUsDto>>> {
    return getUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params: GetUsers$Params, context?: HttpContext): Observable<Array<UserUsDto>> {
    return this.getUsers$Response(params, context).pipe(map((r: StrictHttpResponse<Array<UserUsDto>>): Array<UserUsDto> => r.body));
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/getuserdetail/{orid}/{usid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUsDto>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: GetUser$Params, context?: HttpContext): Observable<UserUsDto> {
    return this.getUser$Response(params, context).pipe(map((r: StrictHttpResponse<UserUsDto>): UserUsDto => r.body));
  }

  /** Path part for operation `userGetUsersWithWorkcenterId()` */
  static readonly UserGetUsersWithWorkcenterIdPath = '/getuserswithworkcenterid/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUsersWithWorkcenterId()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUsersWithWorkcenterId$Response(
    params: UserGetUsersWithWorkcenterId$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserWorkcentersDetail>>> {
    return userGetUsersWithWorkcenterId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUsersWithWorkcenterId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUsersWithWorkcenterId(
    params: UserGetUsersWithWorkcenterId$Params,
    context?: HttpContext
  ): Observable<Array<UserWorkcentersDetail>> {
    return this.userGetUsersWithWorkcenterId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserWorkcentersDetail>>): Array<UserWorkcentersDetail> => r.body)
    );
  }

  /** Path part for operation `getUserSetting()` */
  static readonly GetUserSettingPath = '/getusersettingdetail/{orid}/{usid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSetting$Response(params: GetUserSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<UsersettingUe>> {
    return getUserSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSetting(params: GetUserSetting$Params, context?: HttpContext): Observable<UsersettingUe> {
    return this.getUserSetting$Response(params, context).pipe(map((r: StrictHttpResponse<UsersettingUe>): UsersettingUe => r.body));
  }

  /** Path part for operation `addUser()` */
  static readonly AddUserPath = '/adduser/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUser$Response(params: AddUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUsDto>> {
    return addUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUser(params: AddUser$Params, context?: HttpContext): Observable<UserUsDto> {
    return this.addUser$Response(params, context).pipe(map((r: StrictHttpResponse<UserUsDto>): UserUsDto => r.body));
  }

  /** Path part for operation `addUserSettings()` */
  static readonly AddUserSettingsPath = '/addusersettings/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUserSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUserSettings$Response(params: AddUserSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<UsersettingUe>> {
    return addUserSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addUserSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUserSettings(params: AddUserSettings$Params, context?: HttpContext): Observable<UsersettingUe> {
    return this.addUserSettings$Response(params, context).pipe(map((r: StrictHttpResponse<UsersettingUe>): UsersettingUe => r.body));
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/updateuser/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: UpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUsDto>> {
    return updateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: UpdateUser$Params, context?: HttpContext): Observable<UserUsDto> {
    return this.updateUser$Response(params, context).pipe(map((r: StrictHttpResponse<UserUsDto>): UserUsDto => r.body));
  }

  /** Path part for operation `updateUserSetting()` */
  static readonly UpdateUserSettingPath = '/updateusersettings/{ueid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserSetting$Response(params: UpdateUserSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<UsersettingUe>> {
    return updateUserSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserSetting(params: UpdateUserSetting$Params, context?: HttpContext): Observable<UsersettingUe> {
    return this.updateUserSetting$Response(params, context).pipe(map((r: StrictHttpResponse<UsersettingUe>): UsersettingUe => r.body));
  }

  /** Path part for operation `removeUser()` */
  static readonly RemoveUserPath = '/removeuser/{orid}/{usid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUser$Response(params: RemoveUser$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return removeUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUser(params: RemoveUser$Params, context?: HttpContext): Observable<number> {
    return this.removeUser$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `activeInactiveUser()` */
  static readonly ActiveInactiveUserPath = '/active-inactive/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeInactiveUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeInactiveUser$Response(params: ActiveInactiveUser$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return activeInactiveUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeInactiveUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeInactiveUser(params: ActiveInactiveUser$Params, context?: HttpContext): Observable<number> {
    return this.activeInactiveUser$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
  }

  /** Path part for operation `getLanguages()` */
  static readonly GetLanguagesPath = '/getLanguages/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLanguages$Response(params: GetLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocaleLc>>> {
    return getLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLanguages(params: GetLanguages$Params, context?: HttpContext): Observable<Array<LocaleLc>> {
    return this.getLanguages$Response(params, context).pipe(map((r: StrictHttpResponse<Array<LocaleLc>>): Array<LocaleLc> => r.body));
  }

  /** Path part for operation `getTimeZones()` */
  static readonly GetTimeZonesPath = '/gettimezones/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeZones()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeZones$Response(params: GetTimeZones$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TimezoneTz>>> {
    return getTimeZones(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTimeZones$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeZones(params: GetTimeZones$Params, context?: HttpContext): Observable<Array<TimezoneTz>> {
    return this.getTimeZones$Response(params, context).pipe(map((r: StrictHttpResponse<Array<TimezoneTz>>): Array<TimezoneTz> => r.body));
  }

  /** Path part for operation `getUserGroups()` */
  static readonly GetUserGroupsPath = '/getusergroups/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroups$Response(params: GetUserGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UsergrpUg>>> {
    return getUserGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroups(params: GetUserGroups$Params, context?: HttpContext): Observable<Array<UsergrpUg>> {
    return this.getUserGroups$Response(params, context).pipe(map((r: StrictHttpResponse<Array<UsergrpUg>>): Array<UsergrpUg> => r.body));
  }

  /** Path part for operation `getUserRoles()` */
  static readonly GetUserRolesPath = '/getuserroles/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRoles$Response(params: GetUserRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AclroleAr>>> {
    return getUserRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRoles(params: GetUserRoles$Params, context?: HttpContext): Observable<Array<AclroleAr>> {
    return this.getUserRoles$Response(params, context).pipe(map((r: StrictHttpResponse<Array<AclroleAr>>): Array<AclroleAr> => r.body));
  }

  /** Path part for operation `isValidUserEmail()` */
  static readonly IsValidUserEmailPath = '/validuseremail/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isValidUserEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isValidUserEmail$Response(params: IsValidUserEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isValidUserEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isValidUserEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isValidUserEmail(params: IsValidUserEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.isValidUserEmail$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }

  /** Path part for operation `isValidUserName()` */
  static readonly IsValidUserNamePath = '/validusername/{orid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isValidUserName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isValidUserName$Response(params: IsValidUserName$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isValidUserName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isValidUserName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isValidUserName(params: IsValidUserName$Params, context?: HttpContext): Observable<boolean> {
    return this.isValidUserName$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
  }
}
