import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public showSplashScreen(): void {
    this.setSplashScreenVisibility(true);
  }

  public hideSplashScreen(): void {
    this.setSplashScreenVisibility(false);
  }

  public setSplashScreenVisibility(isVisible: boolean): void {
    const element = document.getElementById('loader');
    if (!element) {
      return;
    }

    if (isVisible) {
      this.renderer.setStyle(element, 'visibility', 'visible');
    } else {
      this.renderer.setStyle(element, 'display', 'none');
    }
  }
}
