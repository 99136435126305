import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderMessageType } from '../models/header-message-type';
import { TranslateService } from '@ngx-translate/core';
import { LogEntry, LogHeaderMessageArea, LogLevel } from '../../../services/logging';
import { DialogService } from 'primeng/dynamicdialog';
import { LogMessagesModalComponent } from '../../modals';
import { Subscription } from 'rxjs';
import moment from 'moment-mini';

@Component({
  selector: 'lib-info-log',
  templateUrl: './info-log.component.html',
  styleUrls: ['./info-log.component.scss']
})
export class InfoLogComponent implements OnInit, OnDestroy {
  private readonly MAX_LOG_MESSAGES = 200;

  public currentMessage: string;
  public currentMessageTime: string;
  public currentMessageType: HeaderMessageType;
  public currentMessageParameters: object;
  public logMessages: LogEntry[] = [];

  private successMessageTimeout: any;
  private subscriptions = new Subscription();

  public readonly MESSAGE_TYPE = HeaderMessageType;
  private readonly SUCCESS_MESSAGE_DISPLAY_TIME = 15000;

  constructor(private translateService: TranslateService, private dialogService: DialogService) {}

  private static mapMessageType(level: LogLevel): HeaderMessageType {
    switch (level) {
      case LogLevel.Success:
        return HeaderMessageType.Success;
      case LogLevel.Warn:
        return HeaderMessageType.Warning;
      case LogLevel.Error:
        return HeaderMessageType.Error;
      default:
        return HeaderMessageType.Info;
    }
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      LogHeaderMessageArea.currentMessage$.subscribe((entry) => {
        this.processLogMessage(entry);
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public openLogMessagesModal(): void {
    const modal = this.dialogService.open(LogMessagesModalComponent, {
      header: this.translateService.instant('INFO_LOG.MODAL_HEADER'),
      width: '1200px',
      data: {
        logMessages: this.logMessages
      }
    });

    this.subscriptions.add(
      modal.onClose.subscribe(() => {
        this.confirmCurrentMessage();
      })
    );
  }

  private processLogMessage(entry: LogEntry): void {
    if (entry) {
      clearTimeout(this.successMessageTimeout);
      this.logMessages.push(entry);
      if (this.logMessages.length > this.MAX_LOG_MESSAGES) {
        this.logMessages.pop(); // Remove the oldest message
      }

      this.currentMessageTime = moment().format('HH:mm');
      this.currentMessage = this.extractCurrentMessage(entry);
      this.currentMessageType = InfoLogComponent.mapMessageType(entry.level);
    }
  }

  private confirmCurrentMessage() {
    this.currentMessage = null;
  }

  private extractCurrentMessage(entry: LogEntry): string {
    if (entry.level === LogLevel.Warn && entry.serializedBackendWarning?.isFailure) {
      const warning = entry.serializedBackendWarning.serializedWarning[0];
      if (warning.errorCode && this.translateService.instant(warning.errorCode) !== warning.errorCode) {
        this.currentMessageParameters = warning.parameters;
        return warning.errorCode;
      } else {
        return warning.value;
      }
    } else if (entry.level === LogLevel.Error) {
      return 'HEADER_MESSAGE.DEFAULT_BACKEND_ERROR';
    } else if (entry.level === LogLevel.Success) {
      this.successMessageTimeout = setTimeout(() => {
        this.confirmCurrentMessage();
      }, this.SUCCESS_MESSAGE_DISPLAY_TIME);
      this.currentMessageParameters = entry.extraInfo[0];
      return entry.message;
    }
    return entry.message;
  }
}
