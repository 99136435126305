/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SettingValuesViewModel } from '../../models/setting-values-view-model';

export interface GetSettingValues$Params {
  hierarchyType: string;
  hierarchyLevel: string;
  entityId: string;
}

export function getSettingValues(
  http: HttpClient,
  rootUrl: string,
  params: GetSettingValues$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<SettingValuesViewModel>>> {
  const rb = new RequestBuilder(rootUrl, getSettingValues.PATH, 'get');
  if (params) {
    rb.path('hierarchyType', params.hierarchyType, {});
    rb.path('hierarchyLevel', params.hierarchyLevel, {});
    rb.path('entityId', params.entityId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<SettingValuesViewModel>>;
    })
  );
}

getSettingValues.PATH = '/api/v1/Settings/GetSettingValues/{hierarchyType}/{hierarchyLevel}/{entityId}';
