import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomColumn } from '@bd/shared/utils';
import { Table as workCenterTable } from '@bd/shared/models';
import { WarehouseLocationViewModel, WorkCenterViewModel as WorkCenter, WorkCenterViewModel } from 'chronos-basedata-client';
import { ListValue, LogService } from 'chronos-shared';
import { WorkCenterDsService, WorkCenterEditData } from '@bd/core/data-services/work-center-ds';
import { finalize, map, tap } from 'rxjs/operators';
import { WorkCenterQuery } from '@bd/core/global-state/work-center/tasks/workcenter.query';
import { WorkCenterService } from '@bd/core/global-state/work-center/tasks/workcenter.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ChangeWarehouseComponent } from '../change-warehouse/change-warehouse.component';
import { TranslateService } from '@ngx-translate/core';
import { WorkCenterColumnType } from '@bd/modules/work-center/models';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

@Component({
  selector: 'app-edit-workcenter-details',
  templateUrl: './edit-workcenter-details.component.html',
  styleUrls: ['./edit-workcenter-details.component.scss']
})
export class EditWorkcenterDetailsComponent implements OnInit {
  @Input() public workCenters$: Observable<WorkCenter[]>;
  @Output() public updateWorkCenterEvent = new EventEmitter();
  @Output() formValidityChange = new EventEmitter<boolean>();

  public warehouseLocationList: ListValue[] = [];
  public operationTypeList: ListValue[] = [];
  public shiftCalendarList: ListValue[] = [];
  public operationLoading = true;
  public locationLoading = true;
  public loading = true;
  public workCenterColumnType: WorkCenterColumnType;
  public readonly WORKCENTER_COLUMN_TYPE = WorkCenterColumnType;
  public formGroup: FormGroup;
  public editAll: boolean = false;
  public readonly INPUT_MIN_NUMBER = 1;
  public readonly INPUT_MAX_NUMBER = 999999;

  columns: workCenterTable[] = [
    new CustomColumn('siteName', 'WORK_CENTER_HEADERS.SITE', { styleClass: 'tiny-cell padding-left' }),
    new CustomColumn('type', 'WORK_CENTER_HEADERS.TYPE', { styleClass: 'tiny-cell' }),
    new CustomColumn('externalWorkCenterId', 'WORK_CENTER_HEADERS.WORK_CENTER', { styleClass: 'tiny-cell' }),
    new CustomColumn('workCenterName', 'WORK_CENTER_HEADERS.NAME', { styleClass: 'medium-cell' }),
    new CustomColumn('workCenterGroupName', 'WORK_CENTER_HEADERS.WORK_CENTER_GROUP', { styleClass: 'medium-cell' }),
    new CustomColumn('operationTypeName', 'WORK_CENTER_HEADERS.OPERATION_TYPE', { styleClass: 'tiny-cell' }),
    new CustomColumn('processPerQuantity', 'WORK_CENTER_HEADERS.PROCESS_PER_QUANTITY', { styleClass: 'medium-cell number-cell' }),
    new CustomColumn('declaredPerformance', 'WORK_CENTER_HEADERS.DECLARED_PERFORMANCE', { styleClass: 'medium-cell number-cell' }),
    new CustomColumn('counterUnitId', 'WORK_CENTER_HEADERS.COUNTER_UNIT_ID', { styleClass: 'tiny-cell' }),
    new CustomColumn('inputWarehouseLocationId', 'WORK_CENTER_HEADERS.INPUT_WAREHOUSE_LOCATION', { styleClass: 'tiny-cell' }),
    new CustomColumn('outputWarehouseLocationId', 'WORK_CENTER_HEADERS.OUTPUT_WAREHOUSE_LOCATION', { styleClass: 'tiny-cell' }),
    new CustomColumn('shiftCalendarId', 'WORK_CENTER_HEADERS.SHIFT_CALENDAR', { styleClass: 'medium-cell' }),
    new CustomColumn('requiresApproval', 'WORK_CENTER_HEADERS.REQUIRES_APPROVAL', { styleClass: 'tiny-cell' }),
    new CustomColumn('isActive', 'WORK_CENTER_HEADERS.IS_ACTIVE', { styleClass: 'tiny-cell' })
  ];

  constructor(
    public workCenterQuery: WorkCenterQuery,
    private workCenterDsService: WorkCenterDsService,
    private workCenterService: WorkCenterService,
    private dialogService: DialogService,
    private translationService: TranslateService,
    private fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.getOperationTypes();
    this.getShiftCalendar();
    this.destructureWorkCenters();

    this.formGroup = this.fb.group({
      rows: this.fb.array([])
    });

    this.workCenters$.subscribe((workCenters) => {
      this.setFormRows(workCenters);
    });

    this.formGroup.statusChanges.subscribe(() => {
      this.formValidityChange.emit(this.formGroup.valid);
    });
  }

  private setFormRows(workCenters: any[]) {
    const rows = this.formGroup.get('rows') as FormArray;
    rows.clear();

    workCenters.forEach((workCenter) => {
      rows.push(this.createRow(workCenter));
    });
  }

  private createRow(workCenter): FormGroup {
    return this.fb.group({
      workCenterId: [workCenter.id, Validators.required],
      operationTypeId: [workCenter.operationTypeId, Validators.required],
      processPerQuantity: [workCenter.processPerQuantity.value, [Validators.required, Validators.min(0)]],
      declaredPerformance: [workCenter.declaredPerformance.value, [Validators.min(0)]],
      counterUnitId: [workCenter.counterUnitId, [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
      inputWarehouseLocation: [workCenter.inputWarehouseLocation, Validators.required],
      outputWarehouseLocation: [workCenter.outputWarehouseLocation, Validators.required],
      shiftCalendarId: [workCenter.shiftCalendarId, Validators.required],
      requiresApproval: new FormControl({ value: workCenter.requiresApproval, disabled: !this.editAll }, Validators.required),
      isActive: new FormControl({ value: workCenter.isActive, disabled: !this.editAll }, Validators.required),
      rowVersion: [workCenter.rowVersion]
    });
  }

  private get rows(): FormArray {
    return this.formGroup.get('rows') as FormArray;
  }

  public updateWorkCenter(newValue, workCenter: WorkCenter, workCenterColumnType: WorkCenterColumnType) {
    switch (workCenterColumnType) {
      case WorkCenterColumnType.OperationType:
        const operationName = this.operationTypeList.find((items) => items.value === newValue);

        workCenter.operationTypeId = newValue;
        workCenter.operationTypeName = operationName.label;
        break;
      case WorkCenterColumnType.ProcessPerQuantity:
        if (workCenter.processPerQuantity === null) {
          workCenter.processPerQuantity = {};
        }
        workCenter.processPerQuantity.value = newValue;
        workCenter.processPerQuantity.unitId = workCenter.counterUnitId;
        break;
      case WorkCenterColumnType.DeclaredPerformance:
        if (!workCenter.declaredPerformance) {
          workCenter.declaredPerformance = {};
        }
        workCenter.declaredPerformance.value = newValue;
        workCenter.declaredPerformance.unitId = workCenter.counterUnitId;
        break;
      case WorkCenterColumnType.CounterUnit:
        workCenter.counterUnitId = newValue;
        workCenter.processPerQuantity.unitId = workCenter.counterUnitId;
        workCenter.declaredPerformance.unitId = workCenter.counterUnitId;
        break;
      case WorkCenterColumnType.ActiveInActive:
        workCenter.isActive = newValue;
        break;
      case WorkCenterColumnType.ShiftCalendarExternal:
        workCenter.shiftCalendarId = newValue;
        break;
      default:
        console.info('default case executed');
        break;
    }

    // update list
    this.workCenterService.updateWorkCenterItem(workCenter);
  }

  public changeInputWarehouseLocation(rowIndex: number, workCenter: WorkCenterViewModel) {
    this.changeWarehouseLocation(workCenter.inputWarehouseLocation).subscribe((selectedWarehouseLocation) => {
      if (selectedWarehouseLocation) {
        const control = this.rows.controls[rowIndex].get('inputWarehouseLocation');
        control.setValue(selectedWarehouseLocation);
        control.markAsDirty();
        control.updateValueAndValidity();

        workCenter.inputWarehouseLocation = selectedWarehouseLocation;
        this.workCenterService.updateWorkCenterItem(workCenter);
      }
    });
  }

  public changeOutputWarehouseLocation(rowIndex: number, workCenter: WorkCenterViewModel) {
    this.changeWarehouseLocation(workCenter.outputWarehouseLocation).subscribe((selectedWarehouseLocation) => {
      if (selectedWarehouseLocation) {
        const control = this.rows.controls[rowIndex].get('outputWarehouseLocation');
        control.setValue(selectedWarehouseLocation);
        control.markAsDirty();

        workCenter.outputWarehouseLocation = selectedWarehouseLocation;
        this.workCenterService.updateWorkCenterItem(workCenter);
      }
    });
  }

  private changeWarehouseLocation(warehouseLocation: WarehouseLocationViewModel): Observable<WarehouseLocationViewModel> {
    return new Observable<WarehouseLocationViewModel>((observable) => {
      this.dialogService
        .open(ChangeWarehouseComponent, {
          data: {
            warehouseLocation
          },
          header: this.translationService.instant('WORK_CENTER_SCREEN.EDIT_WAREHOUSE')
        })
        .onClose.subscribe((selectedWarehouseLocation) => {
          observable.next(selectedWarehouseLocation);
          observable.complete();
        });
    });
  }

  public onSaveAll(): Observable<void> {
    // get dirty rows
    const formArray = this.formGroup.get('rows') as FormArray;
    const dirtyRows = formArray.controls.filter((control) => control.dirty).map((control) => control.value as WorkCenterEditData);

    console.info('Edit WorkCenter Save: Dirty row count: ', dirtyRows.length, '; Dirty rows: ', dirtyRows);

    // update data on the backend
    if (dirtyRows.length > 0) {
      return this.workCenterDsService.updateWorkCenters(dirtyRows).pipe(
        tap(() => {
          LogService.success('SUCCESS_MESSAGE.WORKCENTER_UPDATED_SUCCESSFULLY');
          this.updateWorkCenterEvent.emit();
        })
      );
    }
    return of(null);
  }

  public onEditAll() {
    this.editAll = true;
    this.rows.controls.forEach((element) => {
      element.enable();
    });
  }

  public onCancelEdit() {
    this.editAll = false;
    this.rows.controls.forEach((element) => {
      element.disable();
    });
  }

  public checkValidity(rowIndex: number, columnName: string, workCenterColumnType?: WorkCenterColumnType): boolean {
    if (this.editAll === false) return false;

    const control = this.formGroup.get('rows')?.get(rowIndex.toString())?.get(columnName);

    if (!control) {
      return false;
    }

    if (
      workCenterColumnType === WorkCenterColumnType.ProcessPerQuantity ||
      workCenterColumnType === WorkCenterColumnType.DeclaredPerformance
    ) {
      return control.value === null;
    }

    return control.invalid;
  }

  private destructureWorkCenters(): void {
    this.workCenters$ = this.workCenters$.pipe(
      map((workCenters) =>
        workCenters.map((item) => ({
          ...structuredClone(item)
        }))
      )
    );
  }

  private getOperationTypes() {
    this.operationLoading = true;
    this.workCenterDsService
      .getOperationTypes()
      .pipe(
        tap((items) => {
          this.operationTypeList = items.map((item) => ({
            label: `${item.operationTypeName}`,
            value: item.id
          }));
        }),
        finalize(() => {
          this.operationLoading = false;
        })
      )
      .subscribe();
  }

  private getShiftCalendar() {
    this.loading = true;
    return this.workCenterDsService
      .getShiftCalendars()
      .pipe(
        tap((items) => {
          this.shiftCalendarList = items.map((item) => ({
            label: `${item.externalShiftCalendarId}`,
            value: item.id
          }));
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe();
  }
}
